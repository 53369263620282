import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { UserService } from '../../services/user.service';
import { TarotService } from '../../services/tarot.service';
import { LenormandService } from '../../services/lenormand.service';
import { AstroService } from '../../services/astro.service';
import { RuneService } from '../../services/rune.service';
import { SpreadService } from '../../services/spread.service';
import { TranslateService } from '@ngx-translate/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { ToastController, ModalController } from '@ionic/angular';
import { LanguageService } from '../../services/language.service';
import { Platform } from '@ionic/angular';
import { LogService } from '../../services/log.service';
import { Router } from '@angular/router';
import { AnalyticsService } from '../../services/analytics.service';
import { SwiperComponent } from "swiper/angular";
import SwiperCore, { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-card-modal',
  templateUrl: './card-modal.page.html',
  styleUrls: ['./card-modal.page.scss'],
})
export class CardModalPage implements OnInit, OnDestroy {
  constructor(
    private modalController: ModalController,
    private platform: Platform,
    private userService: UserService,
    private logService: LogService,
    private tarotService: TarotService,
    private lenormandService: LenormandService,
    private astroService: AstroService,
    private runeService: RuneService,
    private spreadService: SpreadService,
    public productService: ProductService,
    private socialSharing: SocialSharing,
    private languageService: LanguageService,
    private analyticsService: AnalyticsService,
    private iab: InAppBrowser,
    public translate: TranslateService,
    public toastController: ToastController,
    public router: Router
  ) {}
  @Input() card: any;
  @Input() userDeck: any;
  @Input() spread: any;
  @Input() index: number;
  @Input() spreadType: string;
  @Input() spreadId: string; // the id that is without the underscore.
  @Input() permissions: any;
  @ViewChild("swiperRef", { static: false }) sliderRef?: SwiperComponent;
  @ViewChild("premium") premium;

  product: any;
  position: any;
  reverseCard: boolean = false;
  lastIndex: number;
  next: any;
  prev: any;
  translateVariable: string;
  deckInfo: any;
  standardSpread: boolean;
  language: string = this.languageService.selected;
  campaign:string = '?utm_source=laby-v2&utm_medium=app&utm_campaign=card-info';
  backbuttonSubscription: any;
  cardProperties: any = [];
  expandedCard: boolean = false;
  userData: any = {
    id: null,
    notes_up: null,
    notes_rev: null,
    lesson: null,
    app: 'userData',
    date: this.logService.convertDate(new Date())
  };
  context: Array<string> = ['love-relationship', 'love-searching', 'work', 'money'];
  updatedUserData: any;
  editNotes: boolean = false;
  url: string = this.router.url;
  activeSlide: number = 0;
  config: SwiperOptions = {
    slidesPerView: 1.25,
    spaceBetween: 15,
    slidesOffsetBefore: 15,
    slidesOffsetAfter: 15,
  };
  auth: boolean = false;

  async nextCard() {
    if (!this.spread || this.spread && this.next.card_id) {
      await this.dismiss(this.card.image);
      if (this.spread && this.next.card_id) {
        this.openCard(this.spread, this.index + 1, this.userDeck, this.permissions);
      } else {
        this.openCardSimple(this.spreadType, this.userDeck, this.next, this.permissions);
      }
    }
  }

  async prevCard() {
    if (!this.spread || this.spread && this.prev.card_id) {
      await this.dismiss(this.card.image);
      if (this.spread && this.prev.card_id) {
        this.openCard(this.spread, this.index - 1, this.userDeck, this.permissions);
      } else {
        this.openCardSimple(this.spreadType, this.userDeck, this.prev, this.permissions);
      }
    }
  }

  openPremium() {
    this.premium.openSales(0);
  }

  expandCard(open: boolean) {
    this.expandedCard = open;
  }

  goToSlide(index: number) {
    this.activeSlide = index;
    this.sliderRef.swiperRef.slideTo(index);
  }

  updateCurrentSlide(ev: any) {
    this.activeSlide = ev[0].activeIndex;
  }

  async dismiss(id, data?) {
    if (data) {
      let modal = await this.modalController.dismiss({data}, '', id);
    } else {
      let modal = await this.modalController.dismiss({}, '', id);
    }
  }

  async presentPopover() {
    var header = this.translate.instant('card_modal.info_header');
    var message = this.translate.instant('card_modal.info_content');
    const toast = await this.toastController.create({
      header: header,
      message: message,
      position: 'bottom',
      color: 'secondary',
      cssClass: 'animated bounceInUp',
      buttons: [
        {
          text: 'OK!',
          role: 'cancel'
        }
      ]
    });
    toast.present();
  }

  // opening card with a reading
  // duplicate of what's on readingService -- but we don't want to have them infinite calling each other.
  async openCard(spread, i, deck, permissions) {
    var card = this[this.spreadType + 'Service'].getCard(spread.cards[i].card_id)[0];
    var spreadId = spread.id;

    if (spread.id.includes('_')) {
      spreadId = spread.id.split('_')[0];
    };

    var props = {
        card: card,
        userDeck: deck,
        spread: spread,
        index: i,
        spreadType: this.spreadType,
        spreadId: spreadId,
        permissions: permissions
    };

    const modal = await this.modalController.create({
      component: CardModalPage,
      componentProps: props,
      id: card.image
    });

    this.analyticsService.logEvent('view_card', {
      content_type: this.spreadType,
      card_id: card,
      deck_id: deck,
      source: this.url
    });

    return await modal.present();
  }

  // opening card outside readings (lessons and reference)
  // duplicate of what's on readingService -- but we don't want to have them infinite calling each other.
  async openCardSimple(type, deck, cardId, permissions) {
    if (type == 'tarot') {
      var props: any = {
        card: this.tarotService.getCard(cardId)[0],
        userDeck: deck,
        spreadType: type,
        permissions: permissions
      }
    };
    if (type != 'tarot') {
      var props: any = {
        card: this[type + 'Service'].getCard(cardId)[0],
        userDeck: deck,
        spreadType: type
      }
    };

    this.analyticsService.logEvent('view_card', {
      content_type: type,
      card_id: cardId,
      deck_id: deck,
      source: this.url
    });

    const modal = await this.modalController.create({
      component: CardModalPage,
      componentProps: props,
      id: cardId
    });

    return await modal.present();
  }

  openPage(url) {
    this.analyticsService.logEvent('visit_website', {
      content_type: 'meanings',
      content_id: url
    });

    const browser = this.iab.create(url+this.campaign, '_system');
  }

  ngOnDestroy() {
    this.backbuttonSubscription.unsubscribe();
  }

  share(url) {
    console.log('calling share function...');
    var options = {
      message: 'Learn more about this tarot card:', // not supported on some apps (Facebook, Instagram)
      subject: 'Learn more about this tarot card:', // fi. for email
      url: url,
      chooserTitle: 'Share on...' // Android only, you can override the default share sheet title
    };

    this.analyticsService.logEvent('share', {
      content_type: 'card',
      content_id: this.card.image,
      deck: this.userDeck,
      source: this.url
    });

    this.socialSharing.shareWithOptions(options);
  }

  createCardProperties() {
    if (this.spreadType == 'astro') {
      // setting this up so the order is the same.
      var keywords = ['fire', 'water', 'air', 'earth', 'zodiac', 'planet', 'house', 'cardinal', 'fixed', 'mutable'];

      for (let term of this.card.search.split(" ")) {
        var property = {
          title: null,
          description: null
        };

        if (keywords.indexOf(term) > -1) {
          property.title = this.translate.instant('_common.astro.' + term + '.title');
          property.description = this.translate.instant('_common.astro.' + term + '.description');

          this.cardProperties.push(property);
        }
      }
    }
  }

  getAstroIcons(astro: string) {
    // should return an array as it can sometimes be one icon or two
    // something like ['planet/jupiter', 'zodiac/taurus']
    var planet = ['sun', 'moon', 'mercury', 'venus', 'mars', 'jupiter', 'saturn', 'uranus', 'neptune', 'pluto'];
    var zodiac = ['aries', 'taurus', 'gemini', 'cancer', 'leo', 'virgo', 'libra', 'scorpio', 'sagittarius', 'capricorn', 'aquarius', 'pisces'];

    var includedPlanets = planet.map((planet) => {
      if (astro.toLowerCase().indexOf(planet) > -1) {
        return 'planet/' + planet;
      }
    });
    var includedZodiac = zodiac.map((zodiac) => {
      if (astro.toLowerCase().indexOf(zodiac) > -1) {
        return 'zodiac/' + zodiac;
      }
    });
    var combined = includedPlanets.concat(includedZodiac).filter(Boolean);
    return combined;
  }

  setupUserNotes() {
    this.logService.getLog('userData', this.card.image).then((data) => {
      if (data) {
        this.userData = data;
      }
      this.userData.id = this.card.image;
      this.updatedUserData = JSON.parse(JSON.stringify(this.userData));
    });
  }

  saveUserNotes() {
    if (this.userData.notes_up == null) {
      // console.log('saving new note');
      this.logService.saveLog('userData', true, this.updatedUserData).then(() => {
        this.editNotes = false;
      });
    } else {
      // console.log('editing old note');
      this.logService.editLog('userData', this.updatedUserData.id, this.updatedUserData).then(() => {
        this.editNotes = false;
      });
    }
    this.userData = JSON.parse(JSON.stringify(this.updatedUserData));
  }

  cancelUserNotes() {
    this.editNotes = false;
    this.updatedUserData.notes_up = this.userData.notes_up;
  }

  deleteUserNotes() {
    this.logService.deleteLog('userData', this.card.image).then(data => {
      this.userData.notes_up = null;
      this.updatedUserData.notes_up = null;
    });
    this.editNotes = false;
  }

  ionViewWillEnter() {
    this.setupUserNotes();
    this.userService.getUser().then(data  => {
      this.auth = data?.email;
    });
  }

  ngOnInit() {
    this.createCardProperties();

    // back button should close modal
    this.backbuttonSubscription = this.platform.backButton.subscribeWithPriority(10000, () => {
      this.dismiss(this.card.image);
    });

    // string for getting the translations are different if the spreadType is tarot.
    // in tarot.service decks object calls for which translation to use in app.
    if (this.spreadType == 'tarot') {
      // reversal info is now in deck, not in individual cards
      this.deckInfo = this[this.spreadType + 'Service'].decks[this.userDeck];
      var translateKey: string;

      if (this.language == 'en' || this.language == undefined) {
        translateKey = 'translate_en';
      } else {
        translateKey = 'translate_global';
      }

      if (this.card.translateVariable) {
        this.translateVariable = this.card.translateVariable;
      } else {
        this.translateVariable = this.spreadType + '.' + this.deckInfo[translateKey] + '.' + this.card.image;
      }
    } else {
      this.translateVariable = this.spreadType + '.' + this.card.image;
    }

    // for spread positions only
    if (this.spread) {
      // show reversals selector if card has one
      if (this.spread.cards[this.index].reversed) {
        if (this.spreadType == 'tarot') {
          if (this.deckInfo?.reversal) {
            this.reverseCard = true;
          } else {
            this.reverseCard = false;
          };
        };
        if (this.spreadType == 'rune' && this.card.reversed) {
          this.reverseCard = true;
        };
      }

      // allow to change cards if in spread
      if (this.spread.cards) {
        this.position = this.spread.cards[this.index];
        this.next = this.spread.cards[this.index + 1];
        this.prev = this.spread.cards[this.index - 1];
      }
      // view-reading spread id is different. Need original for translations.
      // also needs to check if this spread.id exists
      this.standardSpread = this.spreadService.checkSpread(this.spreadId);
    }

    // for simple meanings browsing - scrolling to next and previous card
    if (!this.spread) {
      // get lastIndex to check if last card
      this.lastIndex = this[this.spreadType + 'Service'].lastIndex;
      var thisIndex = this[this.spreadType + 'Service'].getCardIndex(this.card.image);

      if (thisIndex != this.lastIndex) {
        this.next = this[this.spreadType + 'Service'].getCardByIndex(thisIndex + 1, this.userDeck)?.image;
      }
      if (thisIndex != 0) {
        this.prev = this[this.spreadType + 'Service'].getCardByIndex(thisIndex - 1, this.userDeck)?.image;
      }
    }

    // product info
    this.product = this.productService.getDeck(this.spreadType, this.userDeck);
  }

}
