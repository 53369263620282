import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RuneService } from '../../services/rune.service';

@Component({
  selector: 'app-rune',
  templateUrl: './rune.component.html',
  styleUrls: ['./rune.component.scss'],
})
export class RuneComponent implements OnInit {
  constructor(
    public runeService: RuneService,
  ) { }

  @Input()
  get stoneId(): any {
    return this._stoneId;
  }
  set stoneId(value) {
    this._stoneId = value;
    this.changeDeck();
  }
  _stoneId: any;
  @Input()
  get cardId(): string {
    return this._cardId;
  }
  set cardId(value) {
    this._cardId = value;
    this.changeDeck();
  }
  _cardId: string;
  @Input() letter: string;
  @Input() reversed: boolean;
  @Input() shadow: 'var(--shadow-elevated)' | 'var(--shadow-short)' = 'var(--shadow-elevated)';
  @Output() cardClicked = new EventEmitter<{ cardId: string }>();
  runeData: any;
  stone: any;

  changeDeck() {
    if (this.letter) {
      this.runeData = this.runeService.getRuneFromTranslit(this.letter);
    };
    if (this.cardId) {
      this.runeData = this.runeService.getCard(this.cardId)[0]?.rune;
    };
    this.stone = this.runeService.generateStone(this.runeData, this.runeService.decks[this.stoneId]);
  }

  clickCard() {
    this.cardClicked.emit({ cardId: this.cardId });
  }

  ngOnInit() {
    this.changeDeck();
  }

}
