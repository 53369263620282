<div class="banner journal ion-margin-bottom shadow" color="light" *ngIf="banner" (click)="openSales(0)">
  <div class="banner-content">
    <ion-badge>{{'purchases.premium.banner.badge' | translate}}</ion-badge>
    <h4>{{'purchases.premium.banner.title' | translate}}</h4>
    <span>{{'purchases.premium.banner.description' | translate}}</span>
  </div>
</div>

<div class="banner journal" color="light" *ngIf="banner_journal" (click)="openSales(1)">
  <div class="banner-content">
    <h4>{{'purchases.premium.banner.title' | translate}}</h4>
    <span [innerHTML]="'purchases.premium.banner.description_journal' | translate">
    </span>
  </div>
  <ion-button (click)="dismissBanner($event)" class="close">
    <ion-icon slot="icon-only" name="close-outline"></ion-icon>
  </ion-button>
</div>

<div class="banner lesson shadow" color="light" *ngIf="banner_lesson" (click)="openSales(4)">
  <div class="banner-content">
    <h4>{{'purchases.premium.banner.title' | translate}}</h4>
    <span [innerHTML]="'purchases.premium.banner.description_lesson' | translate">
    </span>
  </div>
</div>

<ion-modal [isOpen]="salesModal" class="full-web">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button (click)="dismissSales()" color="tertiary">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <h2 class="ion-padding-horizontal">
        {{'purchases.premium.modal_sell.title' | translate}}
      </h2>
      <swiper #slider [config]="config" class="primary-buttons buttons-top" [initialSlide]="activeSlide">
        <ng-template swiperSlide *ngFor="let slide of 'purchases.premium.slides' | translate, index as i">
          <div class="slider-content">
            <img src="../assets/premium/{{i}}.jpg"><br>
            <div class="content ion-padding">
              {{slide.title}}<br>
              <small>{{slide.desc}}</small>
            </div>
          </div>
        </ng-template>
      </swiper>

      <ion-grid *ngIf="plans && plans.length > 0"  class="ion-margin-horizontal ion-margin-top">
        <ion-row class="plans">
          <ion-col *ngFor="let sku of plans; index as i" size="4" (click)="selectedPlanIndex = i">
            <ion-card [ngClass]="{'selected': selectedPlanIndex == i}">
              <ion-badge color="tertiary" *ngIf="extravars[i].save">-{{extravars[i].save}}%</ion-badge>
              <h2>{{extravars[i].time}}</h2>
              <p class="cost" *ngIf="!web">
                {{ sku.product.price | number:'1.0-2' | currency: sku.product.currencyCode }} {{ extravars[i].time }}<br>
                <small>{{'purchases.premium.modal_sell.cancel' | translate}}</small>
              </p>
              <p class="cost" *ngIf="web">
                {{ sku.rcBillingProduct.currentPrice.formattedPrice }}
                {{ sku.rcBillingProduct.currentPrice.currency }}
                {{ extravars[i].time }}<br>
                <small>{{'purchases.premium.modal_sell.cancel' | translate}}</small>
              </p>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>

    <ion-footer color="primary" class="ion-padding-vertical">
      <ion-button color="tertiary" size="large" expand="block" (click)="purchase(selectedPlanIndex)" class="ion-margin-top"  class="ion-margin-horizontal">
        <div *ngIf="!trialEligible || selectedPlanIndex != 2">
          {{'purchases.premium.modal_sell.button' | translate}}
        </div>
        <div *ngIf="trialEligible && selectedPlanIndex == 2">
          {{'purchases.premium.modal_trial.button' | translate}}
        </div>
      </ion-button>

      <ion-grid class="ion-text-center">
        <ion-row>
          <ion-col>
            <a (click)="openURL('https://labyrinthos.co/pages/terms-of-service')">Terms</a>
          </ion-col>
          <ion-col>
            <a (click)="restore()" *ngIf="!web">Restore</a>
          </ion-col>
          <ion-col>
            <a (click)="openURL('https://labyrinthos.co/pages/privacy-policy')">Privacy</a>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </ng-template>
</ion-modal>

<ion-modal [isOpen]="trialModal" class="bg-image bg-primary full-web">
  <ng-template>
    <div class="flex-content">
      <img id="photo-fullscreen" src="/assets/premium/premium-trial-bg.jpg">
    </div>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button (click)="dismissSales()" color="tertiary">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content [fullscreen]="true">
      <div class="trial-header ion-padding-horizontal ion-text-center">
        <p class="capitals">{{'purchases.premium.modal_trial.subtitle' | translate}}</p>
        <h2>{{'purchases.premium.modal_trial.title' | translate}}</h2>
        <img src="/assets/premium/premium-trial-header.png">
      </div>
      <ion-card class="benefits-list round ion-margin">
        <ion-list>
          <ion-item *ngFor="let item of 'purchases.premium.short_features' | translate, index as i" lines="none">
            <ion-avatar slot="start">
              <img src="../assets/premium/item-{{i}}.png"><br>
            </ion-avatar>
            <ion-label text-wrap>
              {{item}}
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-card>

      <ion-card class="round benefits-list ion-padding" *ngIf="!user?.email">
        <ion-list>
          <ion-item lines="none" (click)="openSignup()">
            <ion-label text-wrap>
              <span class="capitals">Create an Account: </span>
              Try personalized readings without a subscription. Credits available for purchase when you're logged in.
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-card>

      <h2 class="ion-padding-horizontal ion-margin-top ion-padding-top ion-text-center">
        {{'purchases.premium.how.title' | translate}}
      </h2>
      <ul class="module-history">
        <li>
          <h3 class="capitals">{{'purchases.premium.how.0.title' | translate}}</h3>
          <time [innerHTML]="'purchases.premium.how.0.message' | translate"></time>
          <i></i>
        </li>
        <li>
          <h3 class="capitals">{{'purchases.premium.how.1.title' | translate}}</h3>
          <time [innerHTML]="'purchases.premium.how.1.message' | translate"></time>
          <i></i>
        </li>
        <li>
          <h3 class="capitals">{{'purchases.premium.how.2.title' | translate}}</h3>
          <time [innerHTML]="'purchases.premium.how.2.message' | translate: {date: trialEnd.toLocaleDateString()}"></time>
          <i></i>
        </li>
      </ul>

      <ion-grid class="ion-padding-horizontal features">
        <h2 class="ion-text-center">All Premium Features</h2>
        <ul>
          <li *ngFor="let data of 'pricing.premium.features' | translate; index as i">{{data}}</li>
        </ul>
      </ion-grid>

      <ion-grid class="awards ion-text-center">
        <img src="../assets/press-wired.png" (click)="openURL('https://www.wired.com/gallery/best-tarot-card-apps/')">
        <h2>
          {{'purchases.premium.modal_trial.wired_title' | translate}}<br>
          <span class="capitals" (click)="openURL('https://www.wired.com/gallery/best-tarot-card-apps/')">{{'purchases.premium.modal_trial.wired_subtitle' | translate}}</span><br>
        </h2>
        <ion-row class="features ion-margin-top ion-padding-top">
          <ion-col>
            <span class="store capitals">Android</span><br>
            <small>
              {{'purchases.premium.modal_trial.countries' | translate: {count: 80} }}<br>
              <strong>{{'purchases.premium.modal_trial.top_1' | translate}}</strong>
            </small>

          </ion-col>
          <ion-col>
            <span class="store capitals">Android</span><br>
            <small>
              {{'purchases.premium.modal_trial.countries' | translate: {count: 80} }}<br>
              <strong>{{'purchases.premium.modal_trial.top_2' | translate}}</strong>
            </small>

          </ion-col>
          <ion-col>
            <span class="store capitals">Apple</span><br>
            <small>
              {{'purchases.premium.modal_trial.countries' | translate: {count: 4} }}<br>
              <strong>{{'purchases.premium.modal_trial.top_3' | translate}}</strong>
            </small>
          </ion-col>
        </ion-row>
      </ion-grid>

      <div class="reviews ion-margin-top">
        <div *ngFor="let review of reviews" class="review ion-padding ion-margin">
          <div class="stars">
            <ion-icon name="star" color="tertiary" *ngFor="let star of [0,1,2,3,4]"></ion-icon>
          </div>
          <ion-note text-wrap>{{review.content}}</ion-note>
          <ion-label>{{review.user}}</ion-label>
        </div>
      </div>

      <ion-grid class="terms ion-text-center ion-margin-bottom">
        <ion-button fill="outline" color="light" (click)="restore()" mode="ios" *ngIf="!web">
          Restore Purchases
        </ion-button>
        <ion-row class="ion-margin">
          <ion-col>
            <a (click)="openURL('https://labyrinthos.co/pages/terms-of-service')">Terms of Use</a>
          </ion-col>
          <ion-col>
            <a (click)="openURL('https://labyrinthos.co/pages/privacy-policy')">Privacy Policy</a>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>

    <ion-footer>
      <ion-toolbar *ngIf="trialSku">
        <ion-button color="tertiary" size="large" expand="block" (click)="purchase(2)" class="ion-margin-top"  class="ion-margin-horizontal">
          {{'purchases.premium.modal_trial.button' | translate}}
        </ion-button>
        <ion-text class="ion-text-center ion-padding small" *ngIf="trialSku && !web" [innerHTML]="'purchases.premium.modal_trial.button_terms' | translate: {price: trialSku.product.price | number:'1.0-2' | currency: trialSku.product.currencyCode, time: extravars[2].time}">
        </ion-text>
        <ion-text class="ion-text-center ion-padding small" *ngIf="trialSku && web" [innerHTML]="'purchases.premium.modal_trial.button_terms' | translate: {price: '$79.99 USD', time: extravars[2].time}">
        </ion-text>
        </ion-toolbar>
    </ion-footer>
  </ng-template>
</ion-modal>
