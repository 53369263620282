<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <!-- <ion-button id="helpPop">
        <ion-icon slot="icon-only" name="help-circle-outline"></ion-icon>
      </ion-button> -->
    </ion-buttons>
    <ion-title class="ion-text-center">
      Call an AI Reader
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-popover trigger="helpPop">
    <ng-template>
      <p>
        <!-- Phase 1 -->
        AI calls are a beta feature that is only released for premium users. It is currently only available for web, but depending on how things go, I'd like to add it to mobile apps in the future.
        <br><br>
        Because of the high costs of running the service, we can't provide unlimited calls, but all premium users get {{perMonthMinutes}} free minutes every calendar month. Eventually calls will be available for purchase via credits. After every call, you'll get a request to review your caller. The rating is visible to all, but the reviews are currently only seen by me. I'd love to hear your feedback!
        <!-- Phase 2 -->
        <!-- AI calls cost <strong>{{costPerMin}}</strong> credits per minute (about <strong>${{ (699 / (777 / costPerMin) / 100).toFixed(2) }} USD per minute,</strong> if you purchase the 777 credits pack).
        <br><br>
        During Beta testing, this cost reflects a hefty discount, and gives us the ability to recoup some of the costs of running this relatively expensive service. It is likely that it will increase in cost later down the road if the current technology remains at the same price. -->
      </p>
    </ng-template>
  </ion-popover>
</ion-header>

<ion-content color="light" class="ion-padding-vertical">
  <ion-card class="round ion-padding-horizontal intro">
    <ion-grid>
      <ion-row>
        <!-- Phase 2 -->
        <!-- <ion-col size="6" class="credits">
          <img src="../assets/catssandra/credits.png">
          <ion-text>{{user.credits}}</ion-text>
        </ion-col> -->
        <ion-col size="12" class="minutes">
          <span class="emoji">⏳</span>
          <ion-text>{{maxMinutes}} min</ion-text>
        </ion-col>
      </ion-row>
      <div>
        <!-- Phase 2 -->
        <!-- AI calls cost {{costPerMin}} credits per minute. Your call will end when you run out of time. -->
        This feature is in Beta. Only premium users have access, and are currently alotted {{perMonthMinutes}} free minutes per month.<br><br>
        <ion-button fill="outline" size="small" class="xsmall" id="helpPop">More Info</ion-button>
      </div>
    </ion-grid>
  </ion-card>
  <ion-card class="round contact ion-padding" *ngFor="let reader of readers" lines="full" (click)="startCall(reader.id)">
    <ion-avatar>
      <img src="../assets/readers/{{reader.id}}-avatar.png">
      <span class="online"></span>
    </ion-avatar>
    <ion-label text-wrap>
      <h3>{{reader.id}}</h3>
      <div class="keywords">
        <ion-badge color="light" *ngFor="let keyword of ('report.readers.' + reader.id + '.keywords') | translate; index as i">{{keyword}}</ion-badge>
      </div>
    </ion-label>
    <div class="about">
      {{'report.readers.' + reader.id + '.description' | translate }}
    </div>
    <app-rating [rating]="reader.avg" [count]="reader.count" [viewOnly]="true"></app-rating>
  </ion-card>
</ion-content>
