import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { ChatService } from '../../services/chat.service';
import { UserService } from '../../services/user.service';
import { LogService } from '../../services/log.service';
import { ExportService } from '../../services/export.service';

@Component({
  selector: 'app-call-complete',
  templateUrl: './call-complete.page.html',
  styleUrls: ['./call-complete.page.scss'],
})
export class CallCompletePage implements OnInit {
  constructor(
    private modalController: ModalController,
    private toastController: ToastController,
    private userService: UserService,
    private chatService: ChatService,
    private logService: LogService,
    public exportService: ExportService,
  ) { }

  @Input()
  get reader(): string {
    return this._reader;
  }
  set reader(value) {
    // console.log('reader changed: ', value);
    this._reader = value;
  }
  _reader: string;
  @Input()
  get spread(): any {
    return this._spread;
  }
  set spread(value) {
    // console.log('spread changed: ', value);
    this._spread = value;
  }
  _spread: any;
  @Input()
  get transcript(): any {
    return this._transcript;
  }
  set transcript(value) {
    // console.log('transcript changed: ', value);
    this._transcript = value;
  }
  _transcript: any;
  @Input() cost: number;
  @Input() time: string;

  user: any;
  rating: number = 0;
  review: string = '';
  sentReview: boolean = false;
  remainingFreeMinutes: number;
  usedFreeMinutes: number;

  async dismiss() {
    const modal = await this.modalController.getTop();
    modal.dismiss();
  }

  async presentToast(message: string, color: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'top',
      duration: 3000,
      color: color
    });
    toast.present();
  }

  onRatingChange(ev: any) {
    this.rating = ev;
  }

  submit() {
    this.chatService.addReview(this.reader, this.rating, this.review, this.user).subscribe((data) => {
      this.sentReview = true;
      this.presentToast('Thanks for leaving a review!', 'success');
    });
  }

  deductMinutes() {
    var existing = this.user?.user_data?.monthlyCalls;
    var split: any = this.time.split(':');
    split = split.map(i => parseInt(i));
    this.usedFreeMinutes = (split[0] * 60) + split[1] + (split[2] / 60);
    this.remainingFreeMinutes = +(existing.minutes - this.usedFreeMinutes).toFixed(2);
    existing.minutes = this.remainingFreeMinutes;
    this.userService.setUserData('monthlyCalls', existing, true);
  }

  countSubstring(str: string, subStr: string) {
    let count = 0;
    let index = 0;

    while (index !== -1) {
      index = str.indexOf(subStr, index);
      if (index !== -1) {
        count++;
        index += subStr.length;
      }
    }

    return count;
  }

  addTranscriptToLog() {
    console.log('saving spread...', this.spread);
    var reports = this.spread.allReports;
    var transcription = {
      role: this.reader,
      transcript: this.transcript
    };

    if (reports) {
      this.spread.allReports.push(transcription);
    } else {
      this.spread['allReports'] = [transcription];
    };

    var spreadId = this.spread.id;
    var freeform = this.spread.id.includes('free');
    var custom = this.spread.id.includes('custom');
    var breaks = this.countSubstring(this.spread.id, '_');
    var savedSpread = (breaks == 1 && !custom) || (breaks == 2 && custom);

    if (!savedSpread) {
      this.logService.saveReading(this.spread).then((data) => {
        if (data) {
          // on autosave, change the id so that subsequent changes are edits, not new saves.
          this.spread = data;
          console.log('id changed: ', this.spread.id);
          this.dismiss();
        } else {
          // present upgrade modal if it fails to save...
          this.exportService.presentAlert('logs');
        }
      });
    } else {
      this.logService.editLog('logs', this.spread.id, this.spread).then(() => {
        this.dismiss();
      });
    }
  }

  ionViewWillEnter() {
    this.userService.getUser().then((data) => {
      this.user = data;
      this.deductMinutes();
    });
    this.sentReview = false;
  }

  ionViewWillLeave() {
    // bugs - still keeps old image, does not refresh.
    // same with spread
    this.spread = undefined;
    this.reader = undefined;
    this.transcript = undefined;
  }

  ngOnInit() {}

}
