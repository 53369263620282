import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnInit {
  constructor() { }

  @Input() rating: number;
  @Input() count: number;
  @Input() viewOnly: boolean;
  @Output() ratingChange: EventEmitter<number> = new EventEmitter();

  rate(index: number) {
    this.rating = index;
    this.ratingChange.emit(this.rating);
  }

  ngOnInit() {}

}
