import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-transcript',
  templateUrl: './transcript.component.html',
  styleUrls: ['./transcript.component.scss'],
})
export class TranscriptComponent implements OnInit {
  constructor() { }
  @Input() transcript: any;
  @Input() reader: string;

  ngOnInit() {}

}
