<ion-content *ngIf="user && !(!user?.email && web)" (click)="clickAvatar()">
  <ion-grid>
    <ion-row>
      <ion-col size="5" class="avatar">
        <img src="../assets/avatars/{{user.img}}" *ngIf="user.email">
        <img class="nameless" src="../assets/scenes/newUser2/avatar.png" *ngIf="!user.email">
        <ion-badge color="tertiary" *ngIf="user && user.permissions && user.permissions.features.indexOf('premium') > -1">
          <small>Premium</small>
        </ion-badge>
      </ion-col>
      <ion-col size="7" class="user-info">
        <h1>{{user.name}}</h1>
        <div class="capitals" *ngIf="user.email">{{'account_settings.status' | translate}} {{user.level}} {{user.title}}</div>
        <!-- This bar will not take updated data from user.exp, workaround -->
        <ion-progress-bar color="tertiary" value="{{getLevelPercent()}}" *ngIf="user.email"></ion-progress-bar>
        <p *ngIf="user.email">
          <small *ngIf="!user?.user_data?.birthdate">Click to calculate tarot birth cards.</small>
          <small *ngIf="user?.user_data?.birthdate && birthcards">
            <span>{{birthcards.title}}</span>
          </small>
        </p>
        <div *ngIf="!user.email">
          <div class="capitals">No Account</div>
          <small>Limited Features</small>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-list *ngIf="user && !(!user?.email && web)">
  <ion-item (click)="handle(page.handler)" *ngFor="let page of pages" class="capitals" lines="full" [ngClass]="{'ion-hide': (!user.email && page.login) || (web && !page.web)}">
    <ion-icon name="{{page.icon}}" slot="start" color="tertiary"></ion-icon>
    {{'menu.' + page.title | translate}}
  </ion-item>
</ion-list>

<ion-list *ngIf="!user?.email && web" class="public">
  <div class="main">
    <div class="app-logo">
      <img src="/assets/laby-logo-mark.svg">
    </div>
    <ion-item (click)="handle(page.handler)" *ngFor="let page of pagesPublic.main" lines="none">
      <ion-icon name="{{page.icon}}" slot="start" size="small" color="tertiary"></ion-icon>
      {{'menu.' + page.title | translate}}
    </ion-item>
  </div>
  <ion-grid class="download">
    <p class="capitals">Download App</p>
    <p class="content">Get the full experience on mobile. Our web app is in Beta.</p>
    <ion-button class="full" fill="outline" shape="round" (click)="handle(page.handler)" *ngFor="let page of pagesPublic.download">
      <ion-icon name="{{page.icon}}"></ion-icon>
      Download for {{page.title}}
    </ion-button>
  </ion-grid>
  <ion-grid class="social">
    <!-- <p class="ion-text-center capitals">Social</p> -->
    <ion-row>
      <ion-col size="4" *ngFor="let page of pagesPublic.social">
        <ion-button class="round" fill="outline" shape="round" (click)="handle(page.handler)">
          <ion-icon name="{{page.icon}}"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
    <p class="ion-text-center small">Labyrinthos LLC © 2024. All rights reserved.</p>
  </ion-grid>
</ion-list>
