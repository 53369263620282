import { Component, OnInit, Input, NgZone } from '@angular/core';
import { PurchaseService } from '../../services/purchase.service';
import { AnalyticsService } from '../../services/analytics.service';
import { ModalService } from '../../services/modal.service';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { User } from '../../user';
import { CatssandraPage } from '../../modals/catssandra/catssandra.page';
import { ModalController } from '@ionic/angular';
import SwiperCore, { SwiperOptions, Pagination } from 'swiper';
SwiperCore.use([Pagination]);
import { Storage } from '@ionic/storage';
import { LocalNotifications } from '@capacitor/local-notifications';

@Component({
  selector: 'app-premium',
  templateUrl: './premium.component.html',
  styleUrls: ['./premium.component.scss'],
})
export class PremiumComponent implements OnInit {
  constructor(
    private analyticsService: AnalyticsService,
    private purchaseService: PurchaseService,
    private modalService: ModalService,
    private route: Router,
    private iab: InAppBrowser,
    private modalController: ModalController,
    private zone: NgZone,
    private storage: Storage,
  ) {
    this.purchaseService.rcPermissions.subscribe(permissions => {
      if (permissions.features.indexOf('premium') > -1) {
        this.dismissSales();
      }
    });
    this.getOfferings();
  }

  @Input() banner: boolean;
  @Input() banner_journal: boolean;
  @Input() banner_lesson: boolean;
  @Input() banner_data: boolean;
  @Input() fab_ai: boolean;
  @Input() fab_learn: boolean;
  @Input() fab_journal: boolean;
  @Input() salesModal: boolean = false;
  @Input() trialModal: boolean = false;
  @Input()
  get user(): User {
    return this._user;
  }
  set user(value) {
    this._user = value;
    if (value && value.permissions && value.permissions.features.indexOf('premium') > -1) {
      this.salesModal = false;
      this.trialModal = false;
    }
  }
  _user: User;
  loading: boolean = true;
  plans: any = [];
  url: any = this.route.url;
  selectedPlanIndex: number = 1;
  activeSlide: number = 0;
  closedBanner: boolean = false;
  today: Date = new Date();
  trialEnd: Date = new Date(this.today.setHours(this.today.getHours() + 168));
  trialSku: any;
  trialEligible: boolean = false;
  web: boolean = Capacitor.getPlatform() == 'web';
  extravars: any = [
    {
      save: undefined,
      time: 'Monthly'
    },
    {
      save: 17,
      time: 'Quarterly'
    },
    {
      save: 25,
      time: 'Annually'
    },
  ];
  config: SwiperOptions = {
    spaceBetween: 15,
    slidesPerView: 1.25,
    pagination: {
      enabled: true,
      type: 'progressbar'
    },
    breakpoints: {
      480: {
        slidesPerView: 1.25
      },
      768: {
        slidesPerView: 2.25
      },
      992: {
        slidesPerView: 2.65
      }
    }
  };
  reviews: Array<any> = [
    {
      content: `Really easy to use for a total beginner! A very beautiful way to reflect and challenge your thinking, even if you're not into the supernatural. Helpful explanations of symbolism and archetypes, and alot of freedom to use it for a variety of purposes. Thank you for making this app free! I hope to buy one of the decks when I can afford it, but in the meantime this is wonderful!`,
      user: `Melanie`
    },
    {
      content: `This app is well thought out. A great learning layout for the ones that like to dig deep and a better than most tarot reader with a variety of card styles and types of readings. I don't even want to try another tarot app ever again because this one makes all the others obsolete. I'm new to this facet in the gem of spirituality and I'm so happy I came across this app to assist me while I embark on my new journey. Super exciting stuff! Definitely recommended and don't bother looking elsewhere!`,
      user: 'Jaye'
    },
    {
      content: `I've been reading tarot for myself and others for twelve years and still I find this app very useful, especially for a quick read on the go. Would definitely recommend for beginners as well, since it's very intuitive and concise but well explained. A must have app!`,
      user: 'Tamires'
    },
    {
      content: `I’ve just started getting into tarot and this app has been SO helpful in learning about working with the cards, learning the meanings, and giving me different spreads I can use. I love how you can pick which deck you want to use in the app so you can learn with a deck that speaks to you, honestly an amazing app!`,
      user: 'Billie'
    },{
      content: `My sister in law introduced me to this app and my life has forever been changed. I feel more connected to myself and my destiny. I’m learning more about Tarot and how i am interconnected with the universe and Divine will. This app has empowered me to tackle some of the hardest parts of my life. I am grateful.`,
      user: 'Isthatyou'
    }
  ];

  openEligibleModal(activeSlide?: number) {
    // if they have never gotten this product before, offer a free trial.
    // for web, only show if they're logged in.
    if ((this.trialEligible && !this.web) || (this.trialEligible && this.web && this.user.email)) {
      this.openTrial();
    } else {
      this.openSales(activeSlide ? activeSlide : undefined);
    }
  }

  openSales(activeSlide?: number, ev?: any) {
    if (ev) {
      ev.stopPropogation();
    };
    if (activeSlide) {
      this.activeSlide = activeSlide;
    };
    this.salesModal = true;
    this.analyticsService.logEvent('paywall', {
      content_id: 'premium_modal',
      source: this.url,
      content_type: this.activeSlide
    });
  }

  openTrial(ev?: any) {
    if (ev) {
      ev.preventDefault();
    };
    this.trialModal = true;
    this.analyticsService.logEvent('paywall', {
      content_id: 'trial_modal',
      source: this.url,
      content_type: this.activeSlide
    });
  }

  async dismissSales() {
    this.salesModal = false;
    this.trialModal = false;
    // let modal = await this.modalController.getTop();
    // await modal.dismiss();
  }

  dismissBanner(event: any) {
    event.preventDefault();
    this.banner_journal = false;

    // hide banner for next 24 hours
    var today = new Date();
    var threeDays = new Date(today.setDate(today.getDate() + 1));
    var prettyDate = (today.getMonth() + 1) + '/' + today.getDate() + '/' +  today.getFullYear();

    this.storage.ready().then(() => {
      this.storage.set('hide_banner', threeDays);
    })
  }

  async marketingInfo() {
    const modal = await this.modalController.create({
      component: CatssandraPage,
      id: 'marketing',
      componentProps: {
        showFooter: false,
        showReading: false,
        articleId: 'catssandra',
        cssClass: 'full-web'
      }
    });
    await modal.present();
  }

  openURL(url) {
    const browser = this.iab.create(url, '_system');
    this.analyticsService.logEvent('visit_website', {
      content_type: 'terms',
      content_id: url
    });
  }

  async purchase(index: number) {
    if (this.trialModal) {
      this.notify();
    };
    if (this.web) {
      await this.purchaseService.purchaseWeb(this.plans[index]);
    } else {
      await this.purchaseService.purchase(this.plans[index]);
    }
    this.analyticsService.logEvent('start_purchase', {
      content_id: 'premium',
      sku: this.plans[index].skuId,
      source: this.url,
    });
  }

  getOfferings() {
    this.purchaseService.offerings.subscribe((res) => {
      if (res) {
        this.plans = res.premium?.availablePackages;
        this.trialSku = res.premium?.availablePackages[2];
        console.log(this.trialSku);

        if (Capacitor.getPlatform() == 'ios') {
          var trialId = '$rc_annual';
          this.purchaseService.checkTrial([trialId]).then((data) => {
            var trial = data[trialId].status;
            if (trial == 2 || trial == 0) {
              this.trialEligible = true;
            } else {
              this.trialEligible = false;
            }
          });
        } else if (Capacitor.getPlatform() == 'android') {
          // on android, eligibility is done differently
          var options = this.trialSku.product.subscriptionOptions;
          var trial = options.filter(o => o.tags.includes('free-trial'));

          if (trial.length > 0) {
            this.trialEligible = true;
          };
        } else if (Capacitor.getPlatform() == 'web') {
          // on web, eligibility is done differently
          this.plans = res.premium?.availablePackages;
          this.trialSku = res.premium?.availablePackages[2]?.rcBillingProduct?.defaultPurchaseOption;
          if (this.trialSku?.id.includes('offer')) {
            this.trialEligible = true;
          };
        }
      }
    });
  }

  notify() {
    var day5 = new Date(this.today.setHours(this.today.getHours() + 120));
    LocalNotifications.requestPermissions();
    LocalNotifications.schedule({
      notifications: [
        {
          id: 1,
          title: "🔔 Trial ending in 2 days.",
          body: "Thanks for trying Labyrinthos Premium. We hope you'll continue to enjoy your unlimited entries, personalized readings and lessons, and more.",
          schedule: { at: day5 }
        }
      ]
    });

    LocalNotifications.getPending().then((data) => {
      console.log('notifications are being scheduled: ', data.notifications);
    });
  }

  openSignup() {
    this.trialModal = false;
    this.salesModal = false;
    this.modalService.openSignup();
  }

  async restore() {
    await this.purchaseService.restore();
  }

  ngOnInit() {
    setTimeout(() => {
      this.analyticsService.logEvent('paywall', {
        content_id: 'premium_fab',
        source: this.url,
        content_type: this.activeSlide
      });
    }, 2000);
  }

}
