import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})

export class ThemeService {
  selected: string;
  themes: any = [
    {
      name: 'day',
      icon: 'sunny-outline',
      styles: [
        { themeVariable: '--ion-background-color', value: '#FFFDF8'},
        { themeVariable: '--ion-color-bg', value: '#FFFDF8'},
        { themeVariable: '--ion-color-text', value: '#222222'},
        { themeVariable: '--ion-color-primary', value: '#0A2722'},
        { themeVariable: '--ion-color-primary-shade', value: '#061B18'},
        { themeVariable: '--ion-color-primary-tint', value: '#465551'},
        { themeVariable: '--ion-color-secondary', value: '#2C1140'},
        { themeVariable: '--ion-color-tertiary', value: '#B89662'},
        { themeVariable: '--ion-color-tertiary-contrast', value: '#FFFFFF'},
        { themeVariable: '--ion-color-tertiary-rgb', value: '184, 150, 98'},
        { themeVariable: '--ion-color-tertiary-shade', value: '#A88550'},
        { themeVariable: '--ion-color-tertiary-tint', value: '#E6D9C3'},
        { themeVariable: '--ion-color-light', value: '#FAF6ED'},
        { themeVariable: '--ion-color-light-tint', value: '#FFFDF8'},
        { themeVariable: '--ion-color-light-shade', value: '#f5ead9'},
        { themeVariable: '--ion-color-light-rgb', value: '250, 246, 237'},
        { themeVariable: '--ion-color-light-contrast', value: '#A88550'},
      ]
    },
    {
      name: 'night',
      icon: 'moon-outline',
      styles: [
        { themeVariable: '--ion-background-color', value: '#2C1140'},
        { themeVariable: '--ion-color-bg', value: '#2C1140'},
        { themeVariable: '--ion-color-text', value: '#FFFFFF'},
        { themeVariable: '--ion-color-primary', value: '#B89662'},
        { themeVariable: '--ion-color-primary-shade', value: '#A88550'},
        { themeVariable: '--ion-color-primary-tint', value: '#D1A974'},
        { themeVariable: '--ion-color-secondary', value: '#180725'},
        { themeVariable: '--ion-color-tertiary', value: '#735589'},
        { themeVariable: '--ion-color-tertiary-contrast', value: '#FFFFFF'},
        { themeVariable: '--ion-color-tertiary-rgb', value: '115, 85, 137'},
        { themeVariable: '--ion-color-tertiary-shade', value: '#735589'},
        { themeVariable: '--ion-color-tertiary-tint', value: '#3E1D5B'},
        { themeVariable: '--ion-color-light', value: '#180725'},
        { themeVariable: '--ion-color-light-tint', value: '#220836'},
        { themeVariable: '--ion-color-light-shade', value: '#09010e'},
        { themeVariable: '--ion-color-light-rgb', value: '24, 7, 37'},
        { themeVariable: '--ion-color-light-contrast', value: '#FAF6ED'},
      ]
    },
    {
      name: 'earth',
      icon: 'leaf-outline',
      styles: [
        { themeVariable: '--ion-background-color', value: '#e8eeec'},
        { themeVariable: '--ion-color-bg', value: '#e8eeec'},
        { themeVariable: '--ion-color-text', value: '#071a14'},
        { themeVariable: '--ion-color-primary', value: '#123e30'},
        { themeVariable: '--ion-color-primary-shade', value: '#0f3529'},
        { themeVariable: '--ion-color-primary-tint', value: '#144636'},
        { themeVariable: '--ion-color-secondary', value: '#0A2722'},
        { themeVariable: '--ion-color-tertiary', value: '#1f664e'},
        { themeVariable: '--ion-color-tertiary-contrast', value: '#FFFFFF'},
        { themeVariable: '--ion-color-tertiary-rgb', value: '184, 150, 98'},
        { themeVariable: '--ion-color-tertiary-shade', value: '#195844'},
        { themeVariable: '--ion-color-tertiary-tint', value: '#306957'},
        { themeVariable: '--ion-color-light', value: '#d1deda'},
        { themeVariable: '--ion-color-light-tint', value: '#e8eeec'},
        { themeVariable: '--ion-color-light-shade', value: '#bacdc7'},
        { themeVariable: '--ion-color-light-contrast', value: '#071a14'},
      ]
    },
    {
      name: 'water',
      icon: 'water-outline',
      styles: [
        { themeVariable: '--ion-background-color', value: '#e8eef0'},
        { themeVariable: '--ion-color-bg', value: '#e8eef0'},
        { themeVariable: '--ion-color-text', value: '#061115'},
        { themeVariable: '--ion-color-primary', value: '#143b48'},
        { themeVariable: '--ion-color-primary-shade', value: '#11323e'},
        { themeVariable: '--ion-color-primary-tint', value: '#174352'},
        { themeVariable: '--ion-color-secondary', value: '#0f2a34'},
        { themeVariable: '--ion-color-tertiary', value: '#1d5467'},
        { themeVariable: '--ion-color-tertiary-contrast', value: '#FFFFFF'},
        { themeVariable: '--ion-color-tertiary-rgb', value: '184, 150, 98'},
        { themeVariable: '--ion-color-tertiary-shade', value: '#1a4c5d'},
        { themeVariable: '--ion-color-tertiary-tint', value: '#346576'},
        { themeVariable: '--ion-color-light', value: '#d2dde1'},
        { themeVariable: '--ion-color-light-tint', value: '#e8eef0'},
        { themeVariable: '--ion-color-light-shade', value: '#a5bbc2'},
        { themeVariable: '--ion-color-light-contrast', value: '#061115'},
      ]
    },
    {
      name: 'air',
      icon: 'cloud-outline',
      styles: [
        { themeVariable: '--ion-background-color', value: '#f8f5ef'},
        { themeVariable: '--ion-color-bg', value: '#f8f5ef'},
        { themeVariable: '--ion-color-text', value: '#372d1d'},
        { themeVariable: '--ion-color-primary', value: '#6e5a3b'},
        { themeVariable: '--ion-color-primary-shade', value: '#5c4b31'},
        { themeVariable: '--ion-color-primary-tint', value: '#816945'},
        { themeVariable: '--ion-color-secondary', value: '#372d1d'},
        { themeVariable: '--ion-color-tertiary', value: '#B89662'},
        { themeVariable: '--ion-color-tertiary-contrast', value: '#FFFFFF'},
        { themeVariable: '--ion-color-tertiary-rgb', value: '184, 150, 98'},
        { themeVariable: '--ion-color-tertiary-shade', value: '#A88550'},
        { themeVariable: '--ion-color-tertiary-tint', value: '#E6D9C3'},
        { themeVariable: '--ion-color-light', value: '#f1eae0'},
        { themeVariable: '--ion-color-light-tint', value: '#f8f5ef'},
        { themeVariable: '--ion-color-light-shade', value: '#eae0d0'},
        { themeVariable: '--ion-color-light-contrast', value: '#372d1d'},
      ]
    },
    {
      name: 'fire',
      icon: 'flame-outline',
      styles: [
        { themeVariable: '--ion-background-color', value: '#f7ecea'},
        { themeVariable: '--ion-color-bg', value: '#f7ecea'},
        { themeVariable: '--ion-color-text', value: '#061115'},
        { themeVariable: '--ion-color-primary', value: '#672718'},
        { themeVariable: '--ion-color-primary-shade', value: '#562114'},
        { themeVariable: '--ion-color-primary-tint', value: '#782e1c'},
        { themeVariable: '--ion-color-secondary', value: '#220d08'},
        { themeVariable: '--ion-color-tertiary', value: '#ac4128'},
        { themeVariable: '--ion-color-tertiary-contrast', value: '#FFFFFF'},
        { themeVariable: '--ion-color-tertiary-rgb', value: '184, 150, 98'},
        { themeVariable: '--ion-color-tertiary-shade', value: '#9b3b24'},
        { themeVariable: '--ion-color-tertiary-tint', value: '#b4543e'},
        { themeVariable: '--ion-color-light', value: '#e6c6bf'},
        { themeVariable: '--ion-color-light-tint', value: '#eed9d4'},
        { themeVariable: '--ion-color-light-shade', value: '#deb3a9'},
        { themeVariable: '--ion-color-light-contrast', value: '#061115'},
      ]
    },
  ]

  constructor(private domCtrl: DomController, @Inject(DOCUMENT) private document, private storage: Storage) {}

  setInitialTheme() {
    this.storage.get('SELECTED_THEME').then(val => {
      if (val) {
        this.setTheme(val);
        this.selected = val;
      }
    });
  }

  getThemes() {
    return this.themes;
  }

  setTheme(name) {
    let theme = this.themes.find(theme => theme.name === name);
    this.domCtrl.write(() => {
      theme.styles.forEach(style => {
        document.documentElement.style.setProperty(style.themeVariable, style.value);
      });
    });
    this.selected = name;
    this.storage.set('SELECTED_THEME', name);
  }

}
