import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import SwiperCore, { SwiperOptions } from 'swiper';
import { ActivatedRoute, RouterModule, Router, NavigationExtras } from '@angular/router';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'app-catssandra',
  templateUrl: './catssandra.page.html',
  styleUrls: ['./catssandra.page.scss'],
})

export class CatssandraPage {
  constructor(
    private modalController: ModalController,
    private router: Router,
    private route: ActivatedRoute,
    public chatService: ChatService,
  ) { }

  @ViewChild('slider') slider;
  @Input() showFooter: boolean = true;
  @Input() showReading: boolean = false;
  @Input() articleId: string;
  progress: number = 0;
  activeIndex: number = 1;
  config: SwiperOptions = {
    slidesPerView: 1,
    navigation: true
  };
  helpArticles: any = [
    {
      id: 'catssandra',
      slidesImageUrl: '../assets/catssandra/slides',
      action: () => this.closeAndMove('buy')
    },
    {
      id: 'report-card',
      slidesImageUrl: '../assets/catssandra/slides',
      action: () => this.closeAndMove('tabs/tab-spreads')
    }
  ];

  async dismiss() {
    console.log('dismiss called');

    let modal = await this.modalController.getTop();
    await modal.dismiss();
  }

  toCreditsShopTab() {
    var url: string = 'tabs/tab-shop/';
    let navigationExtras: NavigationExtras = {
      state: {
        index: 1,
        category: 'consumables'
      }
    };
    this.router.navigate([url], navigationExtras);
  }

  buyCredits() {
    this.dismiss();
    this.toCreditsShopTab();
  }

  closeAndMove(url: string) {
    this.dismiss();
    this.router.navigateByUrl(url);
  }
}
