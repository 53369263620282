<ion-content color="secondary">
  <ion-card class="round" color="secondary">
    <ion-avatar>
      <img src="../assets/readers/{{reader}}-avatar.png">
    </ion-avatar>
    <ion-label textwrap>
      <h3>{{reader}}</h3>
      <p *ngIf="timeElapsed > 0">{{timeElapsedPretty}}</p>
      <ion-spinner name="dots" *ngIf="timeElapsed === 0"></ion-spinner>
    </ion-label>
    <ion-button color="danger" size="large" shape="round" (click)="endCall()">
      <ion-icon name="call-outline" slot="icon-only"></ion-icon>
    </ion-button>
  </ion-card>
</ion-content>
