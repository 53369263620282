import { Component, OnInit, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.page.html',
  styleUrls: ['./loading.page.scss'],
})
export class LoadingPage {
  constructor() { }

  @Input() downloadProgress: Observable<number>;
  @Input() downloadItem: Observable<string>;
  @Input() product: any;
  @Input() userDeck: string;
  progress: number = 0;
  item: string;

  ngAfterContentInit() {
    console.log(this.downloadProgress);
    if (this.downloadProgress) {
      this.downloadProgress.pipe().subscribe(progress => this.progress = progress);
    }
    if (this.downloadItem) {
      this.downloadItem.pipe().subscribe(item => this.item = item);
    }
  }

}
