<ion-header>
  <ion-toolbar class="ion-text-center">
    <ion-buttons slot="start">
    </ion-buttons>
    <ion-title>{{ 'notifications.title' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-text-center">
  <ion-item color="danger" class="warning" *ngIf="!enabled">
    <ion-button (click)="openSettings()" fill="clear" slot="end" color="light">
      {{'notifications.warning.none_set_button' | translate}}
    </ion-button>
    <ion-label class="ion-text-wrap" [innerHTML]="'notifications.warning.not_enabled' | translate"></ion-label>
  </ion-item>
  <ion-item color="warning" class="warning" *ngIf="pending.length == 0">
    <ion-label class="ion-text-wrap">{{'notifications.warning.none_set' | translate}}</ion-label>
  </ion-item>
  <p>{{ 'notifications.content' | translate }}</p>
  <ion-list>
    <ion-item-group>
      <ion-item-divider class="capitals">
        <ion-label>{{ 'notifications.opt_weekly' | translate }}</ion-label>
      </ion-item-divider>
      <ion-item>
        <ion-icon name="alarm-outline" slot="start" color="tertiary"></ion-icon>
        <ion-label slot="start">{{ 'notifications.time' | translate }}</ion-label>
        <ion-input value="{{ notifyTime }}" id="time" class="ion-text-end" placeholder="{{ placeholderTime }}"></ion-input>
        <ion-popover trigger="time" size="auto" [keepContentsMounted]="true" mode="md">
          <ng-template>
            <ion-datetime presentation="time" (ionChange)="timeChange($event)" mode="md" color="tertiary"></ion-datetime>
          </ng-template>
        </ion-popover>
      </ion-item>
      <ion-item>
        <ion-icon name="calendar-outline" slot="start" color="tertiary"></ion-icon>
        <ion-label>{{ 'notifications.days' | translate }}</ion-label>
        <ion-button size="small" color="tertiary" fill="outline" (click)="selectAll(true)" slot="end" mode="md" *ngIf="!checkedAll">Select All</ion-button>
        <ion-button size="small" color="tertiary" fill="outline" (click)="selectAll(false)" slot="end" mode="md" *ngIf="checkedAll">Deselect All</ion-button>
      </ion-item>

      <ion-item *ngFor="let day of days">
        <ion-label>{{ '_common.days.' + day.title | translate }}</ion-label>
        <ion-checkbox slot="end" [(ngModel)]="day.checked" (ionChange)="checkChecked()"></ion-checkbox>
      </ion-item>
    </ion-item-group>

    <ion-item-divider class="capitals">
      <ion-label>{{ 'notifications.opt_monthly' | translate }}</ion-label>
    </ion-item-divider>
    <ion-item>
      <ion-label class="ion-text-wrap">{{ 'notifications.opt_monthly_info' | translate }}</ion-label>
    </ion-item>
    <ion-item *ngFor="let day of month">
      <ion-label>{{ 'notifications.first_month' | translate }}</ion-label>
      <ion-checkbox slot="end" [(ngModel)]="day.checked"></ion-checkbox>
    </ion-item>

    <ion-item-divider class="capitals">
      <ion-label>{{ 'notifications.opt_moon' | translate }}</ion-label>
    </ion-item-divider>
    <ion-item>
      <ion-label class="ion-text-wrap">{{ 'notifications.opt_moon_info' | translate }}</ion-label>
    </ion-item>
    <ion-item *ngFor="let moonType of moon">
      <ion-label>{{ 'notifications.moon_' + moonType.title | translate }}</ion-label>
      <ion-checkbox slot="end" [(ngModel)]="moonType.checked"></ion-checkbox>
    </ion-item>

    <ion-item-divider class="capitals">
      <ion-label>{{ 'notifications.opt_newsletter' | translate }}</ion-label>
    </ion-item-divider>
    <ion-item (click)="openBrowser('https://omniform1.com/forms/v1/landingPage/5cc257138653ed5a50b11780/64c846199dc61f48ef734ccc')">
      <ion-icon name="mail-outline" slot="start" color="tertiary"></ion-icon>
      <ion-icon name="arrow-forward-outline" slot="end" color="tertiary"></ion-icon>
      <ion-label class="ion-text-wrap">{{ 'notifications.newsletter' | translate }}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar color="light">
    <!-- Debug Testing -->
    <!-- <ion-button (click)="notifyNow()" color="primary" expand="full">
      Debug Notifications
    </ion-button> -->
    <!-- End Debug Testing -->
    <ion-button (click)="addNotifications()" color="primary" expand="full">
      {{ 'notifications.save' | translate }}
    </ion-button>
    <ion-button (click)="cancelAll(true, true)" color="light" expand="full">
      {{ 'notifications.cancel' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>
