<div class="section" *ngIf="type && !horizontal">
  <ion-grid *ngIf="offerings && offerings.length === 0">
    <ion-row>
      <ion-col *ngFor="let i of [1,2,3]" size="4">
        <ion-card>
          <ion-thumbnail class="ion-text-center">
             <ion-skeleton-text [animated]="true" style="height: 100px"></ion-skeleton-text>
          </ion-thumbnail>
          <ion-card-content class="ion-text-center">
            <h3>
              <ion-skeleton-text [animated]="true" style="width: 80%;"></ion-skeleton-text>
            </h3>
            <p>
              <ion-skeleton-text [animated]="true" style="width: 60%;"></ion-skeleton-text>
            </p>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid *ngIf="offerings">
    <ion-row>
      <ion-col *ngFor="let sku of offerings; index as i" size="4">
        <ion-card (click)="purchase(sku)">
          <ion-badge color="tertiary" *ngIf="save[type] && save[type][i] > 0">-{{save[type][i]}}%</ion-badge>
          <ion-thumbnail class="ion-text-center bg-light" [ngClass]="cssClass">
            <span class="v-align-helper"></span>
            <img src="../assets/offerings/{{ sku.product.identifier }}.png">
          </ion-thumbnail>
          <ion-card-content>
            <span class="v-align-helper"></span>
            <h2>{{ sku.product.title }}</h2>
            <div class="round">
              <span>
                {{ sku.product.price | number:'1.0-2' | currency: sku.product.currencyCode }}
              </span>
            </div>
          </ion-card-content>
          <div class="overlay no-click" *ngIf="checkOwned(sku)">
            <div>
              <ion-icon name="checkmark-circle-outline"></ion-icon><br>
              <span class="capitals">Owned</span>
            </div>
          </div>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<div *ngIf="horizontal && offerings.length == 0">
  <ion-item *ngFor="let i of [1,2,3]">
    <ion-label>
      <h3>
        <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
      </h3>
      <p>
        <ion-skeleton-text [animated]="true" style="width: 80%;"></ion-skeleton-text>
      </p>
      <p>
        <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>
</div>

<div *ngIf="type == 'avatars' && horizontal">
  <ion-row *ngFor="let sku of offerings" class="avatarPack">
    <div class="content" (click)="purchase(sku)" style="background: url('../assets/avatars/{{sku.product.identifier}}.jpg')">
      <h2>{{sku.product.title.replace('(Labyrinthos Tarot)', '')}}</h2>
      <p>
        {{sku.product.description }}<br>
        <span class="cost" *ngIf="permissions.avatars.indexOf(sku.skuId) == -1">
          {{ sku.product.price | number:'1.0-2' | currency: sku.product.currencyCode }}
        </span>
        <ion-badge class="purchased" color="tertiary" *ngIf="permissions.avatars.indexOf(sku.product.identifier) > -1">
          Purchased
        </ion-badge>
      </p>
    </div>
    <ion-col *ngFor="let avatar of paidAvatars[sku.product.identifier]" size="4" size-md="2" class="avatar-option" (click)="purchase(sku)">
      <div [ngClass]="{'purchased': permissions.avatars.indexOf(sku.product.identifier) > -1}">
        <ion-icon name="checkmark-circle-outline" color="tertiary" *ngIf="permissions.avatars.indexOf(sku.product.identifier) > -1"></ion-icon>
        <img src="../assets/avatars/{{avatar.img}}"/>
      </div>
    </ion-col>
  </ion-row>
</div>

<div *ngIf="dealAvailable">
  <div class="specials" *ngIf="type && horizontal == true && offerings?.skus">
    <div class="banner ion-margin-bottom shadow" color="light" (click)="purchase(sku)" *ngFor="let sku of offerings.skus; index as i">
      <img src="../assets/offerings/{{sku.productId}}.png">
      <div class="img-overlay">
        <ion-badge color="tertiary">47% off</ion-badge>
        <h4>{{ sku.product.title }}</h4>
        <p class="price">{{ sku.product.price | number:'1.0-2' | currency: sku.product.currencyCode }}</p>
      </div>
      <div class="banner-content">
        <p class="capitals">Limited Time Offer</p>
        <p class="counter">{{countdown.dd}}:{{countdown.hh}}:{{countdown.mm}}:{{countdown.ss}}</p>
        <span>
          {{'purchases.starter_pack.description' | translate}}
        </span>
      </div>
    </div>
  </div>
</div>
