import { Injectable } from '@angular/core';
import { TarotService } from './tarot.service';
import { LenormandService } from './lenormand.service';
import { AstroService } from './astro.service';
import { RuneService } from './rune.service';

@Injectable({
  providedIn: 'root'
})
export class LessonService {
  constructor(
    private tarotService: TarotService,
    private lenormandService: LenormandService,
    private astroService: AstroService,
    private runeService: RuneService,
  ) { }

  classes: any = [
    {
      id: "intro",
      memory: "newUser",
      prereq: null,
      article: "intro",
    },
    {
      id: "magic",
      prereq: null,
      article: "magic",
    },
    {
      id: "tarot-major",
      prereq: null,
      article: "major",
      lessons: [
        {
          id: "tarot-major_1",
          type: "single",
          cards: [0,1,2,3,4,5],
          icon: "lesson-01.png"
        },
        {
          id: "tarot-major_2",
          type: "single",
          cards: [6,7,8,9,10,11],
          icon: "lesson-02.png"
        },
        {
          id: "tarot-major_3",
          type: "single",
          cards: [12,13,14,15,16],
          icon: "lesson-03.png"
        },
        {
          id: "tarot-major_4",
          type: "single",
          cards: [17,18,19,20,21],
          icon: "lesson-04.png"
        },
        {
          id: "tarot-major_5",
          type: "single",
          cards: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21],
          icon: "lesson-05.png"
        }
      ]
    },
    {
      id: "minor",
      prereq: null,
      article: "minor",
    },
    {
      id: "tarot-wands",
      prereq: null,
      article: "wands",
      lessons: [
        {
          id: "tarot-wands_1",
          type: "single",
          cards: [22,23,24,25,26],
          icon: "lesson-06.png"
        },
        {
          id: "tarot-wands_2",
          type: "single",
          cards: [27,28,29,30,31],
          icon: "lesson-07.png"
        },
        {
          id: "tarot-wands_3",
          type: "single",
          cards: [22,23,24,25,26,27,28,29,30,31],
          icon: "lesson-08.png"
        }
      ]
    },
    {
      id: "tarot-cups",
      prereq: null,
      article: "cups",
      lessons: [
        {
          id: "tarot-cups_1",
          type: "single",
          cards: [36,37,38,39,40],
          icon: "lesson-09.png"
        },
        {
          id: "tarot-cups_2",
          type: "single",
          cards: [41,42,43,44,45],
          icon: "lesson-10.png"
        },
        {
          id: "tarot-cups_3",
          type: "single",
          cards: [36,37,38,39,40,41,42,43,44,45],
          icon: "lesson-11.png"
        }
      ]
    },
    {
      id: "tarot-swords",
      prereq: null,
      article: "swords",
      lessons: [
        {
          id: "tarot-swords_1",
          type: "single",
          cards: [50,51,52,53,54],
          icon: "lesson-12.png"
        },
        {
          id: "tarot-swords_2",
          type: "single",
          cards: [55,56,57,58,59],
          icon: "lesson-13.png"
        },
        {
          id: "tarot-swords_3",
          type: "single",
          cards: [50,51,52,53,54,55,56,57,58,59],
          icon: "lesson-14.png"
        }
      ]
    },
    {
      id: "tarot-pentacles",
      prereq: null,
      article: "pentacles",
      lessons: [
        {
          id: "tarot-pentacles_1",
          type: "single",
          cards: [64,65,66,67,68],
          icon: "lesson-15.png"
        },
        {
          id: "tarot-pentacles_2",
          type: "single",
          cards: [69,70,71,72,73],
          icon: "lesson-16.png"
        },
        {
          id: "tarot-pentacles_3",
          type: "single",
          cards: [64,65,66,67,68,69,70,71,72,73],
          icon: "lesson-17.png"
        }
      ]
    },
    {
      id: "tarot-court",
      prereq: null,
      article: "court",
      lessons: [
        {
          id: "tarot-court_1",
          type: "single",
          cards: [32,46,60,74],
          icon: "lesson-18.png"
        },
        {
          id: "tarot-court_2",
          type: "single",
          cards: [33,47,61,75],
          icon: "lesson-19.png"
        },
        {
          id: "tarot-court_3",
          type: "single",
          cards: [34,48,62,76],
          icon: "lesson-20.png"
        },
        {
          id: "tarot-court_4",
          type: "single",
          cards: [35,49,63,77],
          icon: "lesson-21.png"
        },
        {
          id: "tarot-court_5",
          type: "single",
          cards: [32,46,60,74,33,47,61,75,34,48,62,76,35,49,63,77],
          icon: "lesson-22.png"
        }
      ]
    },
    {
      id: "tarot-all",
      prereq: null,
      memory: "sleeper",
      article: "reading",
      lessons: [
        {
          id: "tarot-all",
          type: "single",
          cards: Array.apply(null, {length: 78}).map(Number.call, Number),
          icon: "lesson-34.png"
        }
      ]
    },
    {
      id: "tarot-reverse-major",
      prereq: null,
      article: "reversed",
      lessons: [
        {
          id: "tarot-reverse-major_1",
          type: "single",
          cards: [0,1,2,3,4,5],
          icon: "lesson-44.png"
        },
        {
          id: "tarot-reverse-major_2",
          type: "single",
          cards: [6,7,8,9,10,11],
          icon: "lesson-45.png"
        },
        {
          id: "tarot-reverse-major_3",
          type: "single",
          cards: [12,13,14,15,16],
          icon: "lesson-46.png"
        },
        {
          id: "tarot-reverse-major_4",
          type: "single",
          cards: [17,18,19,20,21],
          icon: "lesson-47.png"
        },
        {
          id: "tarot-reverse-major_5",
          type: "single",
          cards: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21],
          icon: "lesson-48.png"
        }
      ]
    },
    {
      id: "tarot-reverse-wands",
      prereq: null,
      lessons: [
        {
          id: "tarot-reverse-wands_1",
          type: "single",
          cards: [22,23,24,25,26],
          icon: "lesson-49.png"
        },
        {
          id: "tarot-reverse-wands_2",
          type: "single",
          cards: [27,28,29,30,31],
          icon: "lesson-50.png"
        },
        {
          id: "tarot-reverse-wands_3",
          type: "single",
          cards: [22,23,24,25,26,27,28,29,30,31],
          icon: "lesson-51.png"
        }
      ]
    },
    {
      id: "tarot-reverse-cups",
      prereq: null,
      lessons: [
        {
          id: "tarot-reverse-cups_1",
          type: "single",
          cards: [36,37,38,39,40],
          icon: "lesson-52.png"
        },
        {
          id: "tarot-reverse-cups_2",
          type: "single",
          cards: [41,42,43,44,45],
          icon: "lesson-53.png"
        },
        {
          id: "tarot-reverse-cups_3",
          type: "single",
          cards: [36,37,38,39,40,41,42,43,44,45],
          icon: "lesson-54.png"
        }
      ]
    },
    {
      id: "tarot-reverse-swords",
      prereq: null,
      lessons: [
        {
          id: "tarot-reverse-swords_1",
          type: "single",
          cards: [50,51,52,53,54],
          icon: "lesson-55.png"
        },
        {
          id: "tarot-reverse-swords_2",
          type: "single",
          cards: [55,56,57,58,59],
          icon: "lesson-56.png"
        },
        {
          id: "tarot-reverse-swords_3",
          type: "single",
          cards: [50,51,52,53,54,55,56,57,58,59],
          icon: "lesson-57.png"
        }
      ]
    },
    {
      id: "tarot-reverse-pentacles",
      prereq: null,
      lessons: [
        {
          id: "tarot-reverse-pentacles_1",
          type: "single",
          cards: [64,65,66,67,68],
          icon: "lesson-58.png"
        },
        {
          id: "tarot-reverse-pentacles_2",
          type: "single",
          cards: [69,70,71,72,73],
          icon: "lesson-59.png"
        },
        {
          id: "tarot-reverse-pentacles_3",
          type: "single",
          cards: [64,65,66,67,68,69,70,71,72,73],
          icon: "lesson-60.png"
        }
      ]
    },
    {
      id: "tarot-reverse-court",
      prereq: null,
      lessons: [
        {
          id: "tarot-reverse-court_1",
          type: "single",
          cards: [32,46,60,74],
          icon: "lesson-61.png"
        },
        {
          id: "tarot-reverse-court_2",
          type: "single",
          cards: [33,47,61,75],
          icon: "lesson-62.png"
        },
        {
          id: "tarot-reverse-court_3",
          type: "single",
          cards: [34,48,62,76],
          icon: "lesson-63.png"
        },
        {
          id: "tarot-reverse-court_4",
          type: "single",
          cards: [35,49,63,77],
          icon: "lesson-64.png"
        },
        {
          id: "tarot-reverse-court_5",
          type: "single",
          cards: [32,46,60,74,33,47,61,75,34,48,62,76,35,49,63,77],
          icon: "lesson-65.png"
        }
      ]
    },
    {
      id: "tarot-reverse-all",
      prereq: null,
      article: "tarot-traditions",
      memory: "keys",
      lessons: [
        {
          id: "tarot-reverse-all",
          type: "single",
          cards: Array.apply(null, {length: 78}).map(Number.call, Number),
          icon: "lesson-66.png"
        }
      ]
    },
    {
      id: "lenormand",
      prereq: null,
      article: "lenormand",
      lessons: [
        {
          id: "lenormand_1",
          type: "single",
          cards: [0,1,2,3,4,5],
          icon: "lesson-24.png"
        },
        {
          id: "lenormand_2",
          type: "single",
          cards: [6,7,8,9,10,11],
          icon: "lesson-25.png"
        },
        {
          id: "lenormand_3",
          type: "single",
          cards: [12,13,14,15,16,17],
          icon: "lesson-26.png"
        },
        {
          id: "lenormand_4",
          type: "single",
          cards: [18,19,20,21,22,23],
          icon: "lesson-27.png"
        },
        {
          id: "lenormand_5",
          type: "single",
          cards: [24,25,26,27,28,29],
          icon: "lesson-28.png"
        },
        {
          id: "lenormand_6",
          type: "single",
          cards: [30,31,32,33,34,35],
          icon: "lesson-29.png"
        },
        {
          id: "lenormand_7",
          type: "single",
          cards: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35],
          icon: "lesson-23.png"
        }
      ]
    },
    {
      id: "lenormand-lines",
      article: "lenormand-lines"
    },
    {
      id: "astro-moon",
      prereq: null,
      article: "astro",
      lessons: [
        {
          id: "astro-moon_1",
          type: "single",
          cards: [34,35,36,37,38],
          icon: "lesson-30.png"
        },
        {
          id: "astro-moon_2",
          type: "single",
          cards: [39,40,41,42,43],
          icon: "lesson-31.png"
        },
        {
          id: "astro-moon_3",
          type: "single",
          cards: [34,35,36,37,38,39,40,41,42,43],
          icon: "lesson-33.png"
        }
      ]
    },
    {
      id: "astro-zodiac",
      prereq: null,
      lessons: [
        {
          id: "astro-zodiac_1",
          type: "single",
          cards: [0,1,2,3,4,5],
          icon: "lesson-35.png"
        },
        {
          id: "astro-zodiac_2",
          type: "single",
          cards: [6,7,8,9,10,11],
          icon: "lesson-38.png"
        },
        {
          id: "astro-zodiac_3",
          type: "single",
          cards: [0,1,2,3,4,5,6,7,8,9,10,11],
          icon: "lesson-39.png"
        }
      ]
    },
    {
      id: "astro-planets",
      prereq: null,
      lessons: [
        {
          id: "astro-planets_1",
          type: "single",
          cards: [12,13,14,15,16],
          icon: "lesson-36.png"
        },
        {
          id: "astro-planets_2",
          type: "single",
          cards: [17,18,19,20,21],
          icon: "lesson-40.png"
        },
        {
          id: "astro-planets_3",
          type: "single",
          cards: [12,13,14,15,16,17,18,19,20,21],
          icon: "lesson-41.png"
        }
      ]
    },
    {
      id: "astro-houses",
      prereq: null,
      lessons: [
        {
          id: "astro-houses_1",
          type: "single",
          cards: [22,23,24,25,26,27],
          icon: "lesson-37.png"
        },
        {
          id: "astro-houses_2",
          type: "single",
          cards: [28,29,30,31,32,33],
          icon: "lesson-42.png"
        },
        {
          id: "astro-houses_3",
          type: "single",
          cards: [22,23,24,25,26,27,28,29,30,31,32,33],
          icon: "lesson-43.png"
        }
      ]
    },
    {
      id: "tarot-common",
      prereq: null,
      lessons: [
        {
          id: "tarot-common_1",
          type: "common",
          cards: [0,1,2,3],
          icon: "lesson-67.png"
        },
        {
          id: "tarot-common_2",
          type: "common",
          cards: [4,5,6,7],
          icon: "lesson-68.png"
        },
        {
          id: "tarot-common_3",
          type: "common",
          cards: [8,9,10,11],
          icon: "lesson-69.png"
        }
      ]
    },
    {
      id: "tarot-elements",
      prereq: null,
      article: "tarot-elements",
      lessons: [
        {
          id: "tarot-elements_1",
          type: "single",
          concepts: "elements",
          cards: [22,23,24,25,26,27,28,29,30,31,36,37,38,39,40,41,42,43,44,45,50,51,52,53,54,55,56,57,58,59,64,65,66,67,68,69,70,71,72,73,32,33,34,35,46,47,48,49,60,61,62,63,74,75,76,77], // all minor
          icon: "lesson-70.png"
        },
        {
          id: "tarot-elements_2",
          type: "single",
          concepts: "elements",
          cards: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21], // major
          icon: "lesson-71.png"
        }
      ]
    },
    {
      id: "tarot-astro",
      prereq: null,
      article: "tarot-astro",
      lessons: [
        {
          id: "tarot-astro_1",
          type: "single",
          concepts: "astro",
          cards: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21], // major
          icon: "lesson-72.png"
        },
        {
          id: "tarot-astro_2",
          type: "single",
          concepts: "astro",
          cards: [32,33,34,35,46,47,48,49,60,61,62,63,74,75,76,77], // court
          icon: "lesson-73.png"
        },
        {
          id: "tarot-astro_3",
          type: "single",
          concepts: "astro",
          cards: [22,23,24,25,26,27,28,29,30,31], // wands
          icon: "lesson-74.png"
        },
        {
          id: "tarot-astro_4",
          type: "single",
          concepts: "astro",
          cards: [36,37,38,39,40,41,42,43,44,45], // cups
          icon: "lesson-75.png"
        },
        {
          id: "tarot-astro_5",
          type: "single",
          concepts: "astro",
          cards: [50,51,52,53,54,55,56,57,58,59], // swords
          icon: "lesson-76.png"
        },
        {
          id: "tarot-astro_6",
          type: "single",
          concepts: "astro",
          cards: [64,65,66,67,68,69,70,71,72,73], // pentacles
          icon: "lesson-77.png"
        }
      ]
    },
    {
      id: "rune",
      prereq: null,
      article: "rune-intro",
      lessons: [
        {
          id: "rune_1",
          type: "single",
          cards: [0,1,2,3,4,5],
          icon: "lesson-78.png"
        },
        {
          id: "rune_2",
          type: "single",
          cards: [6,7,8,9,10,11],
          icon: "lesson-79.png"
        },
        {
          id: "rune_3",
          type: "single",
          cards: [12,13,14,15,16,17],
          icon: "lesson-80.png"
        },
        {
          id: "rune_4",
          type: "single",
          cards: [18,19,20,21,22,23],
          icon: "lesson-81.png"
        },
        {
          id: "rune_5",
          type: "single",
          cards: Array.apply(null, {length: 24}).map(Number.call, Number),
          icon: "lesson-82.png"
        },
      ]
    },
    {
      id: "rune-reverse",
      prereq: null,
      article: "rune-reverse",
      lessons: [
        {
          id: "rune-reverse_1",
          type: "single",
          cards: [0,1,2,3,4,5],
          icon: "lesson-83.png"
        },
        {
          id: "rune-reverse_2",
          type: "single",
          cards: [7,9,13,14,16,17],
          icon: "lesson-84.png"
        },
        {
          id: "rune-reverse_3",
          type: "single",
          cards: [18,19,20,22],
          icon: "lesson-85.png"
        },
        {
          id: "rune-reverse_4",
          type: "single",
          cards: [0,1,2,3,4,5,7,9,13,14,16,17,18,19,20,22],
          icon: "lesson-86.png"
        },
      ]
    },
  ];

  common: any = [
    { id: "love", cards: [6,37,45,36,47]},
    { id: "travel", cards: [29,24,23,55,7,33]},
    { id: "potential", cards: [0,36,22,50,64]},
    { id: "duality", cards: [6,11,23,37,51,65]},
    { id: "family", cards: [73,45,41,25]},
    { id: "instability", cards: [10,13,16]},
    { id: "conflict", cards: [26,28,54,56,61]},
    { id: "groups", cards: [66,38,25]},
    { id: "trauma", cards: [59,58,16,52]},
    { id: "completion", cards: [13,21,31,45,59,73]},
    { id: "foundation", cards: [4,63,67,77]},
    { id: "growth", cards: [70,3,29,27,71]},
    { id: "pleasure", cards: []},
    { id: "persistence", cards: []},
    { id: "faith", cards: []},
    { id: "cooperation", cards: []},
    { id: "mastery", cards: []},
    { id: "accomplishment", cards: []},
    { id: "business", cards: []},
    { id: "courage", cards: []},
    { id: "healing", cards: []},
    { id: "education", cards: []},
    { id: "intuition", cards: []},
    { id: "delays", cards: []},
    { id: "illness", cards: []},
    { id: "abuse", cards: []},
    { id: "sex", cards: []},
    { id: "guilt", cards: []},
    { id: "planning", cards: []},
    { id: "breakup", cards: []},
    { id: "career", cards: []},
    { id: "insecurity", cards: []},
    { id: "fear", cards: []},
    { id: "morality", cards: []},
    { id: "gratitude", cards: []},
    { id: "celebration", cards: []}
  ];

  lessonFormat: any = [
    {
      type: "chooseKeyword",
      question: {}, // needs card data
      options: [] // needs random selection of 3 keywords
    },
    {
      type: "chooseCard",
      question: null, // needs random keyword
      options: [] // needs random 3 cards
    }
  ];

  lessonFormatElement: any = [
    {
      type: "chooseKeyword", // given a card, choose its element
      question: {}, // needs card data
      options: [] // only options
    },
    {
      type: "chooseCard", // given an element, choose a card
      question: null, // needs random element
      options: [] // needs cards that are not right element
    },
    {
      type: "matchCard", // given 3 cards of same element, choose another card of that element
      question: null, // needs 3 cards of same element
      options: [] // needs random 3 cards
    }
  ];

  lessonFormatCommon: any = [
    {
      type: "chooseKeyword",
      question: [{}, {}], // needs 2 cards
      options: [] // needs random selection of 3 keywords
    }
  ];

  getClasses() {
    return this.classes;
  }

  filterClasses(include, exclude) {
    return this.classes.filter((lesson) => {
      if (include) {
        return (lesson.id.toLowerCase().indexOf(include) > -1) && (lesson.id.toLowerCase().indexOf(exclude) == -1);
      } else {
        return lesson.id.toLowerCase().indexOf(exclude) == -1;
      }
    });
  }

  getLessonIds() {
    var ids = [];
    for (let classroom of this.classes) {
      if (classroom.lessons) {
        for (let lesson of classroom.lessons) {
          ids.push(lesson.id);
        }
      }
    }
    return ids;
  }

  filterLessonType(type) {
    var withTypes = this.classes.filter((classroom) => {
      return classroom[type]
    });

    var filtered = withTypes.map((classroom) => {
      return {
        id: classroom.id,
        [type]: classroom[type]
      };
    });

    return filtered;
  }

  getLesson(lessonId) {
    var classId = lessonId.split('_')[0];
    var lessonClass = this.classes.filter((item) => {
      return item.id == classId;
    })[0];

    return lessonClass.lessons.filter((lesson) => {
      return lesson.id == lessonId;
    })[0];
  }

  getConcept(index) {
    return this.common[index];
  }

  getRandom(array) {
    var item = this[array][Math.floor(Math.random()*this[array].length)];
    return item;
  }

  convertSortedScoresToIndexes(scores: Array<any>, subj: string) {
    var indexes = scores.map((card) => {
      return this[subj + 'Service'].getCardIndex(card[0])
    })
    return indexes;
  }

  customLesson(subj: 'tarot' | 'lenormand' | 'astro' | 'rune', cards: Array<number>) {
    var custom = {
      id: subj + "_custom",
      type: "single",
      cards: cards
    };
    return custom;
  }

  sortScores(score: any, subj: string) {
    // returns scores sorted from worst to best.
    var percents = [];
    Object.keys(score[subj]).forEach((card) => {
      var obj = [card, score[subj][card][1] / score[subj][card][0]];
      percents.push(obj);
    });
    percents.sort(function(a, b) {
        return a[1] - b[1];
    });
    return percents;
  }
}
