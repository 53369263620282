import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PurchaseService } from '../../services/purchase.service';
import { AnalyticsService } from '../../services/analytics.service';
import { Storage } from '@ionic/storage';
import { Capacitor } from '@capacitor/core';
import { JournalShopPage } from '../../modals/journal-shop/journal-shop.page';
import { CatssandraPage } from '../../modals/catssandra/catssandra.page';

@Component({
  selector: 'app-deal',
  templateUrl: './deal.page.html',
  styleUrls: ['./deal.page.scss'],
})
export class DealPage implements OnInit, OnDestroy {

  constructor(
    private modalController: ModalController,
    private purchaseService: PurchaseService,
    private analyticsService: AnalyticsService,
    private storage: Storage,
  ) { }

  offerings: any;
  expiration: any;
  interval: any;
  countdown: any = {
    dd: 0,
    hh: 0,
    mm: 0,
    ss: 0
  }

  async dismiss() {
    let modal = await this.modalController.getTop();
    modal.dismiss();
  }

  async openJournalShop(neededEntries?: number, currentEntries?: number) {
    var props = {
        neededEntries: neededEntries,
        currentEntries: currentEntries
    }
    const modal = await this.modalController.create({
      component: JournalShopPage,
      componentProps: props,
      id: 'journal'
    });
    await modal.present();
  }

  async openCreditsInfo() {
    const modal = await this.modalController.create({
      component: CatssandraPage,
      id: 'marketing',
      componentProps: {
        showFooter: false,
        showReading: false,
        cssClass: 'full-web'
      }
    });
    return await modal.present();
  }

  async purchase(sku: any) {
    await this.purchaseService.purchase(sku);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  ngOnInit() {
    this.purchaseService.checkDealEligibility().then(() => {
      this.countdown = this.purchaseService.setCountdown();
      this.interval = setInterval(() => {
        this.countdown = this.purchaseService.setCountdown();
      }, 1000);
    });

    this.purchaseService.rcPermissions.subscribe(permissions => {
      if (permissions.journals.indexOf('journal_265') > -1) {
        this.dismiss();
      }
    });

    if (Capacitor.getPlatform() == 'ios' || Capacitor.getPlatform() == 'android') {
      this.purchaseService.getOfferings().subscribe((res) => {
        console.log(res);
        this.offerings = res['specials'].availablePackages;
      });
    }
  }
}
