import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Device } from '@capacitor/device';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  analyticsEnabled = true;

  constructor(
    private router: Router
  ) {
    this.initFb();
    this.router.events.pipe(
      filter((e: RouterEvent) => e instanceof NavigationEnd),
    ).subscribe((e: RouterEvent) => {
      console.info('route changed: ', e.url);
      this.setScreenName(e.url)
    });
  }

  async initFb() {
    var platform = await Device.getInfo();
    // only run firebase init if on web platform. Not iOS or android.
    if (platform && platform.platform == "web") {
      console.log('FirebaseAnalytics should be initializing...');
      FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    }
  }

  async setUser() {
    var id = await Device.getId();
    FirebaseAnalytics.setUserId({
      userId: id.identifier,
    });
  }

  logEvent(name: string, params?: any) {
    // params are always a key value pair like so:
    // {
    //   method: "email"
    // }
    FirebaseAnalytics.logEvent({
      name: name,
      params: params
    });
  }

  setProperty(property) {
    // keep it a key value pair like so:
    // {
    //   name: "framework",
    //   value: "angular",
    // }
    FirebaseAnalytics.setUserProperty(property);
  }

  setScreenName(screenName) {
    FirebaseAnalytics.setScreenName({
      screenName
    });
  }

}
