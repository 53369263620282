import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';

const LNG_KEY = 'SELECTED_LANGUAGE';

@Injectable({
  providedIn: 'root'
})

export class LanguageService {
  selected: string;
  languages = [
    { text: 'English', value: 'en', styles: 'standard' },
    { text: 'Español', value: 'es', styles: 'standard', poll: 'https://docs.google.com/forms/d/e/1FAIpQLScSkoto99KE2kdokaPHPIURW8spmfqZAtUqqbGfwlZnLoTetQ/viewform' },
    { text: 'Italiano', value: 'it', styles: 'standard', poll: 'https://docs.google.com/forms/d/e/1FAIpQLSc9p2sqQm3wcx39pB3noJuJ3_GemekanJjo4-6DgzrGoRYPLw/viewform' },
    { text: 'Português', value: 'pt', styles: 'standard', poll: 'https://docs.google.com/forms/d/e/1FAIpQLSdJIwY9J_UDIFpi5m2W21hBUan5EXBUYlPD9s80irFJ3Q7Mrw/viewform' },
    { text: 'Deutsch', value: 'de', styles: 'standard', poll: 'https://docs.google.com/forms/d/e/1FAIpQLScfK2uzduBeCxKJlK2PcO75osV8b450KRMFON39vCZ29hyJ7g/viewform' },
    { text: 'Français', value: 'fr', styles: 'standard', poll: 'https://docs.google.com/forms/d/e/1FAIpQLScdQWBbXMmMsF13CV4xseZeqxetEUBDG5vLws3LJd_l7rlMeQ/viewform' },
    { text: '한국어', value: 'ko', styles: 'plain', poll: 'https://docs.google.com/forms/d/e/1FAIpQLSdvuYeOt80xftPSSOMHQxMNEOOP2PjKp8uDY36rvsq9sEL8rw/viewform' },
    { text: 'Pусский', value: 'ru', styles: 'plain', poll: 'https://docs.google.com/forms/d/e/1FAIpQLSctOUOiqwio7iMSp1h-yEWfDy6JlSNyLJytwupwMUYHQnaHpw/viewform' },
    { text: '中文', value: 'zh', styles: 'plain', poll: 'https://docs.google.com/forms/d/e/1FAIpQLSdLeivLmwC2MuYl-1t4yWDpk_uCp-DEYprUZdZfAtvXlo7jow/viewform' },
    { text: '日本語', value: 'ja', styles: 'plain', poll: 'https://docs.google.com/forms/d/e/1FAIpQLSf3u5LWPSf5ZAzv5A3wcayWXecjpSaqgNQ-bSs6BkzV_uqpLA/viewform' },
  ];

  styles: any = [
    {
      name: 'standard',
      styles: [
        { themeVariable: '--ion-header-font', value: 'Eksell'},
        { themeVariable: '--ion-button-font', value: 'Brandon'},
      ]
    },
    {
      name: 'plain',
      styles: [
        { themeVariable: '--ion-header-font', value: 'Roboto, Helvetica, Sans-Serif'},
        { themeVariable: '--ion-button-font', value: 'Roboto, Helvetica, Sans-Serif'},
      ]
    }
  ]

  constructor (
    private translate: TranslateService,
    private storage: Storage,
    private plt: Platform,
    private domCtrl: DomController,
    @Inject(DOCUMENT) private document
  ) { }

  languageSubscription = new Subject<any>();

  getSubscription(name) {
    return this[name];
  }

  publishSubscription(name, data) {
    this[name].next(data);
  }

  async setInitialAppLanguage() {
    let language: string;

    // check if language key is already set. Keep it or otherwise, set it as the default browswer language.
    await this.storage.get(LNG_KEY).then(val => {
      if (val) {
        language = val;
      } else {
        language = this.translate.getBrowserLang();
      }
    });

    // if this language exists in available languages list use that as the default language, other wise, use english.
    console.log("browser language is: ", this.translate.getBrowserLang());
    console.log("user's default language is: ", language);

    if (language && this.filterLangs(language).length > 0) {
      this.translate.setDefaultLang(language);
      this.setLanguage(language);
    } else {
      this.translate.setDefaultLang('en');
      this.setLanguage('en');
    }
  }

  getLanguages() {
    return this.languages;
  }

  getLanguage(value) {
    return this.languages.filter((language) => {
      return language.value == value;
    })[0];
  }

  setLanguage(lng) {
    console.log('setting the language to: ', lng);
    this.translate.use(lng);
    this.selected = lng;
    this.storage.set(LNG_KEY, lng);
    this.setStyle(this.filterLangs(lng)[0].styles);
    this.publishSubscription('languageSubscription', lng);
  }

  filterLangs(searchText) {
    return this.languages.filter((lang) => {
      return lang.value.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    });
  }

  setStyle(name) {
    let theme = this.styles.find(theme => theme.name === name);
    this.domCtrl.write(() => {
      theme.styles.forEach(style => {
        document.documentElement.style.setProperty(style.themeVariable, style.value);
      });
    });
  }
}
