<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{'purchases.journal.title' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-text-center" color="primary">
  <ion-grid class="statistics message ion-margin-horizontal">
    <ion-row>
      <ion-col *ngIf="currentEntries">
        <h1>{{ currentEntries }}</h1>
        <span class="capitals">{{'purchases.journal.existing' | translate}}</span>
      </ion-col>
      <ion-col>
        <h1>{{maxEntries}}</h1>
        <span class="capitals">{{'purchases.journal.max' | translate}}</span>
      </ion-col>
      <ion-col *ngIf="neededEntries">
        <h1>{{neededEntries}}</h1>
        <span class="capitals">{{'purchases.journal.missing' | translate}}</span>
      </ion-col>
    </ion-row>
  </ion-grid>
  <img src="../assets/offerings/{{getBookImage()}}" class="animated float infinite"/>
  <div class="ion-padding">
    <h1>{{'purchases.journal.subtitle' | translate}}</h1>
    <p>{{'purchases.journal.message' | translate}}</p>
  </div>

  <app-buy-section type="journals" [cssClass]="'bg-light-gradient ion-padding-top'" [permissions]="user?.permissions"></app-buy-section>

  <h1>FAQ</h1>
  <ion-accordion-group>
    <ion-accordion *ngFor="let qa of 'purchases.journal.faq' | translate; index as i" [value]="i">
      <ion-item slot="header" color="primary">
        <ion-label text-wrap>{{qa.q}}</ion-label>
      </ion-item>
      <div class="ion-padding ion-text-left" slot="content" [innerHTML]="qa.a">
      </div>
    </ion-accordion>
  </ion-accordion-group>

  <app-buy-section class="block ion-padding ion-text-left" type="specials" *ngIf="user?.email" [horizontal]="true" [permissions]="user?.permissions"></app-buy-section>
</ion-content>
