<div class="stone" ngClass="{{stone.texture}} {{runeData}}" (click)="clickCard()" style="
  --a: {{stone.styles.a}};
  --px: {{stone.styles.px}};
  --py: {{stone.styles.py}};
  --h: {{stone.styles.h}};
  --r: {{stone.styles.r}};
  --ta: {{stone.styles.ta}};
  --s: {{stone.styles.s}};
  --l: {{stone.styles.l}};
  --effect: {{stone.effect}};
  --glyphStyle: {{stone.glyphColor}};
  --glyphSaturation: {{stone.glyphSaturation}};
  --shadow: {{shadow}}
  ">
  <div class="glyph-rune" [ngClass]="stone.glyph">
    <div [ngClass]="{'reversed': reversed}">
      {{runeData}}
      <span>{{runeData}}</span>
    </div>
  </div>
</div>
