import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DownloadService } from '../../services/download.service';
import { ProductService } from '../../services/product.service';
import { Capacitor } from "@capacitor/core";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  constructor(
    public downloadService: DownloadService,
    public productService: ProductService,
    private sanitizer: DomSanitizer
  ) { }

  @Input() reversed: boolean;
  @Input() local: boolean;
  @Output() cardClicked = new EventEmitter<{ cardId: string }>();
  @Input()
  get cardId(): string {
    return this._cardId;
  }
  set cardId(value) {
    if (this.cardId != value) {
      this._cardId = value;
      this.downloadService.getFile(this.userDeck, value).then( data => {
        this.file = data;
        this.ngOnInit();
      });
    }
  }
  _cardId: string;
  @Input()
  get userDeck(): string {
    return this._userDeck;
  }
  set userDeck(value) {
    if (this.userDeck != value) {
      this._userDeck = value;
      this.ngOnInit();
    }
  }
  _userDeck: string;
  file: any;
  category: string;
  product: any;
  src: string;
  path: string;
  webURL: string = 'https://milkmusket.net/linked_content/labyrinthos/downloads/decks/';

  getPath(cardId: string) {
    // files is the files object that is in storage.
    // inside that, all paths will be setup to be key (cardId): path
    // return Capacitor.convertFileSrc(this.files[cardId]);
    // if premium deck, I don't have the license for web, show default deck instead
    var web = Capacitor.getPlatform() == 'web';
    var webDeck = this.product?.web;
    var labyDeck = this.product?.publisher == 'labyrinthos';
    var localImages = this.local === true;

    if (web && !webDeck) {
      return '../assets/decks/SSTRWS/' + this.cardId;
    } else if (localImages || labyDeck) {
      return '../assets/decks/' + this.userDeck + '/' + this.cardId;
    } else if (web && webDeck) {
      return this.webURL + this.userDeck + '/' + this.cardId;
    } else {
      if (Capacitor.getPlatform() == 'ios' || Capacitor.getPlatform() == 'android') {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.file);
      } else {
        return this.file;
      }
    }
  }

  clickCard() {
    this.cardClicked.emit({ cardId: this.cardId });
  }

  ngOnInit() {
    this.category = this.productService.getProductType(this.userDeck);
    this.product = this.productService.getDeck(this.category, this.userDeck);
    // if this is a download deck, bring download urls here, and show them instead
    if (!this.product.publisher || this.product.publisher != 'labyrinthos') {
      this.downloadService.getFile(this.userDeck, this.cardId).then(data => {
        this.file = data;
      });
    }
    this.path = this.getPath(this.cardId);
  }
}
