import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { LogService } from './log.service';
import { UserService } from './user.service';
import { ReportService } from './report.service';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from './analytics.service';
import { ToastController, AlertController, ActionSheetController, ModalController } from '@ionic/angular';
import { FileOpener, FileOpenerOptions } from '@capacitor-community/file-opener';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Subscription, Subject } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { User } from '../user';
import { environment } from '../../environments/environment';
import { JournalShopPage } from '../modals/journal-shop/journal-shop.page';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  constructor(
    private storage: Storage,
    private userService: UserService,
    private logService: LogService,
    private reportService: ReportService,
    private toastController: ToastController,
    private alertController: AlertController,
    private modalController: ModalController,
    private actionSheetCtrl: ActionSheetController,
    private analyticsService: AnalyticsService,
    private translate: TranslateService,
  ) {
    this.init();
  }

  maxFreeLogs: number = environment.maxFreeLogs;
  maxFreeSpreads: number = environment.maxFreeSpreads;
  backgroundProcess = new Subject<any>();
  premiumModal = new Subject<any>();
  faqModal = new Subject<any>();
  user: User;
  userUpdates: Subscription;

  getSubscription(name) {
    return this[name];
  }

  publishSubscription(name, data) {
    this[name].next(data);
  }

  async presentToast(message: string, color: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      duration: 5000,
      color: color,
      buttons: [
        {
          text: 'OK'
        }
      ]
    });
    toast.present();
  }

  async openJournalShop(neededEntries: number, currentEntries: number) {
    var props = {
        neededEntries: neededEntries,
        currentEntries: currentEntries
    }
    const modal = await this.modalController.create({
      component: JournalShopPage,
      componentProps: props,
      id: 'journal'
    });
    await modal.present();
  }

  // for existing users that do not see the signup screen
  checkDealEligibility() {
    this.storage.get('deal_expiration').then(data => {
      console.log('current expiration: ', data);
      if (!data) {
        const today = new Date();
        const expiration = today.setHours(today.getHours() + 72);
        this.storage.set('deal_expiration', expiration);
        console.log('set deal expiration date to 3 days later: ', expiration);
      }
    })
  }

  async presentAlert(type: 'logs' | 'spreads') {
    this.checkDealEligibility();
    var currentLogs: number;
    var toDeleteCount: number;
    var itemName: string;
    var maxFree = 'maxFree' + type.charAt(0).toUpperCase() + type.slice(1);
    await this.storage.get(type).then(data => {
      currentLogs = data.length;
      toDeleteCount = currentLogs - this[maxFree] + 1;
    });

    var buttons: Array<any> = [
      {
        text: this.translate.instant('_common.upgrade_premium'),
        role: 'confirm',
        handler: () => {
          this.publishSubscription('premiumModal', true);
        },
      }
    ];

    if (type == 'logs') {
      itemName = this.translate.instant('log_limits.alert.item_journal');
      var upgrade = {
        text: '✨ Upgrade Journal & FAQ ✨',
        role: 'confirm',
        cssClass: 'ion-text-center',
        handler: () => {
          this.openJournalShop(toDeleteCount, currentLogs);
        }
      };

      var clear = {
        text: this.translate.instant('log_limits.alert.button_export', {count: toDeleteCount}),
        role: 'cancel',
        cssClass: 'ion-text-center',
        handler: () => {
          this.presentActionSheet(true);
        }
      };
      buttons.splice(0, 0, upgrade);
      buttons.push(clear);
    };

    if (type == 'spreads') {
      itemName = this.translate.instant('log_limits.alert.item_spreads');
    };

    const alert = await this.alertController.create({
      header: this.translate.instant('log_limits.alert.title'),
      message: this.translate.instant('log_limits.alert.message', {max: this[maxFree], current: currentLogs}),
      mode: 'md',
      cssClass: 'illustration confirm',
      buttons: buttons
    });
    await alert.present();

    this.analyticsService.logEvent('paywall', {
      content_id: 'journal_entries'
    });
  }

  async presentExportComplete() {
    var message: string;

    if (Capacitor.getPlatform() == 'ios') {
      message = `To find them, open the Files app on your device. Go to "On My iPhone", and navigate to the Labyrinthos folder. All exported entries should be there.<img class="ion-margin-top" src="../assets/files-ios.png"/>`;
    }

    if (Capacitor.getPlatform() == 'android') {
      message = `To find them, open the My Files app on your device and navigate to the Labyrinthos folder. Your PDF's will be there.`;
    }

    const alert = await this.alertController.create({
      header: `All Tarot Readings Exported`,
      message: message,
      mode: 'md',
      cssClass: 'illustration confirm',
      buttons: [
        {
          text: 'OK',
          role: 'confirm'
        }
      ]
    });

    await alert.present();
  }



  async presentActionSheet(showDelete: boolean, ids?: Array<string>) {
    // only needed for 'logs' type, and not 'spread' type right now.
    var currentLogs: number;
    var toDeleteCount: number;
    var title: string;

    if (!ids) {
      // auto calculate logs
      await this.storage.get('logs').then(data => {
        currentLogs = data.length;
        toDeleteCount = currentLogs - this.maxFreeLogs + 1;
      });
      title = this.translate.instant('log_limits.alert.button_export', {count: toDeleteCount});
      ids = await this.logService.getEarliestNLogs(toDeleteCount);
    } else {
      // when there is an id set, they're the only ones being exported.
      toDeleteCount = ids.length;
      title = this.translate.instant('log_limits.sheet.title_export_only', {count: toDeleteCount})
    }

    var options = [
      {
        text: this.translate.instant('log_limits.sheet.plain'),
        icon: 'document-text-outline',
        handler: () => {
          if (!ids) {
            this.exportPlainText();
          } else {
            this.exportPlainText(ids);
          }
          return false;
        }
      },
      {
        text: this.translate.instant('log_limits.sheet.encrypted'),
        icon: 'document-lock-outline',
        handler: () => {
          if (!ids) {
            this.exportLogs();
          } else {
            this.exportLogs(ids);
          }
          return false;
        }
      },
      {
        text: 'Export as PDF',
        icon: 'documents-outline',
        cssClass: 'disabled-visually',
        handler: () => {
          this.presentToast(`Generating multiple PDFs is a taxing process. A maximum of 10 can be created at a time.`, 'danger');
        }
      },
      {
        text: 'Export as PDF',
        icon: 'documents-outline',
        handler: () => {
          actionSheet.dismiss();
          const requests = [];
          this.publishSubscription('backgroundProcess', true);
          this.presentToast(`Your PDF files are being processed and saved in the background. When completed, you'll get an alert.`, 'success');
          for (let id of ids) {
            requests.push(
              new Promise((resolve, reject) => {
                this.logService.getLog('logs', id).then((logData) => {
                  if (logData.type != 'rune' && logData.type != 'lenormand' && logData.type != 'astro') {
                    var report;
                    if (logData.allReports && logData.allReports[0]) {
                      report = logData.allReports[0];
                    } else {
                      report = {};
                    };

                    logData.cards.forEach(async card => {
                      await this.reportService.generateBase64(logData.deck, card.card_id, card.reversed);
                    });

                    setTimeout(() => {
                      console.log(this.user);
                      this.reportService.download(report, logData, this.user, 'standard', true);
                      resolve(true);
                    }, 1500 * logData.cards.length);
                  } else {
                    console.log('Log is not a tarot reading, skip: ', logData.id);
                    resolve(true);
                  }
                })
              })
            );
          }
          // check if all promises have resolved in array
          Promise.all(requests).then((values) => {
            console.log('Checking whether all logs have been dealt with...', values);
            if (values.every(v => v === true)) {
              this.presentExportComplete();
              this.publishSubscription('backgroundProcess', false);
            }
          });
        }
      },
      {
        text: this.translate.instant('log_limits.sheet.delete'),
        icon: 'trash-outline',
        cssClass: 'danger',
        handler: () => {
          this.logService.getEarliestNLogs(toDeleteCount).then((data) => {
            const toDelete = data;
            this.logService.deleteLogs('logs', toDelete);
            this.presentToast(this.translate.instant('log_limits.toasts.delete_success', {count: toDeleteCount}), 'success');
          });
          actionSheet.dismiss();
          return false;
        }
      },
      {
        text: this.translate.instant('_common.cancel')
      }
    ];

    // buttons are different based on a few things
    // whether delete button exists, show only in paywall.
    if (!showDelete) {
      options.splice(4, 1);
    };
    // whether pdf option is available or not.
    if (!ids || ids.length > 10) {
      options.splice(3, 1);
    } else {
      options.splice(2, 1);
    };

    const actionSheet = await this.actionSheetCtrl.create({
      header: title,
      subHeader: this.translate.instant('log_limits.sheet.message'),
      mode: 'md',
      buttons: options
    });

    await actionSheet.present();
  }

  async exportPlainText(ids?: Array<string>) {
    Filesystem.requestPermissions();

    var logs: Array<any>

    if (!ids) {
      // all logs if no array of ids
      logs = await this.logService.getLogs('logs');
    } else {
      // only logs in array
      logs = ids.map((id) => {
        return this.logService.getLog('logs', id)
      });
      await Promise.all(logs).then((data) => {
        logs = data;
      });
    };

    var plaintext = this.logService.parseLogs(logs);

    try {
      let path = `exports/labyrinthos_logs_plaintext_${new Date().getTime()}.txt`;
      const result = await Filesystem.writeFile({
        path: path,
        data: plaintext,
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
        recursive: true
      });
      const file: FileOpenerOptions = {
        filePath: result.uri,
        contentType: 'text/plain',
        openWithDefault: true,
      };
      await FileOpener.open(file);
      // this.file.open(`${result.uri}`, 'text/plain');
    } catch (e) {
      this.presentToast(this.translate.instant('log_limits.toasts.export_fail', {error: e}), 'danger');
    }
  }

  async exportLogs(ids?: Array<string>) {
    Filesystem.requestPermissions();
    var logs: Array<any>

    if (!ids) {
      // all logs if no array of ids
      logs = await this.logService.getLogs('logs');
    } else {
      // only logs in array
      logs = ids.map((id) => {
        return this.logService.getLog('logs', id)
      });
      await Promise.all(logs).then((data) => {
        logs = data;
      });
    };

    var data = this.encrypt(logs);

    try {
      let path = `exports/labyrinthos_logs_${new Date().getTime()}.txt`;
      const result = await Filesystem.writeFile({
        path: path,
        data: data,
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
        recursive: true
      });
      const file: FileOpenerOptions = {
        filePath: result.uri,
        contentType: 'text/plain',
        openWithDefault: true,
      };
      await FileOpener.open(file);
      // this.file.open(`${result.uri}`, 'text/plain');
    } catch (e) {
      this.presentToast(this.translate.instant('log_limits.toasts.export_fail', {error: e}), 'danger');
    }
  }

  encrypt(jsonData: any) {
    return btoa(unescape(encodeURIComponent(JSON.stringify(jsonData))));
  }

  decrypt(jsonData: any) {
    try {
      return JSON.parse(decodeURIComponent(escape(window.atob(jsonData))));
    } catch(e) {
      this.presentToast(this.translate.instant('log_limits.toasts.filetype_error'), 'danger');
    }
  }

  init() {
    this.userService.getUser().then(data => {
      this.user = data;
    });

    this.userUpdates = this.userService.getSubscription('userSubscription').subscribe((data) => {
      this.user = data;
    });
  }
}
