<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="presentPopover()">
        <ion-icon slot="icon-only" name="help-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center">
      {{ 'get_reading.select_modal' | translate }}<br>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="search-wrapper">
    <ion-searchbar [placeholder]="'get_reading.filter' | translate" [(ngModel)]="searchText" (ionChange)="setFilteredItems()"></ion-searchbar>
  </div>

  <ion-list>
    <ion-item *ngIf="spreadType == 'rune'" color="warning">
      <ion-icon name="information-circle-outline" slot="start"></ion-icon>
      <ion-label text-wrap>
        {{'_common.rune_reversals' | translate}}
      </ion-label>
    </ion-item>
    <ion-item *ngFor="let card of cardsInDeck" (click)="selectCard(card.image, false)">
      <ion-label>
        <span class="rune" *ngIf="spreadType == 'rune'">{{ card.image }}: </span>
        {{ translationPath + '.' + card.image + '.title' | translate }}
      </ion-label>
      <ion-button *ngIf="deckInfo.reversal || card.reversed" fill="outline" color="tertiary" (click)="selectCard(card.image, true)">
        {{ '_common.reversed' | translate }}
      </ion-button>
    </ion-item>
  </ion-list>
</ion-content>
