<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'lenormand_pairs.title' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="bg-light">
  <swiper pager="true" class="swiper-light-pagination" [config]="slideOpts" *ngIf="userDeck && cardsInSpread.length > 1" (slideChange)="changeSlide($event)" [initialSlide]="index">
    <ng-template swiperSlide *ngFor="let card of sectionCards; index as i">
      <div class="card" (click)="openCard(spread, currentView + i, userDeck)">
        <img src="./assets/decks/{{userDeck}}/{{card.card_id}}" class="card">
      </div>
    </ng-template>
  </swiper>

  <div class="interpretations ion-padding ion-text-center bg-light-gradient animated fadeIn">
    <h5>{{ 'lenormand_pairs.subtitle' | translate }}</h5>
    <small>{{ 'lenormand_pairs.description' | translate }}</small>
    <div class="keywords" *ngIf="interpretations.length > 0">
      <ion-badge color="tertiary" *ngFor="let keyword of interpretations">{{keyword}}</ion-badge>
    </div>
  </div>
</ion-content>
