import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SignupPage } from '../modals/signup/signup.page';
import { DealPage } from '../modals/deal/deal.page';
import { AiContactsPage } from '../modals/ai-contacts/ai-contacts.page';
import { CallPage } from '../modals/call/call.page';
import { CallCompletePage } from '../modals/call-complete/call-complete.page';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  // A reminder that this is for SIMPLE modals only, ones that do not need any onDidDismiss things.
  // only done to avoid circular dependencies.
  constructor(
    private toastController: ToastController,
    private modalController: ModalController
  ) { }

  needsAccount(type: 'rune' | 'credits') {
    this.openSignup();
    var messages = {
      rune: `Rune decks are a special kind of item that is associated with individual accounts. Please signup or login to continue.`,
      credits: `Credits are kept track of on our servers. Please signup or login to continue.`
    }
    this.presentToast(messages[type], 'danger');
  }

  async presentToast(message: string, color: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      duration: 5000,
      color: color
    });
    toast.present();
  }

  async openSignup() {
    const modal = await this.modalController.create({
      component: SignupPage,
      id: 'signup'
    });
    return await modal.present();
  };

  async openDeal() {
    const modal = await this.modalController.create({
      component: DealPage,
      id: 'deal'
    });
    return await modal.present();
  };

  async openContacts(spreadType, spread, user) {
    var props = {
      spread: spread,
      spreadType: spreadType,
      user: user
    };

    const modal = await this.modalController.create({
      component: AiContactsPage,
      componentProps: props,
      id: 'contacts',
    });

    modal.onDidDismiss().then((data) => {
      var reader = data?.data?.reader;
      var prompt = data?.data?.prompt;
      var min = data?.data?.maxMinutes;
      var spread = data?.data?.spread;
      if (reader && prompt && min) {
        this.openCall(reader, prompt, min, spread);
      }
    });
    return await modal.present();
  };

  async openCall(readerId, prompt, maxMinutes, spread) {
    var props = {
      reader: readerId,
      prompt: prompt,
      maxMinutes: maxMinutes,
      spread: spread
    };

    const modal = await this.modalController.create({
      component: CallPage,
      componentProps: props,
      id: `call-${readerId}`,
      cssClass: 'call',
      backdropDismiss: false,
      showBackdrop: false
    });

    modal.onDidDismiss().then((data) => {
      var transcript = data?.data?.transcript;
      var cost = data?.data?.cost;
      var time = data?.data?.time;
      var spread = data?.data?.spread;
      this.openCallComplete(readerId, transcript, cost, time, spread);
    });
    return await modal.present();
  };

  async openCallComplete(readerId, transcript, cost, time, spread) {
    var props = {
      reader: readerId,
      transcript: transcript,
      cost: cost,
      time: time,
      spread: spread
    };

    const modal = await this.modalController.create({
      component: CallCompletePage,
      componentProps: props,
      id: 'call-complete',
    });
    return await modal.present();
  };
}
