import { Component, Input } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ProductService } from '../../services/product.service';
import { User } from '../../user';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-default-settings',
  templateUrl: './default-settings.page.html',
  styleUrls: ['./default-settings.page.scss'],
})
export class DefaultSettingsPage {
  constructor(
    private productService: ProductService,
    private userService: UserService,
    private modalController: ModalController,
  ) { }

  @Input() user: User;
  @Input() spreadType: string;

  // TODO FIX IN FUTURE
  async dismiss() {
    let modal = await this.modalController.getTop();
    modal.dismiss();
  }

  async saveSettings() {
    this.userService.setUser(this.user);
    const modal = await this.modalController.getTop();
    modal.dismiss({
      user: this.user
    });
  }

  onSettingsChanged(ev) {
    // settings should be copied to this view
    this.user.reversals = ev.settingsChanged.reversals;
    this.user.autoSave = ev.settingsChanged.autoSave;
    this.user.pickCards = ev.settingsChanged.pickCards;
    this.user.shuffle = ev.settingsChanged.shuffle;
    this.user.readingType = ev.settingsChanged.readingType;
    this.user.subset = ev.settingsChanged.subset;
    this.user[this.spreadType + 'Deck'] = ev.settingsChanged.deck;
  }

  ionViewWillEnter() {
    // default is digital type
    if (!this.user.readingType) {
      this.user.readingType = 'digital';
    }
    if (!this.user.dailyType) {
      this.user.dailyType = 'daily';
    }
  }

}
