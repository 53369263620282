import { Component, OnInit, Input } from '@angular/core';
import { PurchaseService } from '../../services/purchase.service';
import { UserService } from '../../services/user.service';
import { LogService } from '../../services/log.service';
import { Subscription } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { AnalyticsService } from '../../services/analytics.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-journal-shop',
  templateUrl: './journal-shop.page.html',
  styleUrls: ['./journal-shop.page.scss'],
})
export class JournalShopPage implements OnInit {
  constructor(
    private purchaseService: PurchaseService,
    private userService: UserService,
    private logService: LogService,
    private modalController: ModalController,
    private analyticsService: AnalyticsService,
  ) { }

  @Input() neededEntries: number;
  @Input() currentEntries: number;
  offerings: any;
  user: any;
  userUpdates: Subscription;
  maxFreeEntries: number = environment.maxFreeLogs;
  maxEntries: number = environment.maxFreeLogs;

  async dismiss() {
    let modal = await this.modalController.dismiss();
  }

  async purchase(sku: any) {
    if (this.user.permissions.avatars.indexOf(sku.skuId) == -1) {
      this.analyticsService.logEvent('start_purchase', {
        content_id: 'avatar',
        sku: sku.skuId,
        source: 'profile'
      });

      await this.purchaseService.purchase(sku);
    } else {
      this.purchaseService.showPurchasedAlert(sku);
    }
  }

  getBookImage() {
    // show the biggest limit bought
    var permissions = this.user?.permissions?.journals;
    if (permissions) {
      if (permissions.indexOf('journal_500') > -1) {
        return 'journal_500.png';
      } else if (permissions.indexOf('journal_300') > -1) {
        return 'journal_300.png';
      } else if (permissions.indexOf('journal_100') > -1) {
        return 'journal_100.png';
      } else {
        return 'journal_base.png';
      }
    } else {
      return 'journal_base.png';
    }
  }

  ngOnDestroy() {
    this.userUpdates.unsubscribe();
  }

  ngOnInit() {
    this.userUpdates = this.userService.getSubscription('userSubscription').subscribe((data) => {
      this.user = data;
      this.maxEntries = this.logService.calcUpgradeMax('logs');
    });

    this.userService.getUser().then(data  => {
      this.user = data;
      this.maxEntries = this.logService.calcUpgradeMax('logs');
    });
  }

}
