import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { LocalNotifications } from '@capacitor/local-notifications';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { yearMoonPhases } from 'meeussunmoon';
import moment from 'moment-timezone';
import { DateTime } from "luxon";
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.page.html',
  styleUrls: ['./notification.page.scss'],
})
export class NotificationPage implements OnInit {
  enabled: boolean = false;
  notifyTime: any;
  notifications: Array<any> = [];
  moonPhaseNotifications: any = {
    new: [],
    full: []
  };
  chosenHours: number = 9;
  chosenMinutes: number = 0;
  days: Array<any> = [
    {title: 'monday', dayCode: 1, checked: false},
    {title: 'tuesday', dayCode: 2, checked: false},
    {title: 'wednesday', dayCode: 3, checked: false},
    {title: 'thursday', dayCode: 4, checked: false},
    {title: 'friday', dayCode: 5, checked: false},
    {title: 'saturday', dayCode: 6, checked: false},
    {title: 'sunday', dayCode: 0, checked: false}
  ];
  month: Array<any> = [
    {title: 'first', checked: false}
  ];
  moon: Array<any> = [
    {title: 'new', checked: false},
    {title: 'full', checked: false}
  ];
  checkedAll: boolean = false;
  pending: Array<any> = [];
  placeholderTime: any = new Date().toLocaleTimeString('en-US', { hour12: true, hour: 'numeric', minute: 'numeric' });

  constructor(
    private modalController: ModalController,
    private toastController: ToastController,
    private platform: Platform,
    private translate: TranslateService,
    private storage: Storage,
    private iab: InAppBrowser
  ) { }

  // Debug Code...
  async debugToast(msg) {
    const props: any = {
      message: msg,
      color: 'secondary',
      position: 'top',
      duration: 2000
    }
    const toast = await this.toastController.create(props);
    toast.present();
  }

  notifyNow() {
    LocalNotifications.requestPermissions();
    LocalNotifications.schedule({
      notifications: [
        {
          id: 1,
          title: "DEBUG " + this.translate.instant('notifications.1.title'),
          body: "DEBUG " + this.translate.instant('notifications.1.content'),
          schedule: { at: new Date(new Date().getTime() + 5000) }
        }
      ]
    });
    console.log(new Date(new Date().getTime() + 5000));
  }
  // End Debug Code...

  async dismiss() {
    const modal = await this.modalController.getTop();
    modal.dismiss();
  }

  async presentToast(status) {
    const props: any = {
      cancelled: {
        message: this.translate.instant('notifications.cancelled'),
        color: 'secondary',
        position: 'top',
        duration: 2000
      },
      set: {
        message: this.translate.instant('notifications.saved'),
        color: 'primary',
        position: 'top',
        duration: 2000
      }
    }
    const toast = await this.toastController.create(props[status]);
    toast.present();
  }

  timeChange(event){
    var date = event.detail.value;
    this.notifyTime = new Date(date).toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute:'2-digit' });
    this.chosenHours = new Date(date).getHours();
    this.chosenMinutes = new Date(date).getMinutes();
  }

  addNotifications(){
    LocalNotifications.requestPermissions();

    // Days of Week Notifications
    let currentDate = new Date();
    let currentDay = currentDate.getDay(); // Sunday = 0, Monday = 1, etc.
    for (let day of this.days) {
      if(day.checked || day == 0){
        let firstNotificationTime = new Date();
        let dayDifference = day.dayCode - currentDay;

        if(dayDifference < 0){
          dayDifference = dayDifference + 7; // for cases where the day is in the following week
        }

        firstNotificationTime.setHours(firstNotificationTime.getHours() + (24 * (dayDifference)));
        firstNotificationTime.setHours(this.chosenHours);
        firstNotificationTime.setMinutes(this.chosenMinutes);

        let notification = {
          id: day.dayCode,
          title: this.translate.instant('notifications.1.title'),
          body: this.translate.instant('notifications.1.content'),
          extra: {type: 'weekday'},
          schedule: {
            on: {
              weekday: firstNotificationTime.getDay(),
              hour: firstNotificationTime.getHours(),
              minute: firstNotificationTime.getMinutes()
            }
          }
        };
        this.notifications.push(notification);
      }
    }

    // Monthly Notifications
    if (this.month[0].checked) {
      let monthly = {
        id: 999,
        title: this.translate.instant('notifications.2.title'),
        body: this.translate.instant('notifications.2.content'),
        extra: {type: 'month'},
        schedule: {
          on: {
            day: 1,
            hour: 7,
            minute: 0
          }
        }
      };
      this.notifications.push(monthly);
    }

    if (this.moon[0].checked) {
      this.notifications = this.notifications.concat(this.moonPhaseNotifications.new);
    };
    if (this.moon[1].checked) {
      this.notifications = this.notifications.concat(this.moonPhaseNotifications.full);
    };

    console.log("Notifications to be scheduled: ", this.notifications);
    this.presentToast('set');

    // When testing on the browser, you can't schedule in the future.
    // They always show up as happening now.
    this.cancelAll(false, false)
    .then((data) => {
      LocalNotifications.schedule({notifications: this.notifications}).then((data) => {
        this.notifications = [];
      });
    });
  }

  cancelAll(indicate:boolean, close: boolean): Promise<any> {
    return LocalNotifications.getPending().then((data) => {
      if (data.notifications.length > 0) {
        LocalNotifications.cancel(data);
      }
      if (indicate) {
        this.presentToast('cancelled');
      }
      if (close) {
        this.dismiss();
      }
    });
  }

  checkChecked() {
    if (this.days.every(day => day.checked == true)) {
      this.checkedAll = true;
    } else {
      this.checkedAll = false;
    }
  }

  selectAll(checked: boolean) {
    if (checked == true) {
      this.days = [
        {title: 'monday', dayCode: 1, checked: true},
        {title: 'tuesday', dayCode: 2, checked: true},
        {title: 'wednesday', dayCode: 3, checked: true},
        {title: 'thursday', dayCode: 4, checked: true},
        {title: 'friday', dayCode: 5, checked: true},
        {title: 'saturday', dayCode: 6, checked: true},
        {title: 'sunday', dayCode: 7, checked: true}
      ];
      this.checkedAll = true;
    } else {
      this.days = [
        {title: 'monday', dayCode: 1, checked: false},
        {title: 'tuesday', dayCode: 2, checked: false},
        {title: 'wednesday', dayCode: 3, checked: false},
        {title: 'thursday', dayCode: 4, checked: false},
        {title: 'friday', dayCode: 5, checked: false},
        {title: 'saturday', dayCode: 6, checked: false},
        {title: 'sunday', dayCode: 7, checked: false}
      ];
      this.checkedAll = false;
    }
  }

  openBrowser(url) {
    const browser = this.iab.create(url, '_system');
  }

  // create one year's worth of moon notifications...
  createMoonNotifications(type: 'newMoon' | 'fullMoon') {
    let offset = moment.tz.guess();

    // 0 is new moon, 2 is full moon;
    var thisYear = new Date().getFullYear();
    var phaseId;

    if (type == 'newMoon') {
      phaseId = 0;
    } else {
      phaseId = 2;
    }

    var moons = yearMoonPhases(thisYear, phaseId, offset);
    var obj = this.generateMoonObj(type, moons);
    return obj;
  }

  generateMoonObj(type: 'newMoon' | 'fullMoon', phaseArray: Array<any>) {
    var moonNotifications = [];
    var translationInteger;
    var startInteger;

    if (type == 'newMoon') {
      translationInteger = 4;
      startInteger = 10;
    } else {
      translationInteger = 3;
      startInteger = 30;
    }

    phaseArray.forEach((phase, i) => {
      if (phase.valueOf() > DateTime.now().valueOf()) {
        var withTime = moment(phase.toString()).set('hour', 19).set('minute', 0);
        let notification = {
          id: startInteger + i,
          title: this.translate.instant('notifications.' + translationInteger + '.title'),
          body: this.translate.instant('notifications.' + translationInteger + '.content'),
          extra: {type: type},
          schedule: {
            at: new Date(withTime.toString())
          }
        };
        // console.log(notification);
        moonNotifications.push(notification);
      }
    })
    return moonNotifications;
  }

  getScheduledNotifications() {
    console.log(this.pending);
    this.pending.forEach((n) => {
      if (n.extra.type == 'weekday') {
        // index in days array is always in order.
        if (n.schedule.on.weekday == 0) {
          var daysObjIndex = 6;
        } else {
          var daysObjIndex = n.schedule.on.weekday - 1;
        }
        this.days[daysObjIndex].checked = true;

        const d = new Date();
        d.setHours(n.schedule.on.hour);
        d.setMinutes(n.schedule.on.minute);
        this.placeholderTime = d.toLocaleTimeString('en-US', { hour12: true, hour: 'numeric', minute: 'numeric' });
        this.notifyTime = d.toLocaleTimeString('en-US', { hour12: true, hour: 'numeric', minute: 'numeric' });

      }
      if (n.extra.type == 'month') {
        this.month[0].checked = true;
      }
    })

    var newMoons = this.pending.filter((n) => {
      return n.extra.type == 'newMoon';
    });
    var fullMoons = this.pending.filter((n) => {
      return n.extra.type == 'fullMoon'
    });

    if (newMoons.length > 0) {
      this.moon[0].checked = true;
    };
    if (fullMoons.length > 0) {
      this.moon[1].checked = true;
    };
  }

  openSettings() {
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App
    });
  }

  ngOnInit() {
    this.chosenHours = new Date().getHours();
    this.chosenMinutes = new Date().getMinutes();
    this.moonPhaseNotifications.new = this.createMoonNotifications('newMoon');
    this.moonPhaseNotifications.full = this.createMoonNotifications('fullMoon');

    // check if notifications enabled and exist.
    LocalNotifications.areEnabled().then((data) => {
      if (data) {
        this.enabled = true;
      }
    });

    LocalNotifications.getPending().then((pending) => {
      this.pending = pending.notifications;
      this.getScheduledNotifications();
    });
  }

}
