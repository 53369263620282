import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ChatService } from '../../services/chat.service';
import { ModalController, ToastController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-ai-contacts',
  templateUrl: './ai-contacts.page.html',
  styleUrls: ['./ai-contacts.page.scss'],
})
export class AiContactsPage implements OnInit {
  constructor(
    private userService: UserService,
    private chatService: ChatService,
    private modalController: ModalController,
    private toastController: ToastController,
  ) { }

  @Input()
  get spread(): any {
    return this._spread;
  }
  set spread(value) {
    // console.log('spread changed: ', value);
    this._spread = value;
  }
  _spread: any;
  @Input() spreadType: string;
  @Input() user: any;
  maxMinutes: number;
  perMonthMinutes: number = environment.premiumMinutes;
  costPerMin: number = environment.callCost;
  reviews$: Observable<any>;

  readers: Array<any> = [
    {
      id: 'catssandra',
      avg: undefined,
      count: 0,
      reviews: []
    },
    {
      id: 'crowley',
      avg: undefined,
      count: 0,
      reviews: []
    },
    {
      id: 'owlcle',
      avg: undefined,
      count: 0,
      reviews: []
    },
    {
      id: 'nostradamouse',
      avg: undefined,
      count: 0,
      reviews: []
    }
  ];

  async dismiss() {
    const modal = await this.modalController.getTop();
    modal.dismiss();
  }

  async startCall(readerId) {
    var prompt = this.chatService.generatePromptForCall(this.spreadType, this.spread, 'en', this.user);

    if (this.maxMinutes > 0) {
      const modal = await this.modalController.getTop();
      modal.dismiss({
        reader: readerId,
        prompt: prompt,
        maxMinutes: this.maxMinutes,
        spread: this.spread
      });
    } else {
      this.presentToast(`You're out of minutes this month. You get another fresh set of minutes on the first of each month.`, 'danger');
    }
  }

  async presentToast(message: string, color: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'top',
      duration: 3000,
      color: color
    });
    toast.present();
  }

  isNumber(value) {
    if (typeof value === 'number' && !Number.isNaN(value)) {
      return true;
    } else {
      return false;
    }
  }

  checkMinutesRemaining() {
    var minutes = this.user?.user_data?.monthlyCalls;
    var premium = this.user?.permissions?.features.includes('premium');
    var thisMonth = new Date().getMonth();
    var thisYear = new Date().getFullYear();

    // phase 1: only premium users have access to this feature
    if (premium) {
      if (minutes && minutes.month == thisMonth && minutes.year == thisYear && this.isNumber(minutes.minutes)) {
        this.maxMinutes = minutes.minutes;
      } else {
        var monthlyCalls = {
          month: thisMonth,
          year: thisYear,
          minutes: environment.premiumMinutes
        };
        this.userService.setUserData('monthlyCalls', monthlyCalls, true);
        this.maxMinutes = monthlyCalls.minutes;
      }
    };
  }

  resetMinutes() {
    var thisMonth = new Date().getMonth();
    var thisYear = new Date().getFullYear();

    var monthlyCalls = {
      month: thisMonth,
      year: thisYear,
      minutes: 20
    };
    this.userService.setUserData('monthlyCalls', monthlyCalls, true);
    this.maxMinutes = monthlyCalls.minutes;
  }

  ionViewWillEnter() {
    if (environment.production) {
      this.checkMinutesRemaining();
    } else {
      this.resetMinutes();
    }
    // this.maxMinutes = Math.round(this.user.credits / this.costPerMin);
  }

  ngOnInit() {
    this.chatService.getReviews('some-id').subscribe((data) => {
      for (let reader of this.readers) {
        reader.reviews = data.filter(r => r.reader.value == reader.id);
        var rating = reader.reviews.map(r => r.rating);
        if (rating.length > 0) {
          reader.avg = rating.reduce((a, b) => a + b) / rating.length;
          reader.count = rating.length;
        }
      }
    });
  }
}
