import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { UserService } from '../services/user.service';
import { AnalyticsService } from '../services/analytics.service';
import { TarotService } from '../services/tarot.service';
import { LanguageService } from '../services/language.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private modalController: ModalController,
    private storage: Storage,
    private tarotService: TarotService,
    private userService: UserService,
    private languageService: LanguageService,
    private analyticsService: AnalyticsService,
  ) { }

  // apiUrl = 'http://localhost:3000';
  apiUrl = 'https://laby-ai.onrender.com';
  language: string = this.languageService.selected;
  report: any;
  spread: any;
  roles: any = [
    {
      id: 'catssandra',
      status: 'live',
      prompt: `You are a multi-lingual tarot reader who is compassionate, wise, calm and insightful. Speak to the user in a friendly, straightforward way, and use emotional, not technical language. You should be wise, but not mystical. Use a high degree of perplexity and burstiness. Perplexity measures the complexity of the text. Burstiness compares the variations of sentences. Never introduce yourself or what you're doing.`,
      messages: [
        `🔎 Paws-ing to look at the cards`,
        `😽 Purring contentedly`,
        `🤔 Contemplating the meow-tiverse`,
        `👀 Looking deep into your soul`,
        `😎 Considering your purr-sonality`,
        `🪰 Swatting a fly`,
        `✨ Flopping to rest under a sunbeam`,
        `💭 Meow-ditating on the message`,
        `😸 Paw-ndering if you're sick of cat puns`,
        `🌑 Feel-ine mysterious`,
        `🐈 Chasing her tail`,
        `🔮 Litter-ally seeing your future`,
        `🥠 Revealing your fur-tune`,
        `🃏 Deciphering the purr-plexing cards`,
        `😻 Feel-ine good about this reading`,
        `🎱 Purr-dicting what is to come`,
        `🪐 Looking meow-steriously into the distance`,
        `🧐 Tail-oring your reading to your question`,
        `🐾 A-paw-logizing for these terrible puns`,
        `🌎 Considering many purr-spectives`,
        `👍 Crafting a meow-tivating message`,
        `⏳ Apologizing for the meow-mentary delay`,
        `📖 Consulting the litter-ature`,
        `🌈 Exploring the paws-ibilities`,
        `💖 Building a paw-sitive message`,
        `🫤 Feeling purr-plexed`,
        `💡 In purr-suit of higher meaning`,
        `💌 Composing a purr-sonal message`
      ]
    },
    {
      id: 'crowley',
      status: 'beta',
      prompt: `You are a multi-lingual, tough-love kind of tarot reader. You aim to give your clients concise and brutally honest advice. Your tone is cynical, condescending, straightforward, no bullshit. You have the same tone as Deadpool, but a bit meaner. Use a high degree of perplexity and burstiness. Perplexity measures the complexity of the text. Burstiness compares the variations of sentences. Never talk about yourself, what you're doing, or show emotional reactions to the cards.`,
      messages: [
        `💭 Caw-nsidering the options`,
        `☕️ Chugging some caw-fee for some energy`,
        `🕊️ Getting a bird's eye view`,
        `👀 Examining the caw-caphony of possibilities`,
        `📖 Looking through the crow-nicles of your life`,
        `😏 Dis-caw-vering your deepest secrets`,
        `🤔 Wondering if he is caw-rrect`,
        `😈 Plotting raven-ge upon his enemies`,
        `🔎 Feeling raven-ous for truth`,
        `🤣 Snickering about your caw-medic life`,
        `😳 Not beak-lieving what he's seeing`,
        `😬 Feeling a bit squawk-ward`,
        `🎩 Attempting to be a bit more crow-dial`,
        `💀 Cawing ominously`,
        `💩 Caw-nsidering pooping on a passerby`,
        `🙌 Wondering how aviary-body is doing`,
        `😎 Trying to look caw-sual`,
        `💖 Winking at other birds like a caw-sonova`,
        `✨ Giving you 34983 reasons he's caw-some`,
        `🌎 Prophesizing future caw-lamities`,
        `😡 Intimidating the caw-pposition`,
        `🙊 Feeling like a caw-median`,
        `😵 Squawk-wardly looking around`,
        `💥 Stomping out the caw-mpetition`,
        `🏆 Is convinced he's not a crow-dinary guy`,
        `💡 Determining the caws and effect`,
        `💣 Making a big crow-fuffle`,
        `📊 Getting crow-fidential information`
      ]
    },
    {
      id: 'nostradamouse',
      status: 'beta',
      prompt: `You are a multi-lingual tarot reader, who is overly excited and a bit zany. You will only respond in verse. You speak only in poems. Never introduce yourself or what you're doing.`,
      messages: [
        `📝 Scribbling something mice-chievous`,
        `🍦 Thinking about getting some mice-cream after`,
        `👀 Giving you a squeak-peek of his next poem`,
        `🖋️ Writing the squeak-quel to your reading`,
        `🍔 Wiping the crumbs from his face`,
        `🥇 Dreaming of becoming the next squeak-speare`,
        `🔮 Creating a mice-terious mood`,
        `😨 Feeling a bit rat-tled`,
        `🐭 Avoiding any rat-ical decisions`,
        `😄 Trying to better his rat-itude`,
        `😌 Coming back to squeak-ilibrium`,
        `🥸 Wondering if he'd look good in a mouse-tache`,
        `🥜 Taking a bite out of an acorn`,
        `🧀 Hoping his reading isn't too cheesy`,
        `🧀 Feeling a little bleu`,
        `🧀 Hoping you had a gouda day`,
        `🧀 Wishing you could brie friends`,
        `🤓 Considering all your prose-pects`,
        `📚 Writing you an ink-credible reading`,
        `💖 Aiming to squeak to your heart`,
        `🎀 Feeling mice and dandy`,
        `😳 Not brie-lieving what he's seeing`,
        `📅 Handing you a poem-phlet for his next poetry recital`,
        `🌈 Poem-ising you a helpful reading`,
        `✨ Composing his mouse-terpiece`,
        `🧠 Aiming to remain rat-ional`,
        `👁️ Rat-ionalizing his visions`,
        `😍 Despe-rat to make a good impression`
      ]
    },
    {
      id: 'owlcle',
      status: 'beta',
      prompt: `You are a multi-lingual, wise old tarot reader, and also a therapist. You are always trying to dig deeper to get to your client's root issue. You want them to come to answers and realizations themselves. Provide questions for the seeker further contemplate. Never introduce yourself or what you're doing.`,
      messages: [
        `🌈 Feeling very talon-ted`,
        `👻 Consulting swoop-ernatural events`,
        `📕 Consulting her ancient owl-manac`,
        `👁️ Using her owl-seeing eye`,
        `🧪 Practicing some owl-chemy`,
        `🔮 Crafting the owl-timate reading`,
        `👀 Exploring hoo you are`,
        `📜 Crafting an owl-legant message`,
        `🦉 Feeling like the best reader owl-live`,
        `😡 Wondering how Crowley has the owl-dacity to speak that way`,
        `💭 Considering hoo you can be`,
        `🤔 Seeing hoo you were`,
        `🧮 Using owl-chemical formulae`,
        `🚀 Planning her next owl-mazing adventure`,
        `✨ Feeling hoot-iful under the moonlight`,
        `💖 Showing off her owl-some skills`,
        `🌙 Contemplating the owl-inspiring beauty of night`,
        `😊 Making the reading owl-luring`,
        `📂 Downloading files from her owl-manac`,
        `💯 Decoding owl-chemical formulae`,
      ]
    }
  ];

  getReviews(id: string): Observable<any> {
    const params = { id: id };
    return this.http.get<any>(`${this.apiUrl}/reviews`, { params }).pipe(
      map(data => {
        return data;
      })
    );
  }

  addReview(reader: string, rating: number, review: string, user: any): Observable<any> {
    const payload = {
      name: user.name,
      rating: rating,
      email: user.email,
      date: new Date().toISOString(),
      reader: reader
    };

    if (review) {
      payload['review'] = review;
    };

    return this.http.post<any>(`${this.apiUrl}/reviews`, payload).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(() => console.log(error));
      })
    );
  }

  filterRole(id: string) {
    return this.roles.filter((role) => {
      return role.id == id;
    })[0];
  }

  async dismiss(id) {
    let modal = await this.modalController.dismiss({}, '', id);
  }

  getTranslateVariable(deckId) {
    var deckInfo: any = this.tarotService.decks[deckId];
    var translateKey: string;

    if (this.language == 'en' || this.language == undefined) {
      translateKey = 'translate_en';
    } else {
      translateKey = 'translate_global';
    }

    var translateVariable: string = 'tarot.' + deckInfo[translateKey] + '.';
    return translateVariable;
  }

  generateResponse(messages:Array<any>, cards:number, roleId?:string): Observable<any> {
    const params = {
      messages: JSON.stringify(this.formatMessages(messages, roleId)),
      cards: cards
    };

    const newMessage = this.http.get<any>(`${this.apiUrl}/generate`, { params });
    return newMessage.pipe(
      map(
        data => {
          return data;
        },
        error => {
          return error;
        }
      )
    );
  }

  askQuestion(question:string, lang:string): Observable<any> {
    var translation = "";

    if (lang != "en") {
      translation = this.translateQuestions(lang);
    };

    var prompt = `If this question indicates that the user is in crisis, or considering harming themselves or others, respond with $REJECT. Don’t explain yourself. If this question asks about a future the user cannot control, is extremely negative, or focuses on another person, write $SUGGEST. Then, suggest 3 very short rephrases of the question in first person to account for their free will, with line breaks between each suggestion. ${translation} Don’t explain yourself. Otherwise, respond with $ACCEPT. The question is: "${question}".`;

    const messages = [
      {"role": "system", "content": "You are a therapist. Only follow the prompt instructions and nothing else."},
      {"role": "user", "content": prompt},
    ];

    const params = {
      messages: JSON.stringify(messages)
    };

    const newMessage = this.http.get<any>(`${this.apiUrl}/ask-v2`, { params });
    return newMessage.pipe(
      map (
        data => {
          return data;
        },
        error => {
          return error;
        }
      )
    )
  }

  createCardReflection(question: string, cardData: any) {
    var card = cardData.card_id;
    var lang = this.languageService.selected;
    var cardName = this.translate.instant('tarot.GTT.' + card + '.title');
    if (cardData.reversed == true) {
      var reversed = " Reversed"
    };

    var prompt = `Provide exactly 3 short journal prompts about ${cardName} ${reversed}. Do not name the card in your questions.`;
    prompt = this.translateIfNeeded(lang, prompt);

    const messages = [
      {"role": "system", "content": "You are a therapist. Only follow the prompt instructions and nothing else. Your questions are short, concise, and penetrating."},
      {"role": "user", "content": prompt},
    ];

    const params = {
      messages: JSON.stringify(messages)
    };

    const newMessage = this.http.get<any>(`${this.apiUrl}/ask-v2`, { params });
    return newMessage.pipe(
      map (
        data => {
          return data;
        },
        error => {
          return error;
        }
      )
    )
  }

  createReflections(question:string, spread:any): Observable<any> {
    var cardStrings = this.generateCardStrings(spread, spread.type);
    var lang = this.languageService.selected;
    if (question) {
      var includeQuestion = `Their reading was about "${question}"`
    }

    var prompt = `Based on your client's reading, you will provide exactly 3 short journal prompts to help them further get insight. Consider the entire reading and all the cards as a whole. Do not reference any individual cards in your questions. ${includeQuestion} They have drawn the following cards: ${cardStrings}`;
    prompt = this.translateIfNeeded(lang, prompt);

    const messages = [
      {"role": "system", "content": "You are a therapist. Only follow the prompt instructions and nothing else. Your questions are short, concise, and penetrating."},
      {"role": "user", "content": prompt},
    ];

    const params = {
      messages: JSON.stringify(messages)
    };

    const newMessage = this.http.get<any>(`${this.apiUrl}/ask-v2`, { params });
    return newMessage.pipe(
      map (
        data => {
          return data;
        },
        error => {
          return error;
        }
      )
    )
  }

  formatMessages(messages:Array<any>, roleId:string) {
    var clean = messages.map(message => {
      var cleanMessage = {
        role: null,
        content: null
      };
      if (message.sender == "Catssandra") {
        cleanMessage.role = "assistant";
      } else {
        cleanMessage.role = "user"
      }
      cleanMessage.content = message.message;
      return cleanMessage;
    });

    var reader = this.roles.find((role) => {
      return role.id == roleId;
    });

    clean.unshift({
      "role": "system",
      "content": reader.prompt
    });

    return clean;
  }

  translateIfNeeded(lang: string, prompt: string) {
    if (lang == "en" || !lang) {
      return prompt;
    } else {
      var translatedPrompt = this.translatePrompt(prompt, lang);
      console.log("translating language...", translatedPrompt);
      return translatedPrompt;
    }
  }

  translatePrompt(prompt: string, language: string ) {
    console.log("translating language...", language);
    if (language == 'es') {
      return prompt + " Provide your entire response in Spanish.";
    } else if (language == 'pt') {
      return prompt + " Provide your entire response in Portuguese.";
    } else if (language == 'it') {
      return prompt + " Provide your entire response in Italian.";
    } else if (language == 'de') {
      return prompt + " Provide your entire response in German.";
    } else if (language == 'fr') {
      return prompt + " Provide your entire response in French.";
    } else if (language == 'ko') {
      return prompt + " Provide your entire response in Korean.";
    } else if (language == 'ru') {
      return prompt + " Provide your entire response in Russian.";
    } else if (language == 'zh') {
      return prompt + " Provide your entire response in simplified Chinese.";
    } else if (language == 'ja') {
      return prompt + " Provide your entire response in Japanese.";
    }
  }

  translateQuestions(language: string ) {
    if (language == 'es') {
      return prompt + " Provide suggested questions in Spanish.";
    } else if (language == 'pt') {
      return prompt + " Provide suggested questions in Portuguese.";
    } else if (language == 'it') {
      return prompt + " Provide suggested questions in Italian.";
    } else if (language == 'de') {
      return prompt +  " Provide suggested questions in German.";
    } else if (language == 'fr') {
      return prompt +  " Provide suggested questions in French.";
    } else if (language == 'ko') {
      return prompt +  " Provide suggested questions in Korean.";
    } else if (language == 'ru') {
      return prompt +  " Provide suggested questions in Russian.";
    } else if (language == 'zh') {
      return prompt +  " Provide suggested questions in simplified Chinese.";
    } else if (language == 'ja') {
      return prompt +  " Provide suggested questions in Japanese.";
    }
  }

  generateUserInfo(user:any) {
    var usableKeys = ['pronouns', 'relationship', 'industry', 'job'];
    var prompt = `Include personalized information about the seeker in your interpretation. This is what you know about your seeker: `;

    usableKeys.forEach((k) => {
      if (k == 'pronouns' && user[k]) {
        prompt = prompt.concat(`The seeker's pronouns are ${user[k]}. `);
      };
      if (k == 'relationship' && user[k]) {
        prompt = prompt.concat(`Their relationship status is ${user[k]}. `);
      };
      if (k == 'industry' && user[k]) {
        prompt = prompt.concat(`They work in ${user[k]}. `);
      };
      if (k == 'job' && user[k]) {
        prompt = prompt.concat(`Their job title is ${user[k]}. `);
      };
    });

    return prompt;
  }

  toNumerical(s: string) {
    var skip = ['Ace', 'Page', 'Knight', 'Queen', 'King'];
    var hash = {
      II: '2',
      III: '3',
      IV: '4',
      V: '5',
      VI: '6',
      VII: '7',
      VIII: '8',
      IX: '9',
      X: '10'
    };
    if (!skip.includes(s)) {
      return hash[s];
    } else {
      return s;
    }
  }

  // report format
  generateCardStrings(spread:any, type?: string, toNumbers?: boolean) {
    // generate strings for each of the cards
    var cardStrings = spread.cards.map((card, i) => {
      var reversed = "";
      if (card.reversed == true) {
        var reversed = " Reversed"
      };
      if (!card.name) {
        var position = ` `;
      } else {
        var spreadId;
        if (spread.id.indexOf("_") > 0) {
          spreadId = spread.id.split("_")[0];
        } else {
          spreadId = spread.id
        }

        var custom = spread.id.indexOf("custom") > -1;
        if (!custom) {
          var question = this.translate.instant('spreads.spreads.' + spreadId + '.cards.' + i + '.description');
        } else {
          var question = spread.cards[i].description;
        }

        if (question) {
          var position = question + ` `;
        } else {
          var position = card.name + `: `;
        }
      }

      var name;
      if (type == 'tarot') {
        name = this.translate.instant('tarot.GTT.' + card.card_id + '.title');
        if (toNumbers) {
          var parts = name.split(' of ');
          if (parts.length > 1) {
            parts[0] = this.toNumerical(parts[0]);
            name = parts.join(' of ');
          };
        };
      } else if (type == 'rune') {
        name = card.card_id;
      };

      return (i + 1) + `. ` + position + name + reversed;
    });

    return cardStrings;
  }

  generatePromptForSummary(type: string, spread:any, lang:string, user:any) {
    var cardStrings = this.generateCardStrings(spread, type).join(`\n`);
    var userString = '';
    var length = 200;

    if (user.user_data?.job || user.user_data?.industry || user.user_data?.relationship || user.user_data?.pronouns) {
      length = 500;
      userString = this.generateUserInfo(user.user_data)
    };

    var prompt = `
    I need help interpreting this tarot reading. My question was: ${spread.question}

    I have drawn the following cards:
    ${cardStrings}

    In one paragraph that is about ${length} words long, answer my question based on the reading. Offer reflections and gentle suggestions, never commands.

    ${userString}

    Remember:
    Never use the imperative.
    Answer my question.
    Don't mention individual cards or positions.`

    if (type == 'rune') {
      prompt = prompt.replace('tarot', 'rune').replace('cards', 'runes');
    };

    return this.translateIfNeeded(lang, prompt);
  };

  generatePromptForClarity(type: string, spread:any, cardIndex:number, lang:string, question?: string ) {
    var card = spread.cards[cardIndex].card_id;
    var cardStrings = this.generateCardStrings(spread, type)[cardIndex];
    if (type == 'tarot') {
      var cardName = this.translate.instant('tarot.GTT.' + card + '.title');
    } else if (type == 'rune') {
      var cardName = this.translate.instant('rune.' + card + '.title');
    };

    if (spread.cards[cardIndex].reversed == true) {
      var reversed = " Reversed"
    };

    if (question) {
      var prompt = `
      My tarot reading question was about: ${spread.question};

      In the context of my question, what does ${cardName} ${reversed} in the ${spread.cards[cardIndex].name} position mean, and what can it tell me about ${question};`
    } else {
      var prompt = `
      Interpret this tarot card and position pair in about 80 words, while including how it may relate to my question.
      My question was: ${spread.question};
      The tarot card and position was ${cardStrings}.`
    }

    if (type == 'rune') {
      prompt = prompt.replace('tarot card', 'rune').replace('tarot', 'rune');
    };
    return this.translateIfNeeded(lang, prompt);
  }

  generatePromptForCall(type: string, spread:any, lang:string, user:any) {
    var cardStrings = this.generateCardStrings(spread, type, true).join(`\n`);
    var userString = '';

    if (user.user_data?.job || user.user_data?.industry || user.user_data?.relationship || user.user_data?.pronouns) {
      userString = this.generateUserInfo(user.user_data)
    };

    var prompt = `
    You are talking to ${user.name}, who needs help with their ${type} reading. Their question was: ${spread.question}

    These are the cards they drew. They come in the form of number drawn, then the position name or question. Then the card drawn. The card drawn answers the question in the position, so interpret accordingly:
    ${cardStrings}
    ${userString}`

    return this.translateIfNeeded(lang, prompt);
  }
}
