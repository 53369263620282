import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AnalyticsService } from '../../services/analytics.service';
import { PurchaseService } from '../../services/purchase.service';
import { UserService } from '../../services/user.service';
import { ActivatedRoute, RouterModule, Router } from '@angular/router';
import { LogService } from '../../services/log.service';
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';
import { DealPage } from '../../modals/deal/deal.page';
import { Storage } from '@ionic/storage';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.page.html',
  styleUrls: ['./signup.page.scss'],
})
export class SignupPage implements OnInit, OnDestroy {
  constructor(
    private modalController: ModalController,
    private analyticsService: AnalyticsService,
    private toastController: ToastController,
    private purchaseService: PurchaseService,
    private userService: UserService,
    private logService: LogService,
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private iab: InAppBrowser,
    private storage: Storage,
  ) { }

  user: any = null;
  userUpdates: Subscription;
  form: string = 'signup';
  loading: boolean = false;
  message: string;
  subscribe: boolean = true;
  email: string;
  password: string;
  name: string;
  url = environment.labyrinthosAPI;

  async dismiss() {
    let modal = await this.modalController.getTop();
    modal.dismiss();
  }

  openURL(url) {
    const browser = this.iab.create(url, '_system');
    this.analyticsService.logEvent('visit_website', {
      content_type: 'terms',
      content_id: url
    });
  }

  signup() {
    const signup = this.httpClient.post(this.url + 'user', {
      email: this.email,
      password: this.password,
      type: null,
      name: this.name,
      subscribe_to_newsletter: this.subscribe
    });
    this.loading = true;

    return signup.subscribe (
      data => {
        Promise.resolve()
        .then(() => {
          return this.userService.setToken(data['token']);
        })
        .then(() => {
          return this.userService.getUser();
        })
        .then(() => {
          return this.userService.setData('name', this.name, false);
        })
        .then(() => {
          return this.userService.setData('email', this.email, false);
        })
        .then(() => {
          return this.userService.setData('autoSave', true, false);
        })
        // .then(() => {
        //   return this.purchaseService.init();
        // })
        .then(() => {
          // make sure token is pulled again after signup
          return this.logService.init();
        })
        .then(() => {
          this.router.navigateByUrl('/tabs/tab-reading');
          this.loading = false;
        })
        .then(() => {
          // for new users, always
          return this.checkDealEligibility();
        })
        .then(() => {
          this.dismiss();
          this.analyticsService.logEvent('sign_up', { method: 'email' });
          this.analyticsService.setProperty({
            email: this.email
          });
          if (!this.user?.permissions?.journals.includes('journal_265')) {
            this.openDeal();
          };
        })
      },
      error => {
        this.loading = false;
        this.message = error['error'].all_errors;
        this.presentError(this.message);
      }
    )
  }

  checkDealEligibility() {
    return this.storage.get('deal_expiration').then(data => {
      if (!data) {
        const today = new Date();
        const expiration = today.setHours(today.getHours() + 72);
        this.storage.set('deal_expiration', expiration);
      }
    })
  }

  async login() {
    const login = this.httpClient.post(this.url + 'login', {
      email: this.email,
      password: this.password
    });
    this.loading = true;
    this.analyticsService.setProperty({
      email: this.email
    });

    return login.subscribe (
      data => {
        Promise.resolve()
        .then(() => {
          return this.userService.setToken(data['token']);
        })
        .then(() => {
          return this.userService.getUserData();
        })
        .then(() => {
          return this.logService.getAPILogs('v2');
        })
        .then(() => {
          return this.logService.getAPILogs('v2-spreads');
        })
        .then(() => {
          return this.logService.getAPILogs('userData');
        })
        .then(() => {
          return this.logService.getAPILogs('score');
        })
        .then(() => {
          return this.userService.setData('autoSave', true, false);
        })
        // .then(() => {
        //   return this.purchaseService.init();
        // })
        .then(() => {
          this.analyticsService.logEvent('login', { method: 'email' });
          setTimeout( () => {
            this.loading = false;
            this.dismiss();
            this.router.navigateByUrl('/tabs/tab-reading');
          }, 1000);
        })
        .catch(e => {
          console.log(e);
        })
      },
      error => {
        this.loading = false;
        this.message = error.error.message;
        this.presentError(this.message);
      }
    )
  }

  forgotPassword() {
    this.dismiss();
    this.router.navigateByUrl('/forgot');
  }

  async openDeal() {
    const modal = await this.modalController.create({
      component: DealPage,
      id: 'deal'
    });
    return await modal.present();
  };

  async presentError(message) {
    const toast = await this.toastController.create({
      message: '❌ ' + message,
      duration: 5000,
      color: 'danger',
      position: 'top'
    });
    toast.present();
  }

  ngOnDestroy() {
    this.userUpdates.unsubscribe();
  }

  ngOnInit() {
    if (Capacitor.getPlatform() == 'ios' || Capacitor.getPlatform() == 'android') {
      Keyboard.setAccessoryBarVisible({ isVisible: true });
    }

    this.userUpdates = this.userService.getSubscription('userSubscription').subscribe((data) => {
      this.user = data;
    });
  }

  ionViewWillEnter() {
    this.userService.getUser().then( data  => {
      this.user = data;
    });
  }
}
