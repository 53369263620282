import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { SwiperModule } from 'swiper/angular';

// Common Components
import { ExpBarComponent } from './components/exp-bar/exp-bar.component';
import { ReadingToolbarComponent } from './components/reading-toolbar/reading-toolbar.component';
import { MenuComponent } from './components/menu/menu.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { ShuffleAnimationComponent } from './components/shuffle/shuffle-animation.component';
import { CreditsComponent } from './components/credits/credits.component';
import { ChatButtonComponent } from './components/chat-button/chat-button.component';
import { BuySectionComponent } from './components/buy/buy-section.component';
import { FilterButtonComponent } from './components/filter-button/filter-button.component';
import { ProductComponent } from './components/product/product.component';
import { ReadingSettingsComponent } from './components/reading-settings/reading-settings.component';
import { CardComponent } from './components/card/card.component';
import { PremiumComponent } from './components/premium/premium.component';
import { CardNotesComponent } from './components/card-notes/card-notes.component';
import { ReflectComponent } from './components/reflect/reflect.component';
import { FreeFormLayoutComponent } from './components/free-form-layout/free-form-layout.component';
import { DeckComponent } from './components/deck/deck.component';
import { ShufflePickComponent } from './components/shuffle-pick/shuffle-pick.component';
import { RuneComponent } from './components/rune/rune.component';
import { SwipeSegmentComponent } from './components/swipe-segment/swipe-segment.component';
import { PublicHeaderComponent } from './components/public-header/public-header.component';
import { RatingComponent } from './components/rating/rating.component';
import { TranscriptComponent } from './components/transcript/transcript.component';

// Modals
import { SelectModalPage } from './modals/select-modal/select-modal.page';
import { CardModalPage } from './modals/card-modal/card-modal.page';
import { SaveReadingPage } from './modals/save-reading/save-reading.page';
import { InterpretPage } from './modals/interpret/interpret.page';
import { EditSpreadPositionPage } from './modals/edit-spread-position/edit-spread-position.page';
import { ListPositionsPage } from './modals/list-positions/list-positions.page';
import { SaveSpreadPage } from './modals/save-spread/save-spread.page';
import { ArticlePage } from './modals/article/article.page';
import { NotificationPage } from './modals/notification/notification.page';
import { DefaultSettingsPage } from './modals/default-settings/default-settings.page';
import { ReportPage } from './modals/report/report.page';
import { CatssandraPage } from './modals/catssandra/catssandra.page';
import { FilterCardsPage } from './modals/filter-cards/filter-cards.page';
import { DigitalShopPage } from './modals/digital-shop/digital-shop.page';
import { LoadingPage } from './modals/loading/loading.page';
import { SignupPage } from './modals/signup/signup.page';
import { JournalShopPage } from './modals/journal-shop/journal-shop.page';
import { DealPage } from './modals/deal/deal.page';

const routes: Routes = [
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule,
        SwiperModule
    ],
    declarations: [
        MenuComponent,
        CreditsComponent,
        ReadingToolbarComponent,
        ExpBarComponent,
        AvatarComponent,
        ShuffleAnimationComponent,
        ChatButtonComponent,
        BuySectionComponent,
        FilterButtonComponent,
        ProductComponent,
        ReadingSettingsComponent,
        CardComponent,
        PremiumComponent,
        CardNotesComponent,
        ReflectComponent,
        FreeFormLayoutComponent,
        DeckComponent,
        ShufflePickComponent,
        RuneComponent,
        SwipeSegmentComponent,
        PublicHeaderComponent,
        RatingComponent,
        TranscriptComponent,
        CardModalPage,
        SelectModalPage,
        SaveReadingPage,
        InterpretPage,
        EditSpreadPositionPage,
        ListPositionsPage,
        SaveSpreadPage,
        ArticlePage,
        NotificationPage,
        DefaultSettingsPage,
        ReportPage,
        CatssandraPage,
        FilterCardsPage,
        DigitalShopPage,
        LoadingPage,
        SignupPage,
        JournalShopPage,
        DealPage,
    ],
    exports: [
        MenuComponent,
        ReadingToolbarComponent,
        ExpBarComponent,
        AvatarComponent,
        CreditsComponent,
        ShuffleAnimationComponent,
        ChatButtonComponent,
        BuySectionComponent,
        FilterButtonComponent,
        ProductComponent,
        ReadingSettingsComponent,
        CardComponent,
        PremiumComponent,
        CardNotesComponent,
        ReflectComponent,
        FreeFormLayoutComponent,
        DeckComponent,
        ShufflePickComponent,
        RuneComponent,
        SwipeSegmentComponent,
        PublicHeaderComponent,
        RatingComponent,
        TranscriptComponent
    ]
})
export class SharedModule {}
