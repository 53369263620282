import { Component, Input, NgZone } from '@angular/core';
import { ActivatedRoute, RouterModule, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { LenormandService } from '../../services/lenormand.service';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CardModalPage } from '../../modals/card-modal/card-modal.page';
import { Spread } from '../../spread';
import { AnalyticsService } from '../../services/analytics.service';
import SwiperCore, { SwiperOptions, Pagination } from 'swiper';
SwiperCore.use([Pagination]);

@Component({
  selector: 'app-interpret',
  templateUrl: './interpret.page.html',
  styleUrls: ['./interpret.page.scss'],
})
export class InterpretPage {
  constructor(
    private modalController: ModalController,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private lenormandService: LenormandService,
    private translate: TranslateService,
    private analyticsService: AnalyticsService,
    private zone: NgZone
  ) { }

  @Input() cardsInSpread: any;
  @Input() index: number;
  @Input() section: any;
  @Input() userDeck: string;
  @Input() id: string;
  user: Promise<any>|null = null;
  lenormandDeck: string;
  currentView: number = 0;
  slideOpts: SwiperOptions = {
    slidesPerView: 2,
    pagination: true
  };
  sectionCards: any = [];
  interpretations: any = [];
  spread: any = {};

  async dismiss() {
    const modal = await this.modalController.getTop();
    modal.dismiss();
  }

  generateInterpretation(index) {
    const a = this.sectionCards[index].card_id;
    const b = this.sectionCards[index + 1].card_id;
    const subjects = this.translate.instant('lenormand.' + a + '.subjects');
    const modifiers = this.translate.instant('lenormand.' + b + '.modifiers');
    const interpretations = [];

    for (let modifier of modifiers) {
      for (let subject of subjects) {
        interpretations.push(modifier + ' ' + subject);
      }
    }
    return interpretations;
  }

  changeSlide(ev) {
    var index = ev[0].activeIndex;
    this.zone.run(() => {
      this.interpretations = this.generateInterpretation(index);
    });
  }

  changeSectionCards() {
    this.spread = {
      id: this.id,
      cards: this.sectionCards
    }
  }

  // HAS REPEATS TO AVOID CIRCULAR DEPENDENCIES
  async openCard(spread: Spread, i: number, deck: string, permissions?: any) {
    var type = 'lenormand';
    var card = this[type + 'Service'].getCardByDeck(spread.cards[i].card_id, deck)[0];
    var spreadId = spread.id;

    if (spread.id.includes('_')) {
      spreadId = spread.id.split('_')[0];
    };

    var props = {
        card: card,
        userDeck: deck,
        spread: spread,
        index: i,
        spreadType: type,
        permissions: permissions,
        spreadId: spreadId
    };

    const modal = await this.modalController.create({
      component: CardModalPage,
      componentProps: props,
      id: card.image
    });

    this.analyticsService.logEvent('view_card', {
      content_type: type,
      card_id: card,
      deck_id: deck,
      source: 'reading'
    });

    return await modal.present();
  }

  ionViewWillEnter() {
    // if it's only a subsection of the reading...
    if (this.section) {
      var subset = new Array;
      for (let i of this.section) {
        subset.push(this.cardsInSpread[i]);
      }
      this.sectionCards = subset;
    } else {
      this.sectionCards = this.cardsInSpread;
    }

    this.changeSectionCards();

    this.userService.getUser().then( data  => {
      this.user = data;
      if (!this.userDeck) {
        this.userDeck = data.lenormandDeck;
      }
      this.interpretations = this.generateInterpretation(0);
    });
  }
}
