import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { LaunchReview } from '@awesome-cordova-plugins/launch-review/ngx';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class RateService {

  constructor(
    private alertController: AlertController,
    private translate: TranslateService,
    private iab: InAppBrowser,
    private launchReview: LaunchReview,
  ) { }

  async firstAlert() {
    if (Capacitor.getPlatform() != 'web') {
      const alert = await this.alertController.create({
        header: this.translate.instant('_common.rate.alert1_title'),
        message: this.translate.instant('_common.rate.alert1_message'),
        cssClass: 'illustration confirm',
        mode: 'md',
        buttons: [
          {
            text: this.translate.instant('_common.rate.not_really'),
            role: 'cancel',
            handler: () => {
              this.thirdAlert();
            },
          },
          {
            text: this.translate.instant('_common.yep'),
            role: 'confirm',
            handler: () => {
              this.secondAlert();
            },
          },
        ],
      });
      await alert.present();
    }
  }

  async secondAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant('_common.rate.alert2_title'),
      cssClass​: 'illustration dark confirm',
      mode: 'md',
      message: this.translate.instant('_common.rate.alert2_message'),
      buttons: [
        {
          text: this.translate.instant('_common.no_thanks'),
          role: 'cancel'
        },
        {
          text: this.translate.instant('_common.rate.rate'),
          role: 'confirm',
          handler: () => {
            this.rateApp();
          },
        },
      ],
    });
    await alert.present();
  }

  async thirdAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant('_common.rate.alert3_title'),
      cssClass​: 'illustration dark confirm',
      mode: 'md',
      message: this.translate.instant('_common.rate.alert3_message'),
      buttons: [
        {
          text: this.translate.instant('_common.no_thanks'),
          role: 'cancel'
        },
        {
          text: this.translate.instant('_common.yep'),
          role: 'confirm',
          handler: () => {
            window.open('mailto:faculty@labyrinthos.co?subject=Labyrinthos App Feedback','_system');
          },
        },
      ],
    });
    await alert.present();
  }

  rateAppNative() {
    // this basically never works...
    if (this.launchReview.isRatingSupported()){
      this.launchReview.rating();
      console.log('Successfully native rating dialog');
    } else {
      this.launchReview.launch()
        .then(() => console.log('Successfully launched store app'));
    }
  }

  rateApp() {
    this.launchReview.launch()
      .then(
        () => console.log('Successfully launched store app'),
        (error) => console.error('failed to launch', error)
      )
  }
}
