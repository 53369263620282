<div *ngIf="spreadSettings">
  <ion-list>
    <ion-item-divider mode="md">
      <ion-icon color="primary" name="cog-outline" slot="start"></ion-icon>
      <ion-label class="capitals">{{'reading_settings.category_general' | translate}}</ion-label>
    </ion-item-divider>
    <ion-item *ngIf="spreadType == 'tarot'">
      <ion-label text-wrap>
        {{'reading_settings.general_reversals' | translate}}
        <ion-icon color="primary" name="help-circle-outline" slot="start" id="reversalsPop"></ion-icon>
        <ion-popover trigger="reversalsPop">
          <ng-template>
            <p>If deck allows reversals, choose whether to use them in this reading.</p>
          </ng-template>
        </ion-popover>
      </ion-label>
      <ion-toggle slot="end" [(ngModel)]="spreadSettings.reversals" mode="ios" (ionChange)="updateSettings()"></ion-toggle>
    </ion-item>
    <ion-item *ngIf="showAuto && user.email">
      <ion-label text-wrap>
        {{'reading_settings.general_autosave' | translate}}
        <ion-icon color="primary" name="help-circle-outline" slot="start" id="autoPop"></ion-icon>
        <ion-popover trigger="autoPop">
          <ng-template>
            <p>Save readings automatically. If no question is asked prior to reading, name of spread will be the title. Note: Free-form readings cannot be auto-saved, as the app doesn't know when you're complete.</p>
          </ng-template>
        </ion-popover>
      </ion-label>
      <ion-toggle slot="end" [(ngModel)]="spreadSettings.autoSave" mode="ios" (ionChange)="updateSettings()"></ion-toggle>
    </ion-item>
    <ion-item *ngIf="spreadSettings.readingType == 'digital'">
      <ion-label text-wrap>
        {{'reading_settings.general_intentions' | translate}}
        <ion-icon color="primary" name="help-circle-outline" slot="start" id="pickPop"></ion-icon>
        <ion-popover trigger="pickPop">
          <ng-template>
            <p>Don't let the app choose cards for you. Pick each individual cards in digital, non-daily readings.</p>
          </ng-template>
        </ion-popover>
      </ion-label>
      <ion-toggle slot="end" [(ngModel)]="spreadSettings.pickCards" mode="ios" (ionChange)="updateSettings()"></ion-toggle>
    </ion-item>
    <ion-item *ngIf="spreadSettings.readingType == 'digital'">
      <ion-label text-wrap>
        {{'reading_settings.general_shuffle' | translate}}
        <ion-icon color="primary" name="help-circle-outline" slot="start" id="shufflePop"></ion-icon>
        <ion-popover trigger="shufflePop">
          <ng-template>
            <p>In digital readings, shuffle before reading. Use this time to set your intentions.</p>
          </ng-template>
        </ion-popover>
      </ion-label>
      <ion-toggle slot="end" [(ngModel)]="spreadSettings.shuffle" mode="ios" (ionChange)="updateSettings()"></ion-toggle>
    </ion-item>
  </ion-list>
  <ion-list>
    <ion-radio-group value="spreadSettings.readingType" allow-empty-selection="false" [(ngModel)]="spreadSettings.readingType" (ionChange)="updateSettings()">
      <ion-item-divider mode="md">
        <ion-icon color="primary" name="hand-left-outline" slot="start"></ion-icon>
        <ion-label class="capitals">{{'reading_settings.category_style' | translate}}</ion-label>
      </ion-item-divider>
      <ion-item>
        <ion-label text-wrap>
          {{'reading_settings.style_digital' | translate}}
        </ion-label>
        <ion-radio slot="end" value="digital" mode="md"></ion-radio>
      </ion-item>
      <ion-item>
        <ion-label text-wrap>
          {{'reading_settings.style_physical' | translate}}
        </ion-label>
        <ion-radio slot="end" value="physical" mode="md"></ion-radio>
      </ion-item>
    </ion-radio-group>

    <div *ngIf="spreadSettings.readingType == 'digital' && spreadType == 'tarot'">
      <ion-item-divider mode="md">
        <ion-icon color="primary" name="filter-outline" slot="start"></ion-icon>
        <ion-label class="capitals">
          {{'reading_settings.general_subset' | translate}}
          <ion-icon color="primary" name="help-circle-outline" slot="end" id="subsetPop"></ion-icon>
        </ion-label>
        <ion-popover trigger="subsetPop" mode="ios">
          <ng-template>
            <p>In a digital reading, this feature will only let you pull from a certain group of cards.</p>
          </ng-template>
        </ion-popover>
      </ion-item-divider>
      <ion-item>
        <ion-select placeholder="All Cards" multiple="true" [(ngModel)]="spreadSettings.subset" mode="md" [interfaceOptions]="subsetAlertOptions" (ionChange)="updateSettings()">
          <ion-select-option *ngFor="let option of subsetOptions" [value]="option.value">{{option.text | translate}}</ion-select-option>
        </ion-select>
        <ion-button *ngIf="spreadSettings.subset && spreadSettings.subset.length > 0" (click)="clearSubset()" size="small" fill="clear" slot="end" class="selectClearButton" color="danger">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-item>
    </div>
  </ion-list>

  <ion-list>
    <ion-item-divider mode="md">
      <ion-icon color="primary" name="library-outline" slot="start"></ion-icon>
      <ion-label class="capitals">{{'reading_settings.category_deck' | translate}}</ion-label>
    </ion-item-divider>
    <ion-searchbar mode="ios" [(ngModel)]="searchText" placeholder="Search Available Digital Decks" (ionChange)="setFilteredItems()"></ion-searchbar>
    <ion-radio-group value="spreadSettings.deck" allow-empty-selection="false" [(ngModel)]="spreadSettings.deck" (ionChange)="updateSettings()">
      <app-product [item]="deck" [productType]="'list'" [radio]="true" *ngFor="let deck of searchedDecks"></app-product>
    </ion-radio-group>
  </ion-list>
</div>
