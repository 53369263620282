import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { Capacitor } from '@capacitor/core';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private navCtrl: NavController
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return await this.checkAuth();
  }

  async checkAuth() {
    if (Capacitor.getPlatform() == 'web') {
      const authed = await this.userService.getToken();
      const user = await this.userService.getUser();
      return (authed && user?.email) || this.routeToLogin();
      // return true;
    } else {
      return true;
    }
  }

  routeToLogin(): boolean {
    this.navCtrl.navigateRoot('/home');
    return false;
  }
}
