<div class="container" style="
  --height:{{ height }};
  --bg:{{ product.deckView.containerBg }};
  --bgGradient:{{ product.deckView.containerBgGradient }};
  ">
  <div class="box-flat {{view}}" *ngIf="view.includes('box-flat')" style="
    --boxH:{{ product.deckView.boxH }};
    --boxW:{{ product.deckView.boxW }};
    ">
    <app-card [userDeck]="product.id" [local]="true" [cardId]="'back-01.png'"></app-card>
  </div>

  <div class="cards {{view}}" *ngIf="view.includes('cards')" style="
    --cardBg:{{ product.deckView.cardBg }};
    --cardH:{{ product.deckView.cardH }};
    --cardW:{{ product.deckView.cardW }};
    --skew:{{ viewData.skew }};
    ">
    <app-card *ngFor="let card of product.deckView.fan" [local]="true" [userDeck]="product.id"  [cardId]="card"></app-card>
  </div>

  <div class="front-back {{view}}" *ngIf="view.includes('front-back')">
    <app-card [userDeck]="product.id" [cardId]="product.deckView.fan[product.deckView.fan.length - 1]"></app-card>
  </div>

  <div class="scatter {{view}}" *ngIf="view.includes('scatter')">
    <app-rune [stoneId]="product.id" [letter]="letter.id" *ngFor="let letter of scatterData" [ngStyle]="{
      'left': letter.position[0] + '%',
      'top': letter.position[1] + '%',
      'transform': 'rotate(' + letter.rotate + 'deg)'
    }"></app-rune>
  </div>

  <div class="single {{view}}" *ngIf="view.includes('single') || view.includes('small')">
    <app-rune [stoneId]="product.id" [letter]="'f'"></app-rune>
    <app-rune [stoneId]="product.id" [letter]="'u'"></app-rune>
    <app-rune [stoneId]="product.id" [letter]="'þ'"></app-rune>
  </div>
</div>
