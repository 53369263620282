import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { Subscription } from 'rxjs';
import { ToastController } from '@ionic/angular';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class SpreadService {
  constructor(
    private translate: TranslateService,
    private storage: Storage,
    private toastController: ToastController,
    private userService: UserService,
  ) {
    this.init();
  }

  userUpdates: Subscription;
  favorites: any;
  positions: any = [
    {
      id: 'external',
      count: 0,
      positions: [`Obstacle`, `Motivation`, `Influences`, `Environment`, `History`, `Mystery`, `Complication`, `Context`, `Missing_Link`, `Misunderstanding`, `Risk`, `Truth`, `Opportunity`, `Foundation`, `Cost`, `Message`, `Status_Quo`, `Transition`, `Rewards`, `Consequence`, `Surprise`, `Result`, `Alternative`, `Compromise`, `Evolution`, `Lesson`, `Work`, `Romance`, `Family`, `Friendship`, `Community`, `World_Affairs`, `Finances`, `Injustice`, `Limitations`]
    },
    {
      id: 'internal',
      count: 0,
      positions: [`Focus`, `Need`, `Memory`, `Origin`, `Perception`, `Belief`, `Behavior`, `Intention`, `Temptation`, `Self-perception`, `Unconscious`, `Ego`, `Persona`, `Trait`, `Present_Self`, `Past_Self`, `Ideal_Self`, `Values`, `Spirit`, `Body`, `Mind`, `Heart`, `Secret`, `Comfort`, `Energy`, `Curiosity`, `Mental_Health`, `Physical_Health`, `Outlook`, `Role`, `Connection`, `Family_Patterns`, `Conditioning`]
    },
    {
      id: 'light',
      count: 0,
      positions: [`Guidance`, `Resource`, `Sanctuary`, `Hope`, `Purpose`, `Potential`, `Inspiration`, `Skill`, `Talent`, `Contribution`, `Relationship`, `Achievement`, `Leap_of_Faith`, `Gift`, `Ambitions`, `Passion`, `Strength`, `Power`, `Gratitude`, `Joy`, `Abundance`, `Self-care`, `Play`, `Fun`, `Growth`, `Belonging`, `Vulnerability`, `Wisdom`]
    },
    {
      id: 'shadow',
      count: 0,
      positions: [`Reservation`, `Fear`, `Trigger`, `Shame`, `Weakness`, `Guilt`, `Repression`, `Wound`, `Denial`, `Ignorance`, `Envy`, `Self-sabotage`, `Rejection`, `Avoidance`, `Punishment`, `Failure`, `Addiction`, `Grudge`, `Anger`, `Frustration`, `Neglect`, `Doubt`, `Disconnection`, `Scarcity`, `Despair`, `Suspicion`, `Detachment`, `Insecurity`]
    },
    {
      id: 'actions',
      count: 0,
      positions: [`Accept`, `Reject`, `Resist`, `Protect`, `Transform`, `Sacrifice`, `Heal`, `Break`, `Save`, `Organize`, `Reflect`, `Avoid`, `Confront`, `Abandon`, `Give`, `Receive`, `Maintain`, `Nourish`, `Question`, `Evaluate`, `Grieve`, `Learn`, `Release`, `Investigate`, `Redeem`, `Hide`, `Value`, `Communicate`, `Grasp`, `Love`, `Integrate`, `Attract`, `Open`, `Speak`, `Witness`, `Reveal`, `Ignite`, `Inspire`, `Do_Next`, `Forgive`, `Create`, `Destroy`, `Prepare`]
    }
  ];
  layouts: any = [
    {
      number: 1,
      cards: [
        {
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      number: 2,
      cards: [
        {
          position: {
            x: 'calc(30% - 9vw)',
            y: '50%'
          }
        },
        {
          position: {
            x: 'calc(70% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      number: 3,
      cards: [
        {
          position: {
            x: 'calc(20% - 9vw)',
            y: '50%'
          }
        },
        {
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          position: {
            x: 'calc(80% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      number: 4,
      cards: [
        {
          position: {
            x: 'calc(30% - 9vw)',
            y: '20%'
          }
        },
        {
          position: {
            x: 'calc(70% - 9vw)',
            y: '20%'
          }
        },
        {
          position: {
            x: 'calc(30% - 9vw)',
            y: '80%'
          }
        },
        {
          position: {
            x: 'calc(70% - 9vw)',
            y: '80%'
          }
        }
      ]
    },
    {
      number: 5,
      cards: [
        {
          position: {
            x: 'calc(30% - 9vw)',
            y: '15%'
          }
        },
        {
          position: {
            x: 'calc(70% - 9vw)',
            y: '15%'
          }
        },
        {
          position: {
            x: 'calc(30% - 9vw)',
            y: '85%'
          }
        },
        {
          position: {
            x: 'calc(70% - 9vw)',
            y: '85%'
          }
        },
        {
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      number: 6,
      cards: [
        {
          position: {
            x: 'calc(15% - 9vw)',
            y: '20%'
          }
        },
        {
          position: {
            x: 'calc(50% - 9vw)',
            y: '20%'
          }
        },
        {
          position: {
            x: 'calc(85% - 9vw)',
            y: '20%'
          }
        },
        {
          position: {
            x: 'calc(15% - 9vw)',
            y: '80%'
          }
        },
        {
          position: {
            x: 'calc(50% - 9vw)',
            y: '80%'
          }
        },
        {
          position: {
            x: 'calc(85% - 9vw)',
            y: '80%'
          }
        }
      ]
    },
    {
      number: 7,
      cards: [
        {
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          position: {
            x: 'calc(15% - 9vw)',
            y: '50%'
          }
        },
        {
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          position: {
            x: 'calc(85% - 9vw)',
            y: '50%'
          }
        },
        {
          position: {
            x: 'calc(15% - 9vw)',
            y: '90%'
          }
        },
        {
          position: {
            x: 'calc(50% - 9vw)',
            y: '90%'
          }
        },
        {
          position: {
            x: 'calc(85% - 9vw)',
            y: '90%'
          }
        }
      ]
    },
    {
      number: 8,
      cards: [
        {
          position: {
            x: 'calc(30% - 9vw)',
            y: '10%'
          }
        },
        {
          position: {
            x: 'calc(30% - 9vw)',
            y: '35%'
          }
        },
        {
          position: {
            x: 'calc(30% - 9vw)',
            y: '65%'
          }
        },
        {
          position: {
            x: 'calc(30% - 9vw)',
            y: '90%'
          }
        },
        {
          position: {
            x: 'calc(70% - 9vw)',
            y: '10%'
          }
        },
        {
          position: {
            x: 'calc(70% - 9vw)',
            y: '35%'
          }
        },
        {
          position: {
            x: 'calc(70% - 9vw)',
            y: '65%'
          }
        },
        {
          position: {
            x: 'calc(70% - 9vw)',
            y: '90%'
          }
        }
      ]
    },
    {
      number: 9,
      cards: [
        {
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          position: {
            x: 'calc(20% - 9vw)',
            y: '10%'
          }
        },
        {
          position: {
            x: 'calc(20% - 9vw)',
            y: '35%'
          }
        },
        {
          position: {
            x: 'calc(20% - 9vw)',
            y: '65%'
          }
        },
        {
          position: {
            x: 'calc(20% - 9vw)',
            y: '90%'
          }
        },
        {
          position: {
            x: 'calc(80% - 9vw)',
            y: '10%'
          }
        },
        {
          position: {
            x: 'calc(80% - 9vw)',
            y: '35%'
          }
        },
        {
          position: {
            x: 'calc(80% - 9vw)',
            y: '65%'
          }
        },
        {
          position: {
            x: 'calc(80% - 9vw)',
            y: '90%'
          }
        }
      ]
    },
    {
      number: 10,
      cards: [
        {
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          position: {
            x: 'calc(15% - 9vw)',
            y: '40%'
          }
        },
        {
          position: {
            x: 'calc(50% - 9vw)',
            y: '40%'
          }
        },
        {
          position: {
            x: 'calc(85% - 9vw)',
            y: '40%'
          }
        },
        {
          position: {
            x: 'calc(15% - 9vw)',
            y: '70%'
          }
        },
        {
          position: {
            x: 'calc(50% - 9vw)',
            y: '70%'
          }
        },
        {
          position: {
            x: 'calc(85% - 9vw)',
            y: '70%'
          }
        },
        {
          position: {
            x: 'calc(15% - 9vw)',
            y: '100%'
          }
        },
        {
          position: {
            x: 'calc(50% - 9vw)',
            y: '100%'
          }
        },
        {
          position: {
            x: 'calc(85% - 9vw)',
            y: '100%'
          }
        }
      ]
    }
  ];

  // unfortunately, in order to maintain backwards compatibility with old apps, we need to keep some duplicate data here, as well as translations.
  spreads: any = [
    {
      id: "daily",
      title: "Daily Reading",
      image: "layout-3-horizontal.svg",
      type: "general",
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Thinking",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Feeling",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Doing",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      id: "single",
      title: "Single Card",
      image: "layout-single.svg",
      type: "general",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Your Answer",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      id: "ppf",
      title: "Past, Present, Future",
      image: "layout-3-horizontal.svg",
      type: "general",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Past",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Present",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Future",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      id: "sao",
      title: "Situation, Action, Outcome",
      image: "layout-3-horizontal.svg",
      type: "general",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Situation",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Action",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Outcome",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      id: "mbs",
      title: "Mind, Body, Spirit",
      image: "layout-3-balanced.svg",
      type: "spiritual",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Mind",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Body",
          reversed: undefined,
          position: {
            x: 'calc(35% - 9vw)',
            y: '75%'
          }
        },
        {
          card_id: null,
          name: "Spirit",
          reversed: undefined,
          position: {
            x: 'calc(65% - 9vw)',
            y: '75%'
          }
        }
      ]
    },
    {
      id: "love-short",
      title: "Relationship Spread",
      image: "layout-3-horizontal.svg",
      type: "love",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "You",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Dynamics",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Partner",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      id: "compatibility",
      title: "Compatibility Spread",
      image: "layout-hex.svg",
      type: "love",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Your Wants",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Their Wants",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Differences",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Similarities",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Emotional Compatibility",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Physical Compatibility",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Mental Compatibility",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '90%'
          }
        }
      ]
    },
    {
      id: "heartbreak",
      title: "Broken Heart",
      image: "layout-hex.svg",
      type: "love",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Your Contribution",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Their Contribution",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Advice for You",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Advice for Them",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Other Influences",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Can It Be Saved?",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Worth Saving?",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '90%'
          }
        }
      ]
    },
    {
      id: "123",
      title: "Three Options",
      image: "layout-3-balanced.svg",
      type: "general",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Option 1",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Option 2",
          reversed: undefined,
          position: {
            x: 'calc(35% - 9vw)',
            y: '75%'
          }
        },
        {
          card_id: null,
          name: "Option 3",
          reversed: undefined,
          position: {
            x: 'calc(65% - 9vw)',
            y: '75%'
          }
        }
      ]
    },
    {
      id: "self",
      title: "Self Assessment and Advice",
      image: "layout-3-balanced.svg",
      type: "spiritual",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Weaknesses",
          reversed: undefined,
          position: {
            x: 'calc(35% - 9vw)',
            y: '75%'
          }
        },
        {
          card_id: null,
          name: "Strengths",
          reversed: undefined,
          position: {
            x: 'calc(65% - 9vw)',
            y: '75%'
          }
        },
        {
          card_id: null,
          name: "Advice",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '30%'
          }
        }
      ]
    },
    {
      id: "dreams",
      title: "Dream Interpretation",
      image: "layout-3-balanced.svg",
      type: "spiritual",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Origin",
          reversed: undefined,
          position: {
            x: 'calc(35% - 9vw)',
            y: '75%'
          }
        },
        {
          card_id: null,
          name: "Message",
          reversed: undefined,
          position: {
            x: 'calc(65% - 9vw)',
            y: '75%'
          }
        },
        {
          card_id: null,
          name: "Waking Life",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '30%'
          }
        }
      ]
    },
    {
      id: "goal",
      title: "Shooting Forward",
      image: "layout-spear.svg",
      type: "career",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Dream Job",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Path Forward",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "Your Qualities",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Sources of Help",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Needs Attention",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '100%'
          }
        }
      ]
    },
    {
      id: "work-issues",
      title: "Work Problems",
      image: "layout-cross-over.svg",
      type: "career",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Goal",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "Challenge",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%',
            rotate: 'rotate(90deg)'
          }
        },
        {
          card_id: null,
          name: "Pulls You Back",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "Pushes You Forward",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "Rewards",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Underlying Factors",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '100%'
          }
        }
      ]
    },
    {
      id: "finances",
      title: "Turbulent Finances",
      image: "layout-pentagram.svg",
      type: "career",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Root Cause",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Opposition",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "Action",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "Allies",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "Sources of Help",
          reversed: undefined,
          position: {
            x: 'calc(35% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Outcome",
          reversed: undefined,
          position: {
            x: 'calc(65% - 9vw)',
            y: '100%'
          }
        }
      ]
    },
    {
      id: "love-ready",
      title: "Readiness for Love",
      image: "layout-pyramid.svg",
      type: "love",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "The Love You Want",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "What Love Taught You",
          reversed: undefined,
          position: {
            x: 'calc(65% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "What Holds You Back",
          reversed: undefined,
          position: {
            x: 'calc(35% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "Is Your Heart Ready",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Is Your Mind Ready",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Is Your Spirit Ready",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '100%'
          }
        }
      ]
    },
    {
      id: "work-goals",
      title: "Brick by Brick",
      image: "layout-pyramid.svg",
      type: "career",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Purpose",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Motivation",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Responsibilities",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Your Projects",
          reversed: undefined,
          position: {
            x: 'calc(65% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "Rewards",
          reversed: undefined,
          position: {
            x: 'calc(35% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "Career Potential",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        }
      ]
    },
    {
      id: "celtic",
      title: "Celtic Cross",
      image: "layout-celtic.svg",
      type: "general",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Heart of the Matter",
          reversed: undefined,
          position: {
            x: 'calc(38% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "Challenge",
          reversed: undefined,
          position: {
            x: 'calc(37% - 9vw)',
            y: '65%',
            rotate: 'rotate(90deg)'
          }
        },
        {
          card_id: null,
          name: "Unconscious",
          reversed: undefined,
          position: {
            x: 'calc(38% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Past",
          reversed: undefined,
          position: {
            x: 'calc(18% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "Conscious",
          reversed: undefined,
          position: {
            x: 'calc(38% - 9vw)',
            y: '20%'
          }
        },
        {
          card_id: null,
          name: "Future",
          reversed: undefined,
          position: {
            x: 'calc(58% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "Querent",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Environment",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Hopes and Fears",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '40%'
          }
        },
        {
          card_id: null,
          name: "Outcome",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '10%'
          }
        }
      ]
    },
    {
      id: "stay-go",
      title: "Stay or Go",
      image: "layout-pyramid.svg",
      type: "love",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Current State",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Why Stay",
          reversed: undefined,
          position: {
            x: 'calc(40% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Why Go",
          reversed: undefined,
          position: {
            x: 'calc(60% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "How I'll feel staying",
          reversed: undefined,
          position: {
            x: 'calc(30% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "How I'll feel leaving",
          reversed: undefined,
          position: {
            x: 'calc(70% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Overall Advice",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '90%'
          }
        }
      ]
    },
    {
      id: "maslow",
      title: "Maslow's Hierarchy of Needs",
      image: "layout-arrow.svg",
      type: "spiritual",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Physiological",
          reversed: undefined,
          position: {
            x: 'calc(30% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Safety",
          reversed: undefined,
          position: {
            x: 'calc(70% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Love & Belonging",
          reversed: undefined,
          position: {
            x: 'calc(60% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Esteem",
          reversed: undefined,
          position: {
            x: 'calc(40% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Self-Actualization",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        }
      ]
    },
    {
      id: "self-love",
      title: "Self Love",
      image: "layout-rays.svg",
      type: "spiritual",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Quality",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '30%',
            rotate: 'rotate(-10deg)'
          }
        },
        {
          card_id: null,
          name: "Accomplishment",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Radiance",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '30%',
            rotate: 'rotate(10deg)'
          }
        },
        {
          card_id: null,
          name: "Skill",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '70%',
            rotate: 'rotate(-10deg)'
          }
        },
        {
          card_id: null,
          name: "Shadow",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Expression",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '70%',
            rotate: 'rotate(10deg)'
          }
        }
      ]
    },
    {
      id: "archetypes",
      title: "Jungian Archetypes",
      image: "layout-corner-center.svg",
      type: "spiritual",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Persona",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '20%'
          }
        },
        {
          card_id: null,
          name: "Shadow",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '20%'
          }
        },
        {
          card_id: null,
          name: "Anima",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '80%'
          }
        },
        {
          card_id: null,
          name: "Animus",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '80%'
          }
        },
        {
          card_id: null,
          name: "Self",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      id: "dream-mirror",
      title: "Dream Mirror",
      image: "layout-corner-cross.svg",
      type: "spiritual",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Waking Life Environment",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Dream World Environment",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Waking Emotions",
          reversed: undefined,
          position: {
            x: 'calc(45% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Dream Emotions",
          reversed: undefined,
          position: {
            x: 'calc(45% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Foundations",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Lesson",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Waking Life Block",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '70%',
            rotate: 'rotate(90deg)'
          }
        }
      ]
    },
    {
      id: "self-growth",
      title: "Self Growth",
      image: "layout-grid-cross.svg",
      type: "spiritual",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Past Self",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Current Self",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Highest Self",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Blocks Potential",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%',
            rotate: 'rotate(90deg)'
          }
        },
        {
          card_id: null,
          name: "Family Patterns",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Personal Issues",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Conscience",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Desire",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Lesson",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Advice",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '90%'
          }
        }
      ]
    },
    {
      id: "new-moon",
      title: "New Moon",
      image: "layout-3-horizontal.svg",
      type: "moon",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Where you want to be",
          reversed: undefined,
          position: {
              x: 'calc(20% - 9vw)',
              y: '50%'
          }
        },
        {
          card_id: null,
          name: "Where you are",
          reversed: undefined,
          position: {
              x: 'calc(50% - 9vw)',
              y: '50%'
          }
        },
        {
          card_id: null,
          name: "Where you have been",
          reversed: undefined,
          position: {
              x: 'calc(80% - 9vw)',
              y: '50%'
          }
        }
      ]
    },
    {
      id: "wax-cres",
      title: "Waxing Crescent",
      image: "layout-pyramid.svg",
      type: "moon",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Your Talents",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Your Environment",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Your Resources",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "What Cannot Change",
          reversed: undefined,
          position: {
            x: 'calc(35% - 9vw)',
            y: '60%'
          }
        },
        {
          card_id: null,
          name: "What Must Change",
          reversed: undefined,
          position: {
            x: 'calc(65% - 9vw)',
            y: '60%'
          }
        },
        {
          card_id: null,
          name: "Outcome",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '20%'
          }
        }
      ]
    },
    {
      id: "first-quarter",
      title: "First Quarter",
      image: "layout-reverse-pyramid.svg",
      type: "moon",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "The Querent",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Expected Obstacle",
          reversed: undefined,
          position: {
            x: 'calc(40% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Overcomes Expected Obstacle",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Strengthens Expected Obstacle",
          reversed: undefined,
          position: {
            x: 'calc(40% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Unforeseen Obstacle",
          reversed: undefined,
          position: {
            x: 'calc(60% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Overcomes Unforeseen Obstacle",
          reversed: undefined,
          position: {
            x: 'calc(60% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Strengthens Unforeseen Obstacle",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '10%'
          }
        }
      ]
    },
    {
      id: "wax-gibb",
      title: "Waxing Gibbous",
      image: "layout-cross.svg",
      type: "moon",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "The Problem",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "What You Expected",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "What You Got",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Your Weakness",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Your Strength",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      id: "full-moon",
      title: "Full Moon",
      image: "layout-arch.svg",
      type: "moon",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Self Perception",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '80%'
          }
        },
        {
          card_id: null,
          name: "Best Qualities",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '33%',
            rotate: 'rotate(-10deg)'
          }
        },
        {
          card_id: null,
          name: "Your Gift to Others",
          reversed: undefined,
          position: {
            x: 'calc(40% - 9vw)',
            y: '27%',
            rotate: 'rotate(-5deg)'
          }
        },
        {
          card_id: null,
          name: "What Fulfills You",
          reversed: undefined,
          position: {
            x: 'calc(60% - 9vw)',
            y: '27%',
            rotate: 'rotate(5deg)'
          }
        },
        {
          card_id: null,
          name: "Unrecognized Potential",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '33%',
            rotate: 'rotate(10deg)'
          }
        }
      ]
    },
    {
      id: "wane-gibb",
      title: "Waning Gibbous",
      image: "layout-line.svg",
      type: "moon",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Full Potential",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '5%'
          }
        },
        {
          card_id: null,
          name: "Fears",
          reversed: undefined,
          position: {
            x: 'calc(40% - 9vw)',
            y: '35%'
          }
        },
        {
          card_id: null,
          name: "Hidden Self",
          reversed: undefined,
          position: {
            x: 'calc(60% - 9vw)',
            y: '35%'
          }
        },
        {
          card_id: null,
          name: "Deception",
          reversed: undefined,
          position: {
            x: 'calc(40% - 9vw)',
            y: '65%'
          }
        },
        {
          card_id: null,
          name: "Regret",
          reversed: undefined,
          position: {
            x: 'calc(60% - 9vw)',
            y: '65%'
          }
        },
        {
          card_id: null,
          name: "Rejected Self",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '95%'
          }
        }
      ]
    },
    {
      id: "last-quarter",
      title: "Last Quarter",
      image: "layout-corners.svg",
      type: "moon",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Acceptance",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Forgiveness",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Guidance",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Lesson",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '90%'
          }
        }
      ]
    },
    {
      id: "wane-cres",
      title: "Waning Crescent",
      image: "layout-single.svg",
      type: "moon",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Cycle Learnings",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    // Begin Wheel of Year Spreads
    {
      id: "yule",
      title: "Yule (Winter Solstice)",
      image: "layout-9-box.svg",
      type: "year",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Rebirth",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Reflection",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '40%'
          }
        },
        {
          card_id: null,
          name: "To Contemplate",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Darkness",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '40%'
          }
        },
        {
          card_id: null,
          name: "To Illuminate",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Community",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '40%'
          }
        },
        {
          card_id: null,
          name: "To Gather",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Gift to Receive",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Gift to Give",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '100%'
          }
        }
      ]
    },
    {
      id: "imbolc",
      title: "Imbolc",
      image: "layout-7.svg",
      type: "year",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Hibernation",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "To Purify",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '45%'
          }
        },
        {
          card_id: null,
          name: "To Sacrifice",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '45%'
          }
        },
        {
          card_id: null,
          name: "Warmth",
          reversed: undefined,
          position: {
            x: 'calc(10% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Quickening",
          reversed: undefined,
          position: {
            x: 'calc(35% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Spark",
          reversed: undefined,
          position: {
            x: 'calc(65% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Blessing",
          reversed: undefined,
          position: {
            x: 'calc(90% - 9vw)',
            y: '90%'
          }
        }
      ]
    },
    {
      id: "ostara",
      title: "Ostara (Spring Equinox)",
      image: "layout-9-box.svg",
      type: "year",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Sun",
          reversed: undefined,
          position: {
            x: 'calc(40% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Rain",
          reversed: undefined,
          position: {
            x: 'calc(60% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Seed",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '45%'
          }
        },
        {
          card_id: null,
          name: "To Sow",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '80%'
          }
        },
        {
          card_id: null,
          name: "Blossom",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '45%'
          }
        },
        {
          card_id: null,
          name: "To Cultivate",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '80%'
          }
        },
        {
          card_id: null,
          name: "Decay",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '45%'
          }
        },
        {
          card_id: null,
          name: "To Shed",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '80%'
          }
        },
        {
          card_id: null,
          name: "Harvest",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '115%'
          }
        }
      ]
    },
    {
      id: "beltane",
      title: "Beltane",
      image: "layout-cross.svg",
      type: "year",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Divine Feminine",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Divine Masculine",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Union",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Fertility",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Manifestation",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '90%'
          }
        }
      ]
    },
    {
      id: "litha",
      title: "Litha (Summer Solstice)",
      image: "layout-7.svg",
      type: "year",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Vitality",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Passion",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "To Fuel",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Abundance",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "To Channel",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Gratitude",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "To Express",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '90%'
          }
        }
      ]
    },
    {
      id: "lammas",
      title: "Lammas",
      image: "layout-line.svg",
      type: "year",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Seeds Planted",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '80%'
          }
        },
        {
          card_id: null,
          name: "Roots Deepened",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '115%'
          }
        },
        {
          card_id: null,
          name: "Stems Extended",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '45%'
          }
        },
        {
          card_id: null,
          name: "Leaves Unfurled",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Harvest Anticipation",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Offerings",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      id: "mabon",
      title: "Mabon (Autumnal Equinox)",
      image: "layout-8.svg",
      type: "year",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Transition",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "To Navigate",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Harvest",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "To Reap",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Wisdom",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "To Integrate",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Preparations",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Rest",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '90%'
          }
        }
      ]
    },
    {
      id: "samhain",
      title: "Samhain",
      image: "layout-pyramid.svg",
      type: "year",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Haunting",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Protection",
          reversed: undefined,
          position: {
            x: 'calc(40% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Banishment",
          reversed: undefined,
          position: {
            x: 'calc(60% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Message from Ancestors",
          reversed: undefined,
          position: {
            x: 'calc(30% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Message from Shadow",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Message from Higher Self",
          reversed: undefined,
          position: {
            x: 'calc(70% - 9vw)',
            y: '90%'
          }
        }
      ]
    },
    {
      id: "major0",
      title: "The Fool",
      image: "layout-pyramid.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Adventure",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Position",
          reversed: undefined,
          position: {
            x: 'calc(35% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Risk",
          reversed: undefined,
          position: {
            x: 'calc(65% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Warning",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Foundation",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Helping Hand",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '90%'
          }
        }
      ]
    },
    {
      id: "major1",
      title: "The Magician",
      image: "layout-magician.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Inspiration",
          reversed: undefined,
          position: {
            x: 'calc(40% - 9vw)',
            y: '15%'
          }
        },
        {
          card_id: null,
          name: "Querent",
          reversed: undefined,
          position: {
            x: 'calc(60% - 9vw)',
            y: '15%'
          }
        },
        {
          card_id: null,
          name: "Wands",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Cups",
          reversed: undefined,
          position: {
            x: 'calc(40% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Swords",
          reversed: undefined,
          position: {
            x: 'calc(60% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Pentacles",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Manifestation",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '85%'
          }
        }
      ]
    },
    {
      id: "major2",
      title: "The High Priestess",
      image: "layout-staggered-box.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Mystery",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Avoidance",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '20%'
          }
        },
        {
          card_id: null,
          name: "Unconscious Fear",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Conscious Desire",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Work",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '20%'
          }
        },
        {
          card_id: null,
          name: "Higher Wisdom",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '60%'
          }
        }
      ]
    },
    {
      id: "major3",
      title: "The Empress",
      image: "layout-t.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Give",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Keep",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Nurture",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Pull Back",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '80%'
          }
        }
      ]
    },
    {
      id: "major4",
      title: "The Emperor",
      image: "layout-emperor.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Disorder",
          reversed: undefined,
          position: {
            x: 'calc(60% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "My Domain",
          reversed: undefined,
          position: {
            x: 'calc(40% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Outside Domain",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "To Discard",
          reversed: undefined,
          position: {
            x: 'calc(60% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "To Maintain",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "To Protect",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '70%'
          }
        }
      ]
    },
    {
      id: "major5",
      title: "The Hierophant",
      image: "layout-arrow-down.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Message",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '15%'
          }
        },
        {
          card_id: null,
          name: "Querent",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Earthly Action",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '85%'
          }
        },
        {
          card_id: null,
          name: "Belonging",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '65%'
          }
        },
        {
          card_id: null,
          name: "Guidance",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '65%'
          }
        }
      ]
    },
    {
      id: "major6",
      title: "The Lovers",
      image: "layout-lovers.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Underlying Issues",
          reversed: undefined,
          position: {
            x: 'calc(40% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Work",
          reversed: undefined,
          position: {
            x: 'calc(60% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Self Love",
          reversed: undefined,
          position: {
            x: 'calc(15% - 9vw)',
            y: '30%',
            rotate: 'rotate(-20deg)'
          }
        },
        {
          card_id: null,
          name: "Affection",
          reversed: undefined,
          position: {
            x: 'calc(35% - 9vw)',
            y: '10%',
            rotate: 'rotate(-10deg)'
          }
        },
        {
          card_id: null,
          name: "Familiar Love",
          reversed: undefined,
          position: {
            x: 'calc(65% - 9vw)',
            y: '10%',
            rotate: 'rotate(10deg)'
          }
        },
        {
          card_id: null,
          name: "Romantic Love",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '30%',
            rotate: 'rotate(20deg)'
          }
        },
        {
          card_id: null,
          name: "Enduring Love",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '70%',
            rotate: 'rotate(-20deg)'
          }
        },
        {
          card_id: null,
          name: "Playful Love",
          reversed: undefined,
          position: {
            x: 'calc(65% - 9vw)',
            y: '90%',
            rotate: 'rotate(-10deg)'
          }
        },
        {
          card_id: null,
          name: "Obsessive Love",
          reversed: undefined,
          position: {
            x: 'calc(35% - 9vw)',
            y: '90%',
            rotate: 'rotate(10deg)'
          }
        },
        {
          card_id: null,
          name: "Selfless Love",
          reversed: undefined,
          position: {
            x: 'calc(15% - 9vw)',
            y: '70%',
            rotate: 'rotate(20deg)'
          }
        }
      ]
    },
    {
      id: "major7",
      title: "The Chariot",
      image: "layout-chariot.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Charioteer",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Dark Horse",
          reversed: undefined,
          position: {
            x: 'calc(25% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Light Horse",
          reversed: undefined,
          position: {
            x: 'calc(75% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Next Step",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '42.5%'
          }
        },
        {
          card_id: null,
          name: "Obstacle",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '68.5%',
            rotate: 'rotate(90deg)'
          }
        },
        {
          card_id: null,
          name: "Destination",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '95%'
          }
        }
      ]
    },
    {
      id: "major8",
      title: "Strength",
      image: "layout-strength.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Self Perception",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Challenge",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '40%',
            rotate: 'rotate(90deg)'
          }
        },
        {
          card_id: null,
          name: "Skill",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '40%',
            rotate: 'rotate(-10deg)'
          }
        },
        {
          card_id: null,
          name: "Blessings",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '40%',
            rotate: 'rotate(10deg)'
          }
        },
        {
          card_id: null,
          name: "Wisdom",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '80%',
            rotate: 'rotate(10deg)'
          }
        },
        {
          card_id: null,
          name: "Potential",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '80%',
            rotate: 'rotate(-10deg)'
          }
        }
      ]
    },
    {
      id: "major9",
      title: "The Hermit",
      image: "layout-reverse-t.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Dark",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '40%'
          }
        },
        {
          card_id: null,
          name: "Persona",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '80%'
          }
        },
        {
          card_id: null,
          name: "True Self",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '80%'
          }
        },
        {
          card_id: null,
          name: "Guiding Light",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '80%'
          }
        }
      ]
    },
    {
      id: "major10",
      title: "The Wheel of Fortune",
      image: "layout-wheel.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Self",
          reversed: undefined,
          position: {
            x: 'calc(40% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Environment",
          reversed: undefined,
          position: {
            x: 'calc(60% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Winter",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Spring",
          reversed: undefined,
          position: {
            x: 'calc(15% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Summer",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Fall",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      id: "major11",
      title: "Justice",
      image: "layout-justice.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Your Needs",
          reversed: undefined,
          position: {
            x: 'calc(30% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Their Needs",
          reversed: undefined,
          position: {
            x: 'calc(70% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "My Path - Rewards",
          reversed: undefined,
          position: {
            x: 'calc(10% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "My Path - Consequences",
          reversed: undefined,
          position: {
            x: 'calc(10% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Their Path - Rewards",
          reversed: undefined,
          position: {
            x: 'calc(90% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Their Path - Consequences",
          reversed: undefined,
          position: {
            x: 'calc(90% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Balance",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Advice: Act",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Advice: Retreat",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '90%'
          }
        }
      ]
    },
    {
      id: "major12",
      title: "The Hanged Man",
      image: "layout-hanged.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Challenge",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '20%',
            rotate: 'rotate(90deg)'
          }
        },
        {
          card_id: null,
          name: "Feelings",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '20%'
          }
        },
        {
          card_id: null,
          name: "Origins",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '20%'
          }
        },
        {
          card_id: null,
          name: "Sacrifice",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Awakening",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '90%'
          }
        }
      ]
    },
    {
      id: "major13",
      title: "Death",
      image: "layout-trapezoid.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Death",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Before",
          reversed: undefined,
          position: {
            x: 'calc(30% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "After",
          reversed: undefined,
          position: {
            x: 'calc(70% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "To Grieve",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "To Remember",
          reversed: undefined,
          position: {
            x: 'calc(40% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "To Release",
          reversed: undefined,
          position: {
            x: 'calc(60% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "To Heal",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '70%'
          }
        }
      ]
    },
    {
      id: "major14",
      title: "Temperance",
      image: "layout-temperance.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Prima Materia 1",
          reversed: undefined,
          position: {
            x: 'calc(25% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Prima Materia 2",
          reversed: undefined,
          position: {
            x: 'calc(15% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Prima Materia 3",
          reversed: undefined,
          position: {
            x: 'calc(35% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Decomposition",
          reversed: undefined,
          position: {
            x: 'calc(65% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Purification",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Awakening",
          reversed: undefined,
          position: {
            x: 'calc(65% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Culmination",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '70%'
          }
        }
      ]
    },
    {
      id: "major15",
      title: "Devil",
      image: "layout-8.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Ego",
          reversed: undefined,
          position: {
            x: 'calc(15% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Shadow",
          reversed: undefined,
          position: {
            x: 'calc(15% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Comfort",
          reversed: undefined,
          position: {
            x: 'calc(37.5% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Avoidance",
          reversed: undefined,
          position: {
            x: 'calc(37.5% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Projection",
          reversed: undefined,
          position: {
            x: 'calc(62.5% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
            name: "Rejection",
          reversed: undefined,
          position: {
            x: 'calc(62.5% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Forgiveness",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Release",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '70%'
          }
        }
      ]
    },
    {
      id: "major16",
      title: "Tower",
      image: "layout-tower.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Rage",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '10%',
            rotate: 'rotate(-20deg)'
          }
        },
        {
          card_id: null,
          name: "Break 1",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '20%'
          }
        },
        {
          card_id: null,
          name: "Break 2",
          reversed: undefined,
          position: {
            x: 'calc(40% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "Break 3",
          reversed: undefined,
          position: {
            x: 'calc(60% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "Void",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "To Save",
          reversed: undefined,
          position: {
            x: 'calc(30% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "To Uncover",
          reversed: undefined,
          position: {
            x: 'calc(70% - 9vw)',
            y: '90%'
          }
        }
      ]
    },
    {
      id: "major17",
      title: "Star",
      image: "layout-star.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Wound",
          reversed: undefined,
          position: {
            x: 'calc(30% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Guidance",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Grounding",
          reversed: undefined,
          position: {
            x: 'calc(70% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Inspiration",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Blessing",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Wish",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      id: "major18",
      title: "Moon",
      image: "layout-moon.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Darkness",
          reversed: undefined,
          position: {
            x: 'calc(15% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Moon",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '20%'
          }
        },
        {
          card_id: null,
          name: "Reflection",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '60%'
          }
        },
        {
          card_id: null,
          name: "Dog",
          reversed: undefined,
          position: {
            x: 'calc(25% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Wolf",
          reversed: undefined,
          position: {
            x: 'calc(75% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Path",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '90%'
          }
        }
      ]
    },
    {
      id: "major19",
      title: "Sun",
      image: "layout-sun.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Abundance",
          reversed: undefined,
          position: {
            x: 'calc(15% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Sunlight",
          reversed: undefined,
          position: {
            x: 'calc(35% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Inner Light",
          reversed: undefined,
          position: {
            x: 'calc(35% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Outer Radiance",
          reversed: undefined,
          position: {
            x: 'calc(55% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Cloudiness",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      id: "major20",
      title: "Judgement",
      image: "layout-judgement.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Past Mistakes",
          reversed: undefined,
          position: {
            x: 'calc(10% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Past Successes",
          reversed: undefined,
          position: {
            x: 'calc(10% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Judgement",
          reversed: undefined,
          position: {
            x: 'calc(30% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "The Call",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Rebirth",
          reversed: undefined,
          position: {
            x: 'calc(70% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Result",
          reversed: undefined,
          position: {
            x: 'calc(90% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      id: "major21",
      title: "World",
      image: "layout-corner-center.svg",
      type: "major",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Self Fulfillment",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Taurus: Security",
          reversed: undefined,
          position: {
            x: 'calc(15% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Leo: Pleasure",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Scorpio: Passion",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Aquarius: Altruism",
          reversed: undefined,
          position: {
            x: 'calc(15% - 9vw)',
            y: '10%'
          }
        }
      ]
    },
    {
      id: "zodiac-inner",
      title: "Zodiac Spread - Inner World",
      image: "layout-zodiac.svg",
      type: "spiritual",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Aries",
          reversed: undefined,
          position: {
            x: 'calc(15% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Taurus",
          reversed: undefined,
          position: {
            x: 'calc(15% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Gemini",
          reversed: undefined,
          position: {
            x: 'calc(37.5% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Cancer",
          reversed: undefined,
          position: {
            x: 'calc(62.5% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Leo",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Virgo",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Libra",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '40%'
          }
        },
        {
          card_id: null,
          name: "Scorpio",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Sagittarius",
          reversed: undefined,
          position: {
            x: 'calc(62.5% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Capricorn",
          reversed: undefined,
          position: {
            x: 'calc(37.5% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Aquarius",
          reversed: undefined,
          position: {
            x: 'calc(15% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Pisces",
          reversed: undefined,
          position: {
            x: 'calc(15% - 9vw)',
            y: '40%'
          }
        }
      ]
    },
    {
      id: "zodiac-outer",
      title: "Zodiac Spread - Outer World",
      image: "layout-zodiac.svg",
      type: "general",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Aries",
          reversed: undefined,
          position: {
            x: 'calc(15% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Taurus",
          reversed: undefined,
          position: {
            x: 'calc(15% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Gemini",
          reversed: undefined,
          position: {
            x: 'calc(37.5% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Cancer",
          reversed: undefined,
          position: {
            x: 'calc(62.5% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Leo",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Virgo",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Libra",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '40%'
          }
        },
        {
          card_id: null,
          name: "Scorpio",
          reversed: undefined,
          position: {
            x: 'calc(85% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Sagittarius",
          reversed: undefined,
          position: {
            x: 'calc(62.5% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Capricorn",
          reversed: undefined,
          position: {
            x: 'calc(37.5% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Aquarius",
          reversed: undefined,
          position: {
            x: 'calc(15% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Pisces",
          reversed: undefined,
          position: {
            x: 'calc(15% - 9vw)',
            y: '40%'
          }
        }
      ]
    },
    {
      id: "weekly",
      title: "Week Ahead",
      image: "layout-pentagram.svg",
      type: "general",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Theme",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Challenges",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "Blessings",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "Self-Care",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "Release",
          reversed: undefined,
          position: {
            x: 'calc(35% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Focus",
          reversed: undefined,
          position: {
            x: 'calc(65% - 9vw)',
            y: '100%'
          }
        }
      ]
    },
    {
      id: "pair",
      title: "Pair Reading",
      type: "lenormand",
      image: "layout-2-horizontal.svg",
      interpretation: null,
      cards: [
        {
          card_id: null,
          name: "",
          position: {
            x: 'calc(40% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "",
          position: {
            x: 'calc(60% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      id: "three",
      title: "3 Card Reading",
      type: "lenormand",
      image: "layout-3-horizontal.svg",
      interpretation: null,
      cards: [
        {
          card_id: null,
          name: "",
          position: {
            x: 'calc(20% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "",
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "",
          position: {
            x: 'calc(80% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      id: "five",
      title: "5 Card Reading",
      type: "lenormand",
      image: "layout-5-horizontal.svg",
      interpretation: null,
      cards: [
        {
          card_id: null,
          name: "",
          position: {
            x: 'calc(15% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "",
          position: {
            x: 'calc(32.5% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "",
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "",
          position: {
            x: 'calc(67.5% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "",
          position: {
            x: 'calc(85% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      id: "nine",
      title: "9 Card Reading",
      type: "lenormand",
      image: "layout-9-box.svg",
      interpretation: null,
      sections: [
        { cards: [4] },
        { cards: [0,2,6,8] },
        { cards: [0,3,6] },
        { cards: [1,4,7] },
        { cards: [2,5,8] },
        { cards: [0,1,2] },
        { cards: [3,4,5] },
        { cards: [6,7,8] }
      ],
      cards: [
        {
          card_id: null,
          name: "",
          position: {
            x: 'calc(20% - 9vw)',
            y: '20%'
          }
        },
        {
          card_id: null,
          name: "",
          position: {
            x: 'calc(50% - 9vw)',
            y: '20%'
          }
        },
        {
          card_id: null,
          name: "",
          position: {
            x: 'calc(80% - 9vw)',
            y: '20%'
          }
        },
        {
          card_id: null,
          name: "",
          position: {
            x: 'calc(20% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "",
          position: {
            x: 'calc(50% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "",
          position: {
            x: 'calc(80% - 9vw)',
            y: '55%'
          }
        },
        {
          card_id: null,
          name: "",
          position: {
            x: 'calc(20% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "",
          position: {
            x: 'calc(50% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "",
          position: {
            x: 'calc(80% - 9vw)',
            y: '90%'
          }
        }
      ]
    },
    {
      id: "astro",
      title: "Astrology Oracle Reading",
      type: "astro",
      image: "layout-4.svg",
      interpretation: null,
      cards: [
        {
          card_id: null,
          name: "",
          reversed: undefined,
          position: {
            x: 'calc(30% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "",
          reversed: undefined,
          position: {
            x: 'calc(70% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "",
          reversed: undefined,
          position: {
            x: 'calc(30% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "",
          reversed: undefined,
          position: {
            x: 'calc(70% - 9vw)',
            y: '70%'
          }
        }
      ]
    },
    {
      id: "r1",
      title: "Single Rune",
      image: "layout-single.svg",
      type: "rune",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Your Answer",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      id: "rppf",
      title: "Past, Present, Future",
      image: "layout-3-horizontal.svg",
      type: "rune",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Past",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Present",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Future",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      id: "r4",
      title: "Four Rune Spread",
      image: "layout-4-horizontal.svg",
      type: "rune",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Situation",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Origin",
          reversed: undefined,
          position: {
            x: 'calc(40% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Direction",
          reversed: undefined,
          position: {
            x: 'calc(60% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Outcome",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      id: "r5",
      title: "Five Rune Spread",
      image: "layout-5-horizontal.svg",
      type: "rune",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Present",
          reversed: undefined,
          position: {
            x: 'calc(10% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Obstacle",
          reversed: undefined,
          position: {
            x: 'calc(30% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Goal",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Worry",
          reversed: undefined,
          position: {
            x: 'calc(70% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Result",
          reversed: undefined,
          position: {
            x: 'calc(90% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      id: "r4elements",
      title: "4 Elements",
      image: "layout-4.svg",
      type: "rune",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Earth",
          reversed: undefined,
          position: {
            x: 'calc(30% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Water",
          reversed: undefined,
          position: {
            x: 'calc(70% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Fire",
          reversed: undefined,
          position: {
            x: 'calc(70% - 9vw)',
            y: '70%'
          }
        },
        {
          card_id: null,
          name: "Air",
          reversed: undefined,
          position: {
            x: 'calc(30% - 9vw)',
            y: '70%'
          }
        },
      ]
    },
    {
      id: "rcross",
      title: "Cross",
      image: "layout-cross.svg",
      type: "rune",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Basis",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '90%'
          }
        },
        {
          card_id: null,
          name: "Obstacles",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Blessings",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '10%'
          }
        },
        {
          card_id: null,
          name: "Road Ahead",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '50%'
          }
        },
        {
          card_id: null,
          name: "Destination",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '50%'
          }
        }
      ]
    },
    {
      id: "rtree",
      title: "Great Tree",
      image: "layout-tree.svg",
      type: "rune",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Branch of the Heart",
          reversed: undefined,
          position: {
            x: 'calc(30% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Branch of the Mind",
          reversed: undefined,
          position: {
            x: 'calc(70% - 9vw)',
            y: '30%'
          }
        },
        {
          card_id: null,
          name: "Supporting Branches",
          reversed: undefined,
          position: {
            x: 'calc(30% - 9vw)',
            y: '65%'
          }
        },
        {
          card_id: null,
          name: "Future Growth",
          reversed: undefined,
          position: {
            x: 'calc(70% - 9vw)',
            y: '65%'
          }
        },
        {
          card_id: null,
          name: "Trunk",
          reversed: undefined,
          position: {
            x: 'calc(50% - 9vw)',
            y: '100%'
          }
        }
      ]
    },
    {
      id: "rcircle",
      title: "The Circle",
      image: "layout-circle.svg",
      type: "rune",
      question: null,
      notes: null,
      date: null,
      cards: [
        {
          card_id: null,
          name: "Issue",
          reversed: undefined,
          position: {
            x: 'calc(35% - 9vw)',
            y: '20%'
          }
        },
        {
          card_id: null,
          name: "Solution",
          reversed: undefined,
          position: {
            x: 'calc(65% - 9vw)',
            y: '20%'
          }
        },
        {
          card_id: null,
          name: "Next Step",
          reversed: undefined,
          position: {
            x: 'calc(20% - 9vw)',
            y: '60%'
          }
        },
        {
          card_id: null,
          name: "Character",
          reversed: undefined,
          position: {
            x: 'calc(80% - 9vw)',
            y: '60%'
          }
        },
        {
          card_id: null,
          name: "Time",
          reversed: undefined,
          position: {
            x: 'calc(35% - 9vw)',
            y: '100%'
          }
        },
        {
          card_id: null,
          name: "Union",
          reversed: undefined,
          position: {
            x: 'calc(65% - 9vw)',
            y: '100%'
          }
        }
      ]
    },
  ];
  ids: Array<string> = this.spreads.map((spread: any) => spread.id);

  async showToast(message: string, color: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'top',
      duration: 3000,
      color: color
    });
    toast.present();
  }

  getCategories() {
    var categories = [];
    for (let spread of this.spreads) {
      categories.push(spread.type);
    }
    categories.unshift('all');
    categories.unshift('custom');

    let unique = (categories) => categories.filter((v,i) => categories.indexOf(v) === i)
    return unique(categories);
  }

  getSpreads() {
    return this.spreads;
  }

  get(type: string) {
    return this[type];
  }

  getTemplateByNumber(number: number) {
    return this.layouts.filter((spread) => {
      return spread.number == number;
    })[0];
  }

  getSpreadsByCategory(category) {
    return this.spreads.filter((spread) => {
      return spread.type == category;
    });
  }

  getSpread(spreadId) {
    return this.spreads.filter((spread) => {
      return spread.id == spreadId;
    })[0];
  }

  getSpreadByTitle(title) {
    return this.spreads.filter((spread) => {
      return spread.title == title;
    })[0];
  }

  getPositionInSpread(spread, name) {
    return spread.filter((card) => {
      return card.name == name;
    })[0];
  }

  checkSpread(spreadId) {
    if (this.ids.indexOf(spreadId) > -1) {
      return true;
    } else {
      return false;
    }
  }

  cleanSpreadData(jsonData: any) {
    delete jsonData.date;
    delete jsonData.id;
    delete jsonData.app;
    delete jsonData.notes;
    delete jsonData.question;
    delete jsonData.type;
    delete jsonData.image;

    jsonData.cards.forEach(element => {
      delete element.card_id;
      delete element.reversed;
    });

    // turn cards to array
    var cards = jsonData.cards.map(element => {
      var position = Object.keys(element.position).map((key) => element.position[key]);
      return [element.name, element.description, position].flat();
    });
    jsonData.cards = cards;

    // sort keys -> cards, description, title is always the order.
    jsonData = Object.keys(jsonData).sort().reduce(
      (obj, key) => {
        obj[key] = jsonData[key];
        return obj;
      },
      {}
    );

    // turn to array
    jsonData = Object.keys(jsonData).map((key) => jsonData[key]);
    return jsonData;
  }

  getFavorites() {
    return this.favorites.map(id => this.getSpread(id));
  }

  removeFavorite(id: string) {
    if (this.favorites.includes(id)) {
      const index = this.favorites.indexOf(id);
      this.favorites = this.favorites.filter(Boolean);
      this.favorites.splice(index, 1);
      return this.userService.setUserData('favoriteSpreads', this.favorites, true);
    }
  }

  addFavorite(id: string) {
    if (!this.favorites.includes(id)) {
      if (this.favorites.length < 10) {
        this.favorites = this.favorites.filter(Boolean);
        this.favorites.push(id);
        return this.userService.setUserData('favoriteSpreads', this.favorites, true);
      } else {
        this.showToast('You can favorite a maximum of 10 spreads.', 'danger');
      }
    }
  }

  cleanSpreads(ids: Array<any>, customSpreads: Array<any>) {
    var broken = [];

    var ok = ids.map((id) => {
      if (id.includes('custom')) {
        var item = customSpreads.find((spread) => {
          return spread.id == id;
        });
        if (item) {
          return item;
        } else {
          broken.push(id);
        }
      } else {
        var spread = this.getSpread(id);
        if (spread) {
          return spread;
        } else {
          broken.push(id);
        }
      }
    }).filter(Boolean);

    if (broken.length > 0) {
      this.deleteBroken(broken);
    };

    return ok;
  }

  deleteBroken(ids: Array<any>) {
    ids.map((id) => {
      this.removeFavorite(id);
    });
  }

  getSubscription(name) {
    return this[name];
  }

  init() {
    this.userUpdates = this.userService.getSubscription('userSubscription').subscribe((data) => {
      this.favorites = data?.user_data?.favoriteSpreads ?? [];
    });
  }
}
