<ion-header>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center">My Account</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-segment value="form" mode="md" [(ngModel)]="form" color="tertiary" class="green">
    <ion-segment-button value="signup">
      <ion-label>{{'auth.signup' | translate}}</ion-label>
    </ion-segment-button>
    <ion-segment-button value="login">
      <ion-label>{{'auth.login' | translate}}</ion-label>
    </ion-segment-button>
  </ion-segment>
</ion-header>

<ion-content color="light">
  <div class="ion-margin ion-text-center" *ngIf="form == 'signup'">
    <h1>{{'auth.modal_title' | translate}}</h1>
  </div>
  <ion-grid class="ion-text-center">
    <ion-row>
      <ion-col>
        <img src="assets/benefits1.png"/>
        <ion-label [innerHTML]="'auth.benefit_1' | translate"></ion-label>
      </ion-col>
      <ion-col>
        <img src="assets/benefits2.png"/>
        <ion-label [innerHTML]="'auth.benefit_2' | translate"></ion-label>
      </ion-col>
      <ion-col>
        <img src="assets/benefits3.png"/>
        <ion-label [innerHTML]="'auth.benefit_3' | translate"></ion-label>
      </ion-col>
    </ion-row>
  </ion-grid>

  <form class="forms-wrapper" *ngIf="form == 'signup'">
    <ion-item mode="md" fill="outline" tabindex="1">
      <ion-label>
        <ion-icon name="person-outline" size="large" color="tertiary"></ion-icon>
      </ion-label>
      <ion-input type="text" required [(ngModel)]="name" placeholder="{{ 'auth.name' | translate }}" [clearInput]="true" name="name"></ion-input>
    </ion-item>

    <ion-item mode="md" fill="outline" tabindex="2">
      <ion-label>
        <ion-icon name="mail-outline" size="large" color="tertiary"></ion-icon>
      </ion-label>
      <ion-input type="email" required [(ngModel)]="email" placeholder="{{ 'auth.email' | translate }}" [clearInput]="true" name="email"></ion-input>
    </ion-item>

    <ion-item mode="md" fill="outline" tabindex="3">
      <ion-label>
        <ion-icon name="lock-closed-outline" size="large" color="tertiary"></ion-icon>
      </ion-label>
      <ion-input type="password" required placeholder="{{ 'auth.password' | translate }}" [(ngModel)]="password" [clearInput]="true" name="password"></ion-input>
    </ion-item>

    <ion-item mode="md" lines="none" color="light">
      <ion-label class="checkbox-label ion-text-wrap">
        <strong>Subscribe to Newsletter?</strong><br>
        Get notified of our tarot deck giveaways, sales, and tips on making the most of your app experience.
      </ion-label>
      <ion-checkbox color="tertiary" slot="start" [(ngModel)]="subscribe" name="subscribe"></ion-checkbox>
    </ion-item>
  </form>

  <form class="forms-wrapper" *ngIf="form == 'login'">
    <ion-item mode="md" fill="outline">
      <ion-label>
        <ion-icon name="mail-outline" size="large" color="tertiary"></ion-icon>
      </ion-label>
      <ion-input type="email" required [(ngModel)]="email" placeholder="{{ 'auth.email' | translate }}" [clearInput]="true" name="email"></ion-input>
    </ion-item>

    <ion-item mode="md" fill="outline">
      <ion-label>
        <ion-icon name="lock-closed-outline" size="large" color="tertiary"></ion-icon>
      </ion-label>
      <ion-input type="password" required placeholder="{{ 'auth.password' | translate }}" [(ngModel)]="password" [clearInput]="true" name="password"></ion-input>
    </ion-item>

    <ion-button fill="clear" (click)="forgotPassword()" size="small" color="tertiary" expand="block" class="ion-margin-top">{{ 'auth.forgot' | translate }}</ion-button>
  </form>
</ion-content>

<ion-footer mode="md" class="animated slideInUp">
  <ion-item mode="md" lines="full" color="light" class="terms">
    <ion-label class="ion-text-wrap ion-text-center">
      By creating an account or using our app, you agree to our <a (click)="openURL('https://labyrinthos.co/pages/terms-of-service')">Terms of Service</a> and <a (click)="openURL('https://labyrinthos.co/pages/privacy-policy')">Privacy Policy</a>.
    </ion-label>
  </ion-item>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center">
      <ion-button [disabled]="loading || !email || !password || !name" (click)="signup()" *ngIf="form == 'signup'">
        <ion-spinner name="crescent" *ngIf="loading" style="margin-right: 15px"></ion-spinner>
        <ion-label>
          Sign Up
        </ion-label>
      </ion-button>

      <ion-button [disabled]="loading || !email || !password" (click)="login()" *ngIf="form == 'login'">
        <ion-spinner name="crescent" *ngIf="loading" style="margin-right: 15px"></ion-spinner>
        <ion-label>
          Log In
        </ion-label>
      </ion-button>
    </ion-title>
  </ion-toolbar>
</ion-footer>
