<div class="message-wrap">
  <div *ngFor="let message of transcript" class="message" [class.right]=" message.role != reader">
    <div class="msg-detail">
      <div class="msg-info">
        <p>{{message.role}}</p>
      </div>
      <div class="msg-content">
        <p>{{message.content}}</p>
      </div>
    </div>
  </div>
</div>
