import { Injectable } from '@angular/core';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})

export class TarotService {
  constructor(
    private languageService: LanguageService,
  ) { }

  lastIndex: number = 77;

  // major arcana - wands > pentacles (numbers > page > knight > queen > king)
  // all extra cards MUST be put at the end of the array
  decks: any = {
    "GTT": {
      "reversal": true,
      "translate_global": "GTT",
      "translate_en": "GTT"
    },
    "LST": {
      "reversal": true,
      "translate_global": "GTT",
      "translate_en": "LST"
    },
    "SST": {
      "reversal": false,
      "translate_global": "GTT",
      "translate_en": "SST",
      "switch": true,
      "extra": [
        {
          "image": "major-24.png",
          "type": "major",
          "number": null,
          "search": "the return major",
          "yn": false,
          "element": "water",
          "translateVariable": "tarot.SST.major-24.png"
        },
        {
          "image": "major-25.png",
          "type": "major",
          "number": null,
          "search": "tree of life major",
          "yn": false,
          "element": "earth",
          "translateVariable": "tarot.SST.major-25.png"
        }
      ]
    },
    "SSTRWS": {
      "reversal": true,
      "translate_global": "GTT",
      "translate_en": "LST",
      "extra": [
        {
          "image": "major-24.png",
          "type": "major",
          "number": null,
          "search": "the return major",
          "yn": false,
          "element": "water",
          "translateVariable": "tarot.SST.major-24.png"
        },
        {
          "image": "major-25.png",
          "type": "major",
          "number": null,
          "search": "tree of life major",
          "yn": false,
          "element": "earth",
          "translateVariable": "tarot.SST.major-25.png"
        }
      ]
    },
    "AIS": {
      "reversal": false,
      "translate_global": "GTT",
      "translate_en": "AIS",
      "switch": true,
    },
    "TVM": {
      "reversal": true,
      "translate_global": "GTT",
      "translate_en": "LST"
    },
    "RWS1910": {
      "reversal": true,
      "translate_global": "GTT",
      "translate_en": "LST"
    },
    "LS_SMT": {
      "reversal": true,
      "translate_global": "GTT",
      "translate_en": "LST"
    },
    "LS_MCT": {
      "reversal": false,
      "translate_global": "GTT",
      "translate_en": "SST"
    },
    "LS_1001": {
      "reversal": true,
      "translate_global": "GTT",
      "translate_en": "LST"
    },
    "LS_TM": {
      "reversal": true,
      "translate_global": "GTT",
      "translate_en": "LST"
    },
    "LS_VT": {
      "reversal": true,
      "translate_global": "GTT",
      "translate_en": "LST"
    },
    "LS_TB": {
      "reversal": true,
      "translate_global": "GTT",
      "translate_en": "LST"
    },
  };

  ranges: any = [
    {
      id: 'major',
      range: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21]
    },
    {
      id: 'wands',
      range: [22,23,24,25,26,27,28,29,30,31,32,33,34,35]
    },
    {
      id: 'cups',
      range: [36,37,38,39,40,41,42,43,44,45,46,47,48,49]
    },
    {
      id: 'swords',
      range: [50,51,52,53,54,55,56,57,58,59,60,61,62,63]
    },
    {
      id: 'pentacles',
      range: [64,65,66,67,68,69,70,71,72,73,74,75,76,77]
    },
    {
      id: 'court',
      range: [32,33,34,35,46,47,48,49,60,61,62,63,74,75,76,77]
    },
    {
      id: 'numbered',
      range: [22,23,24,25,26,27,28,29,30,31,36,37,38,39,40,41,42,43,44,45,50,51,52,53,54,55,56,57,58,59,64,65,66,67,68,69,70,71,72,73]
    }
  ];

  tarot: any = [
    {
      image: "major-02.png",
      type: "major",
      element: "air",
      yn: true,
      number: 0,
      search: "the fool major",
      astro: "card__astrology-oracle_planet-uranus.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/the-fool-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "major-03.png",
      type: "major",
      element: "air",
      yn: true,
      number: 1,
      search: "the magician major",
      astro: "card__astrology-oracle_planet-mercury.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/the-magician-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "major-04.png",
      type: "major",
      element: "water",
      yn: undefined,
      number: 2,
      search: "the high priestess popess major",
      astro: "card__astrology-oracle_planet-moon.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/the-high-priestess-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "major-05.png",
      type: "major",
      element: "earth",
      yn: true,
      number: 3,
      search: "the empress major",
      astro: "card__astrology-oracle_planet-venus.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/the-empress-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "major-06.png",
      type: "major",
      element: "fire",
      yn: undefined,
      number: 4,
      search: "the emperor major",
      astro: "card__astrology-oracle_zodiac-aries.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/the-emperor-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "major-07.png",
      type: "major",
      element: "earth",
      yn: undefined,
      number: 5,
      search: "the hierophant pope major",
      astro: "card__astrology-oracle_zodiac-taurus.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/the-hierophant-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "major-08.png",
      type: "major",
      element: "air",
      yn: true,
      number: 6,
      search: "the lovers major",
      astro: "card__astrology-oracle_zodiac-gemini.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/the-lovers-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "major-09.png",
      type: "major",
      element: "water",
      yn: true,
      number: 7,
      search: "the chariot major",
      astro: "card__astrology-oracle_zodiac-cancer.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/the-chariot-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "major-10.png",
      type: "major",
      element: "fire",
      yn: true,
      number: 8,
      search: "strength major",
      astro: "card__astrology-oracle_zodiac-leo.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/strength-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "major-11.png",
      type: "major",
      element: "earth",
      yn: undefined,
      number: 9,
      search: "hermit major",
      astro: "card__astrology-oracle_zodiac-virgo.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/the-hermit-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "major-12.png",
      type: "major",
      element: "fire",
      yn: true,
      number: 10,
      search: "the wheel of fortune major",
      astro: "card__astrology-oracle_planet-jupiter.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/the-wheel-of-fortune-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "major-13.png",
      type: "major",
      element: "air",
      yn: undefined,
      number: 11,
      search: "justice major",
      astro: "card__astrology-oracle_zodiac-libra.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/justice-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "major-14.png",
      type: "major",
      element: "water",
      yn: undefined,
      number: 12,
      search: "the hanged man major",
      astro: "card__astrology-oracle_planet-neptune.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/the-hanged-man-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "major-15.png",
      type: "major",
      element: "water",
      yn: false,
      number: 13,
      search: "death no name major",
      astro: "card__astrology-oracle_zodiac-scorpio.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/death-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "major-16.png",
      type: "major",
      element: "fire",
      yn: true,
      number: 14,
      search: "temperance major",
      astro: "card__astrology-oracle_zodiac-sagittarius.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/temperance-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "major-17.png",
      type: "major",
      element: "earth",
      yn: false,
      number: 15,
      search: "the devil major",
      astro: "card__astrology-oracle_zodiac-capricorn.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/the-devil-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "major-18.png",
      type: "major",
      element: "fire",
      yn: false,
      number: 16,
      search: "the tower major",
      astro: "card__astrology-oracle_planet-mars.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/the-tower-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "major-19.png",
      type: "major",
      element: "air",
      yn: true,
      number: 17,
      search: "the star major",
      astro: "card__astrology-oracle_zodiac-aquarius.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/the-star-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "major-20.png",
      type: "major",
      element: "water",
      yn: false,
      number: 18,
      search: "the moon major",
      astro: "card__astrology-oracle_zodiac-pisces.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/the-moon-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "major-21.png",
      type: "major",
      element: "fire",
      yn: true,
      number: 19,
      search: "the sun major",
      astro: "card__astrology-oracle_planet-sun.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/the-sun-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "major-22.png",
      type: "major",
      element: "fire",
      yn: true,
      number: 20,
      search: "judgement major",
      astro: "card__astrology-oracle_planet-pluto.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/judgement-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "major-23.png",
      type: "major",
      element: "earth",
      yn: true,
      number: 21,
      search: "the world major",
      astro: "card__astrology-oracle_planet-saturn.png",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/the-world-meaning-major-arcana-tarot-card-meanings"
    },
    {
      image: "wands-31.png",
      type: "wands",
      element: "fire",
      yn: true,
      number: 0,
      search: "1 one ace of wands numbered",
      astro: "All Fire Zodiac Signs",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/ace-of-wands-meaning-tarot-card-meanings"
    },
    {
      image: "wands-30.png",
      type: "wands",
      element: "fire",
      yn: true,
      number: 1,
      search: "2 two ii of wands numbered",
      astro: "Decan 1: Mars in Aries",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/two-of-wands-meaning-tarot-card-meanings"
    },
    {
      image: "wands-29.png",
      type: "wands",
      element: "fire",
      yn: true,
      number: 2,
      search: "3 three iii of wands numbered",
      astro: "Decan 2: Sun in Aries",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/three-of-wands-meaning-tarot-card-meanings"
    },
    {
      image: "wands-28.png",
      type: "wands",
      element: "fire",
      yn: true,
      number: 3,
      search: "4 four iv of wands numbered",
      astro: "Decan 3: Venus in Aries",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/four-of-wands-meaning-tarot-card-meanings"
    },
    {
      image: "wands-32.png",
      type: "wands",
      element: "fire",
      yn: false,
      number: 4,
      search: "5 five v of wands numbered",
      astro: "Decan 1: Saturn in Leo",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/five-of-wands-meaning-tarot-card-meanings"
    },
    {
      image: "wands-33.png",
      type: "wands",
      element: "fire",
      yn: true,
      number: 5,
      search: "6 six vi of wands numbered",
      astro: "Decan 2: Jupiter in Leo",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/six-of-wands-meaning-tarot-card-meanings"
    },
    {
      image: "wands-34.png",
      type: "wands",
      element: "fire",
      yn: false,
      number: 6,
      search: "7 seven vii of wands numbered",
      astro: "Decan 3: Mars in Leo",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/seven-of-wands-meaning-tarot-card-meanings"
    },
    {
      image: "wands-35.png",
      type: "wands",
      element: "fire",
      yn: true,
      number: 7,
      search: "8 eight viii of wands numbered",
      astro: "Decan 1: Mercury in Sagittarius",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/eight-of-wands-meaning-tarot-card-meanings"
    },
    {
      image: "wands-36.png",
      type: "wands",
      element: "fire",
      yn: true,
      number: 8,
      search: "9 nine ix of wands numbered",
      astro: "Decan 2: Moon in Sagittarius",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/nine-of-wands-meaning-tarot-card-meanings"
    },
    {
      image: "wands-37.png",
      type: "wands",
      element: "fire",
      yn: false,
      number: 9,
      search: "10 ten x of wands numbered",
      astro: "Decan 3: Saturn in Sagittarius",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/ten-of-wands-meaning-tarot-card-meanings"
    },
    {
      image: "wands-24.png",
      type: "wands",
      element: "fire",
      yn: true,
      number: 10,
      search: "page of wands court numbered",
      astro: "2nd Quadrant: Cancer, Leo, Virgo",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/page-of-wands-meaning-tarot-card-meanings"
    },
    {
      image: "wands-25.png",
      type: "wands",
      element: "fire",
      yn: true,
      number: 11,
      search: "knight of wands court",
      astro: "20° Cancer – 20° Leo",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/knight-of-wands-meaning-tarot-card-meanings"
    },
    {
      image: "wands-26.png",
      type: "wands",
      element: "fire",
      yn: true,
      number: 12,
      search: "queen of wands court",
      astro: "20° Pisces – 20° Aries",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/queen-of-wands-meaning-tarot-card-meanings"
    },
    {
      image: "wands-27.png",
      type: "wands",
      element: "fire",
      yn: true,
      number: 13,
      search: "king of wands court",
      astro: "20° Scorpio – 20° Sagittarius",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/king-of-wands-meaning-tarot-card-meanings"
    },
    {
      image: "cups-42.png",
      type: "cups",
      element: "water",
      yn: true,
      number: 0,
      search: "1 one ace of cups numbered",
      astro: "All Water Zodiac Signs",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/ace-of-cups-meaning-tarot-card-meanings"
    },
    {
      image: "cups-43.png",
      type: "cups",
      element: "water",
      yn: true,
      number: 1,
      search: "2 two ii of cups numbered",
      astro: "Decan 1: Venus in Cancer",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/two-of-cups-meaning-tarot-card-meanings"
    },
    {
      image: "cups-44.png",
      type: "cups",
      element: "water",
      yn: true,
      number: 2,
      search: "3 three iii of cups numbered",
      astro: "Decan 2: Mercury in Cancer",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/three-of-cups-meaning-tarot-card-meanings"
    },
    {
      image: "cups-45.png",
      type: "cups",
      element: "water",
      yn: undefined,
      number: 3,
      search: "4 four iv of cups numbered",
      astro: "Decan 3: Moon in Cancer",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/four-of-cups-meaning-tarot-card-meanings"
    },
    {
      image: "cups-46.png",
      type: "cups",
      element: "water",
      yn: false,
      number: 4,
      search: "5 five v of cups numbered",
      astro: "Decan 1: Mars in Scorpio",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/five-of-cups-meaning-tarot-card-meanings"
    },
    {
      image: "cups-47.png",
      type: "cups",
      element: "water",
      yn: true,
      number: 5,
      search: "6 six vi of cups numbered",
      astro: "Decan 2: Sun in Scorpio",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/six-of-cups-meaning-tarot-card-meanings"
    },
    {
      image: "cups-48.png",
      type: "cups",
      element: "water",
      yn: false,
      number: 6,
      search: "7 seven vii of cups numbered",
      astro: "Decan 3: Venus in Scorpio",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/seven-of-cups-meaning-tarot-card-meanings"
    },
    {
      image: "cups-49.png",
      type: "cups",
      element: "water",
      yn: undefined,
      number: 7,
      search: "8 eight viii of cups numbered",
      astro: "Decan 1: Saturn in Pisces",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/eight-of-cups-meaning-tarot-card-meanings"
    },
    {
      image: "cups-50.png",
      type: "cups",
      element: "water",
      yn: true,
      number: 8,
      search: "9 nine ix of cups numbered",
      astro: "Decan 2: Jupiter in Pisces",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/nine-of-cups-meaning-tarot-card-meanings"
    },
    {
      image: "cups-51.png",
      type: "cups",
      element: "water",
      yn: true,
      number: 9,
      search: "10 ten x of cups numbered",
      astro: "Decan 3: Mars in Pisces",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/ten-of-cups-meaning-tarot-card-meanings"
    },
    {
      image: "cups-38.png",
      type: "cups",
      element: "water",
      yn: true,
      number: 10,
      search: "page of cups court",
      astro: "3rd Quadrant: Libra, Scorpio, Sagittarius",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/page-of-cups-meaning-tarot-card-meanings"
    },
    {
      image: "cups-39.png",
      type: "cups",
      element: "water",
      yn: true,
      number: 11,
      search: "knight of cups court",
      astro: "20° Libra – 20° Scorpio",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/knight-of-cups-meaning-tarot-card-meanings"
    },
    {
      image: "cups-40.png",
      type: "cups",
      element: "water",
      yn: true,
      number: 12,
      search: "queen of cups court",
      astro: "20° Gemini – 20° Cancer",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/queen-of-cups-meaning-tarot-card-meanings"
    },
    {
      image: "cups-41.png",
      type: "cups",
      element: "water",
      yn: true,
      number: 13,
      search: "king of cups court",
      astro: "20° Aquarius – 20 ° Pisces",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/king-of-cups-meaning-tarot-card-meanings"
    },
    {
      image: "swords-59.png",
      type: "swords",
      element: "air",
      yn: true,
      number: 0,
      search: "1 one ace of swords numbered",
      astro: "All Air Zodiac Signs",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/ace-of-swords-meaning-tarot-card-meanings"
    },
    {
      image: "swords-58.png",
      type: "swords",
      element: "air",
      yn: undefined,
      number: 1,
      search: "2 two ii of swords numbered",
      astro: "Decan 1: Moon in Libra",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/two-of-swords-meaning-tarot-card-meanings"
    },
    {
      image: "swords-57.png",
      type: "swords",
      element: "air",
      yn: false,
      number: 2,
      search: "3 three iii of swords numbered",
      astro: "Decan 2: Saturn in Libra",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/three-of-swords-meaning-tarot-card-meanings"
    },
    {
      image: "swords-56.png",
      type: "swords",
      element: "air",
      yn: undefined,
      number: 3,
      search: "4 four iv of swords numbered",
      astro: "Decan 3: Jupiter in Libra",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/four-of-swords-meaning-tarot-card-meanings"
    },
    {
      image: "swords-60.png",
      type: "swords",
      element: "air",
      yn: false,
      number: 4,
      search: "5 five v of swords numbered",
      astro: "Decan 1: Venus in Aquarius",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/five-of-swords-meaning-tarot-card-meanings"
    },
    {
      image: "swords-61.png",
      type: "swords",
      element: "air",
      yn: true,
      number: 5,
      search: "6 six vi of swords numbered",
      astro: "Decan 2: Mercury in Aquarius",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/six-of-swords-meaning-tarot-card-meanings"
    },
    {
      image: "swords-62.png",
      type: "swords",
      element: "air",
      yn: false,
      number: 6,
      search: "7 seven vii of swords numbered",
      astro: "Decan 3: Moon in Aquarius",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/seven-of-swords-meaning-tarot-card-meanings"
    },
    {
      image: "swords-63.png",
      type: "swords",
      element: "air",
      yn: false,
      number: 7,
      search: "8 eight viii of swords numbered",
      astro: "Decan 1: Jupiter in Gemini",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/eight-of-swords-meaning-tarot-card-meanings"
    },
    {
      image: "swords-64.png",
      type: "swords",
      element: "air",
      yn: false,
      number: 8,
      search: "9 nine ix of swords numbered",
      astro: "Decan 2: Mars in Gemini",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/nine-of-swords-meaning-tarot-card-meanings"
    },
    {
      image: "swords-65.png",
      type: "swords",
      element: "air",
      yn: false,
      number: 9,
      search: "10 ten x of swords numbered",
      astro: "Decan 3: Sun in Gemini",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/ten-of-swords-meaning-tarot-card-meanings"
    },
    {
      image: "swords-52.png",
      type: "swords",
      element: "air",
      yn: true,
      number: 10,
      search: "page of swords court",
      astro: "4th Quadrant: Capricorn, Aquarius, Pisces",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/page-of-swords-meaning-tarot-card-meanings"
    },
    {
      image: "swords-53.png",
      type: "swords",
      element: "air",
      yn: true,
      number: 11,
      search: "knight of swords court",
      astro: "20° Capricorn – 20 ° Aquarius",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/knight-of-swords-meaning-tarot-card-meanings"
    },
    {
      image: "swords-54.png",
      type: "swords",
      element: "air",
      yn: true,
      number: 12,
      search: "queen of swords court",
      astro: "20° Virgo – 20° Libra",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/queen-of-swords-meaning-tarot-card-meanings"
    },
    {
      image: "swords-55.png",
      type: "swords",
      element: "air",
      yn: true,
      number: 13,
      search: "king of swords court",
      astro: "20° Taurus – 20° Gemini",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/king-of-swords-meaning-tarot-card-meanings"
    },
    {
      image: "pentacles-70.png",
      type: "pentacles",
      element: "earth",
      yn: true,
      number: 0,
      search: "1 one ace of pentacles disks numbered",
      astro: "All Earth Zodiac Signs",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/ace-of-pentacles-meaning-tarot-card-meanings"
    },
    {
      image: "pentacles-71.png",
      type: "pentacles",
      element: "earth",
      yn: undefined,
      number: 1,
      search: "2 two ii of pentacles disks numbered",
      astro: "Decan 1: Jupiter in Capricorn",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/two-of-pentacles-meaning-tarot-card-meanings"
    },
    {
      image: "pentacles-72.png",
      type: "pentacles",
      element: "earth",
      yn: true,
      number: 2,
      search: "3 three iii of pentacles disks numbered",
      astro: "Decan 2: Mars in Capricorn",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/three-of-pentacles-meaning-tarot-card-meanings"
    },
    {
      image: "pentacles-73.png",
      type: "pentacles",
      element: "earth",
      yn: false,
      number: 3,
      search: "4 four iv of pentacles disks numbered",
      astro: "Decan 3: Sun in Capricorn",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/four-of-pentacles-meaning-tarot-card-meanings"
    },
    {
      image: "pentacles-74.png",
      type: "pentacles",
      element: "earth",
      yn: false,
      number: 4,
      search: "5 five v of pentacles disks numbered",
      astro: "Decan 1: Mercury in Taurus",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/five-of-pentacles-meaning-tarot-card-meanings"
    },
    {
      image: "pentacles-75.png",
      type: "pentacles",
      element: "earth",
      yn: true,
      number: 5,
      search: "6 six vi of pentacles disks numbered",
      astro: "Decan 2: Moon in Taurus",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/six-of-pentacles-meaning-tarot-card-meanings"
    },
    {
      image: "pentacles-76.png",
      type: "pentacles",
      element: "earth",
      yn: undefined,
      number: 6,
      search: "7 seven vii of pentacles disks numbered",
      astro: "Decan 3: Saturn in Taurus",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/seven-of-pentacles-meaning-tarot-card-meanings"
    },
    {
      image: "pentacles-77.png",
      type: "pentacles",
      element: "earth",
      yn: true,
      number: 7,
      search: "8 eight viii of pentacles disks numbered",
      astro: "Decan 1: Sun in Virgo",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/eight-of-pentacles-meaning-tarot-card-meanings"
    },
    {
      image: "pentacles-78.png",
      type: "pentacles",
      element: "earth",
      yn: true,
      number: 8,
      search: "9 nine ix of pentacles disks numbered",
      astro: "Decan 2: Venus in Virgo",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/nine-of-pentacles-meaning-tarot-card-meanings"
    },
    {
      image: "pentacles-79.png",
      type: "pentacles",
      element: "earth",
      yn: true,
      number: 9,
      search: "10 ten x of pentacles disks numbered",
      astro: "Decan 3: Mercury in Virgo",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/ten-of-pentacles-meaning-tarot-card-meanings"
    },
    {
      image: "pentacles-66.png",
      type: "pentacles",
      element: "earth",
      yn: true,
      number: 10,
      search: "page of pentacles disks court",
      astro: "1st Quadrant: Aries, Taurus, Gemini",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/page-of-pentacles-meaning-tarot-card-meanings"
    },
    {
      image: "pentacles-67.png",
      type: "pentacles",
      element: "earth",
      yn: true,
      number: 11,
      search: "knight of pentacles disks court",
      astro: "20° Aries – 20° Taurus",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/knight-of-pentacles-meaning-tarot-card-meanings"
    },
    {
      image: "pentacles-68.png",
      type: "pentacles",
      element: "earth",
      yn: true,
      number: 12,
      search: "queen of pentacles disks court",
      astro: "20° Sagittarius – 20° Capricorn",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/queen-of-pentacles-meaning-tarot-card-meanings"
    },
    {
      image: "pentacles-69.png",
      type: "pentacles",
      element: "earth",
      yn: true,
      number: 13,
      search: "king of pentacles disks court",
      astro: "20° Leo – 20° Virgo",
      url: "https://labyrinthos.co/blogs/tarot-card-meanings-list/king-of-pentacles-meaning-tarot-card-meanings"
    }
  ];

  getTarot() {
    return this.tarot;
  }

  swapElements(arr, x, y) {
    [arr[x], arr[y]] = [arr[y], arr[x]];
    return arr;
  }

  getTarotByDeck(deckId: string) {
    var copy = undefined;
    copy = JSON.parse(JSON.stringify(this.tarot));
    if (this.decks[deckId]?.extra) {
      copy = this.tarot.concat(this.decks[deckId]?.extra);
    };
    if (this.decks[deckId].switch) {
      var indexStrength: number = this.getCardIndex('major-10.png');
      var indexJustice: number = this.getCardIndex('major-13.png');
      copy = this.swapElements(copy, indexStrength, indexJustice);
    };
    return copy;
  }

  getAllCardProperties(property: string) {
    return this.tarot.map((card) => card[property]);
  }

  filterCards(field, searchText) {
    return this.tarot.filter((card) => {
      return card[field].toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    });
  }

  filterCardsByNum(number) {
    return this.tarot.filter((card) => {
      return card.number == number;
    });
  }

  getCard(cardId) {
    return this.tarot.filter((card) => {
      return card.image == cardId;
    });
  }

  getCardByDeck(cardId: string, deckId: string) {
    var standard = this.tarot.filter((card) => {
      return card.image == cardId;
    });

    if (standard.length == 0) {
      return this.decks[deckId].extra.filter((card) => {
        return card.image == cardId;
      });
    } else {
      return standard;
    }
  }

  getCardByIndex(index: number, deckId: string) {
    if (this.tarot[index]) {
      return this.tarot[index];
    } else {
      return this.decks[deckId].extra[0];
    }
  }

  getCardsByIndex(indexArray) {
    var cards = [];
    for (let i of indexArray) {
      cards.push(this.tarot[i])
    }
    return cards;
  }

  getRandomInt(excluded, maxIndex) {
    // return Math.floor(Math.random()*(max-min+1)+min);
    var result = 0;
    var min = 0;
    var max = maxIndex - 1;
    var index = Math.floor(Math.random()*(max-min+1)+min);

    while(excluded.indexOf(index) > -1) {
      if (index < max) {
        index++;
      } else {
        index = 0;
      }
    }
    return index;
  }

  getRandomCard(excluded) {
    var index = this.getRandomInt(excluded, 78);
    return this.tarot[index];
  }

  getCardArraysforSubset(subset: Array<string>) {
    var subsetIndexes = this.ranges.map((type) => {
      if (subset.indexOf(type.id) > -1) {
        return type.range;
      }
    });

    subsetIndexes = subsetIndexes.filter(Boolean); // remove undefined or empty items
    subsetIndexes = Array.prototype.concat(...subsetIndexes); // combine arrays
    subsetIndexes = Array.from(new Set(subsetIndexes)); // remove duplicates

    return subsetIndexes;
  }

  getRandomCardInRange(subset: Array<any>, excluded?: Array<any>) {
    if (excluded) {
      subset = subset.filter((n) => { // remove excluded
        return excluded.indexOf(n) < 0;
      });
    };

    var index = subset[Math.floor(Math.random()*subset.length)];
    return this.tarot[index];
  }

  getCardIndex(cardId) {
    return this.tarot.findIndex(card => {
      return card.image == cardId;
    })
  }

  getMissingCards(cardIds: Array<string>) {
    var allCards = JSON.parse(JSON.stringify(this.tarot.map(c => c.image)));
    let difference = allCards.filter(x => !cardIds.includes(x));
    return difference;
  }

  getDeckTranslateVariable(deckId: string) {
    var language = this.languageService.selected;
    var deckInfo = this.decks[deckId];

    if (deckInfo) {
      var translateKey: string;
      if (language == 'en' || language == undefined) {
        translateKey = 'translate_en';
      } else {
        translateKey = 'translate_global';
      }
      return 'tarot.' + deckInfo[translateKey];
    } else {
      return undefined;
    }
  }
}
