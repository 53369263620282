import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UserService } from '../../services/user.service';
import { VoiceService } from '../../services/voice.service';
import { ModalController } from '@ionic/angular';
import { NativeAudio } from '@capacitor-community/native-audio';
import { Capacitor } from '@capacitor/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-call',
  templateUrl: './call.page.html',
  styleUrls: ['./call.page.scss'],
})
export class CallPage implements OnInit, OnDestroy {
  constructor(
    private userService: UserService,
    private voiceService: VoiceService,
    private modalController: ModalController,
  ) { }

  @Input() prompt: string;
  @Input() maxMinutes: number;
  @Input()
  get reader(): string {
    return this._reader;
  }
  set reader(value) {
    // console.log('reader changed: ', value);
    this._reader = value;
  }
  _reader: string;
  @Input()
  get spread(): any {
    return this._spread;
  }
  set spread(value) {
    // console.log('spread changed: ', value);
    this._spread = value;
  }
  _spread: any;

  timeElapsed: number = 0;
  timeElapsedPretty: string = "00:00:00";
  interval: any;
  transcript: any;
  user: any;
  web: boolean = Capacitor.getPlatform() == 'web';
  costPerMin: number = environment.callCost;

  setTimer() {
    this.interval = setInterval(() => {
      this.timeElapsed = this.timeElapsed + 1;
      this.timeElapsedPretty = new Date(this.timeElapsed * 1000).toISOString().slice(11, 19);
      // when timer gets to 15 seconds before max minutes do some stuff
      if (this.timeElapsed == (this.maxMinutes * 60) - 15) {
        this.voiceService.say(`Our time is nearly up, looks like I have to go! Farewell!`)
      };
      if (this.timeElapsed == this.maxMinutes * 60) {
        this.voiceService.endCall();
      };
    }, 1000);
  }

  cleanTranscript(transcript: any) {
    if (this.reader) {
      return transcript
      .filter(i => i.role != 'system')
      .filter(i => !i.tool_calls)
      .map((i) => {
        if (i.role == 'assistant') {
          i.role = this.reader;
        };
        if (i.role == 'user') {
          i.role = this.userService.user.name;
        };
        return i;
      });
    }
  }

  dismiss() {
    clearInterval(this.interval);
    this.modalController.getTop().then((data) => {
      var modal = data;
      if (this.transcript) {
        modal.dismiss({
          transcript: this.transcript,
          cost: Math.round(this.timeElapsed / 60 * this.costPerMin),
          time: this.timeElapsedPretty,
          spread: this.spread
        });
      }
    });
  }

  async endCall() {
    this.voiceService.endCall();
    NativeAudio.play({
      assetId: 'end',
    });
  }

  async startCall() {
    NativeAudio.play({
        assetId: 'start',
    });
    setTimeout(() => {
      this.voiceService.callAssistant(this.reader, this.prompt, this.user.name);
      this.voiceService.vapi.on('call-start', () => {
        this.setTimer();
      });

      this.voiceService.vapi.on('call-end', () => {
        this.dismiss();
      });

      this.voiceService.vapi.on('error', () => {
        this.dismiss();
      });

      this.voiceService.vapi.on('message', (message) => {
        if (message?.conversation) {
          var conversation = this.cleanTranscript(message.conversation);
          if (conversation) {
            this.transcript = conversation;
          };
        }
      });
    }, 4000);
  }

  ionViewDidLeave() {
    this.timeElapsed = 0;
    this.timeElapsedPretty = "00:00:00";
    this.reader = undefined;
    this.transcript = undefined;
  }

  ngOnDestroy() {
    NativeAudio.unload({
      assetId: 'start',
    });
    NativeAudio.unload({
      assetId: 'end',
    });
  }

  async ngOnInit() {
    var path = "https://milkmusket.net/linked_content/labyrinthos/downloads/sounds/";
    if (Capacitor.getPlatform() == 'ios') {
      path = "public/assets/sounds/"
    } else if (Capacitor.getPlatform() == 'android') {
      path = "sounds/"
    };
    await NativeAudio.preload({
      assetId: "start",
      assetPath: path + "phone-ring.mp3",
      audioChannelNum: 1,
      isUrl: true
    });
    await NativeAudio.preload({
      assetId: "end",
      assetPath: path + "phone-hangup.mp3",
      audioChannelNum: 1,
      isUrl: true
    });
    this.startCall();
    this.userService.getUser().then( data  => {
      this.user = data;
    });
  }
}
