import { Injectable } from '@angular/core';
import Vapi from "@vapi-ai/web";

@Injectable({
  providedIn: 'root'
})
export class VoiceService {
  constructor() { }
  readers: any = {
    catssandra: "c45871dc-100f-4845-87b2-02d4ad586a37",
    crowley: "2725dc69-8011-4836-b32e-e44033706616",
    owlcle: "07bfa3d9-4854-4c04-8927-4aff34d4796b",
    nostradamouse: "c37dda0d-5ceb-41fd-a374-feee87ea04bc"
  };
  vapi: any = new Vapi("6c058e85-73ab-4287-a130-8867503c94df");

  callAssistant(readerId: string, readingPrompt: string, userName: string) {
    const assistantOverrides = {
      variableValues: {
        instructions: readingPrompt,
        name: userName
      },
    };
    this.vapi.start(this.readers[readerId], assistantOverrides);
  }

  endCall() {
    this.vapi.stop();
  };

  say(message: string) {
    this.vapi.say(message);
  };
}
