<ion-header>
  <ion-toolbar>
    <ion-title class="ion-text-center">Getting Personalized Readings</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()" size="large">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <swiper #slider [config]="config" class="primary-buttons buttons-top" [navigation]="true">
    <ng-template swiperSlide *ngFor="let slide of 'catssandra.slides' | translate, index as i">
      <div class="slider-content">
        <img src="../assets/catssandra/slides{{i + 1}}.png"><br>
        <div class="content ion-padding">
          <h2>{{slide.title}}</h2>
          <p class="small" [innerHTML]="slide.data"></p>
        </div>
      </div>
    </ng-template>

    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>

  </swiper>
</ion-content>

<ion-footer mode="md" color="primary" *ngIf="showFooter">
  <ion-toolbar color="primary" mode="md" class="ion-text-center">
    <ion-button (click)="buyCredits()">
      {{ 'catssandra.purchase' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>

<ion-footer mode="md" color="primary" *ngIf="showReading">
  <ion-toolbar color="primary" mode="md" class="ion-text-center">
    <ion-button (click)="closeAndMove('tabs/tab-spreads')">
      Start a Reading
    </ion-button>
  </ion-toolbar>
</ion-footer>
