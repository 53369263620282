import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RuneService {
  constructor() { }

  rune: Array<any> = [
    {
      rune: 'ᚠ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/fehu-rune-meaning-wealth',
      alphabet: 'f',
      search: 'fehu',
      image: 'fehu',
      reversed: true,
      aett: 'frey'
    },
    {
      rune: 'ᚢ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/uruz-rune-meaning-endurance-and-power',
      alphabet: 'u',
      search: 'uruz',
      image: 'uruz',
      reversed: true,
      aett: 'frey'
    },
    {
      rune: 'ᚦ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/thurisaz-rune-meaning-conflict',
      alphabet: 'þ',
      search: 'thurisaz',
      image: 'thurisaz',
      reversed: true,
      aett: 'frey'
    },
    {
      rune: 'ᚨ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/ansuz-rune-meaning-messages',
      alphabet: 'a',
      search: 'ansuz',
      image: 'ansuz',
      reversed: true,
      aett: 'frey'
    },
    {
      rune: 'ᚱ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/raidho-rune-meaning-journey',
      alphabet: 'r',
      search: 'raidho',
      image: 'raidho',
      reversed: true,
      aett: 'frey'
    },
    {
      rune: 'ᚲ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/kenaz-rune-meaning-knowledge',
      alphabet: 'k',
      search: 'kenaz',
      image: 'kenaz',
      reversed: true,
      aett: 'frey'
    },
    {
      rune: 'ᚷ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/gebo-rune-meaning-gift',
      alphabet: 'g',
      search: 'gebo',
      image: 'gebo',
      reversed: false,
      aett: 'frey'
    },
    {
      rune: 'ᚹ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/wunjo-rune-meaning-joy',
      alphabet: 'w',
      search: 'wunjo',
      image: 'wunjo',
      reversed: true,
      aett: 'frey'
    },
    {
      rune: 'ᚺ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/hagalaz-rune-meaning-hail',
      alphabet: 'h',
      search: 'hagalaz',
      image: 'hagalaz',
      reversed: false,
      aett: 'heimdall'
    },
    {
      rune: 'ᚾ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/nauthiz-rune-meaning-necessity',
      alphabet: 'n',
      search: 'nauthiz',
      image: 'nauthiz',
      reversed: true,
      aett: 'heimdall'
    },
    {
      rune: 'ᛁ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/isa-rune-meaning-stillness',
      alphabet: 'i',
      search: 'isa',
      image: 'isa',
      reversed: false,
      aett: 'heimdall'
    },
    {
      rune: 'ᛃ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/jera-rune-meaning-harvest',
      alphabet: 'j',
      search: 'jera',
      image: 'jera',
      reversed: false,
      aett: 'heimdall'
    },
    {
      rune: 'ᛇ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/eihwaz-rune-meaning-transformation',
      alphabet: 'ï',
      search: 'eihwaz',
      image: 'eihwaz',
      reversed: false,
      aett: 'heimdall'
    },
    {
      rune: 'ᛈ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/perthro-rune-meaning-mystery',
      alphabet: 'p',
      search: 'perthro',
      image: 'perthro',
      reversed: true,
      aett: 'heimdall'
    },
    {
      rune: 'ᛉ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/algiz-rune-meaning-protection',
      alphabet: 'z',
      search: 'algiz',
      image: 'algiz',
      reversed: true,
      aett: 'heimdall'
    },
    {
      rune: 'ᛊ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/sowilo-rune-meaning-achievement',
      alphabet: 's',
      search: 'sowilo',
      image: 'sowilo',
      reversed: false,
      aett: 'heimdall'
    },
    {
      rune: 'ᛏ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/tiwaz-rune-meaning-tyr',
      alphabet: 't',
      search: 'tiwaz',
      image: 'tiwaz',
      reversed: true,
      aett: 'tyr'
    },
    {
      rune: 'ᛒ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/berkano-rune-meaning-divine-feminine',
      alphabet: 'b',
      search: 'berkano',
      image: 'berkano',
      reversed: true,
      aett: 'tyr'
    },
    {
      rune: 'ᛖ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/ehwaz-rune-meaning-forward-energy',
      alphabet: 'e',
      search: 'ehwaz',
      image: 'ehwaz',
      reversed: true,
      aett: 'tyr'
    },
    {
      rune: 'ᛗ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/mannaz-rune-meaning-humanity',
      alphabet: 'm',
      search: 'mannaz',
      image: 'mannaz',
      reversed: true,
      aett: 'tyr'
    },
    {
      rune: 'ᛚ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/laguz-rune-meaning-intuition',
      alphabet: 'l',
      search: 'laguz',
      image: 'laguz',
      reversed: true,
      aett: 'tyr'
    },
    {
      rune: 'ᛜ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/ingwaz-rune-meaning-divine-masculine',
      alphabet: 'ŋ',
      search: 'ingwaz',
      image: 'ingwaz',
      reversed: false,
      aett: 'tyr'
    },
    {
      rune: 'ᛟ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/othala-rune-meaning-home',
      alphabet: 'o',
      search: 'othala',
      image: 'othala',
      reversed: true,
      aett: 'tyr'
    },
    {
      rune: 'ᛞ',
      url: 'https://labyrinthos.co/blogs/elder-futhark-norse-runes-meanings-list/dagaz-rune-meaning-dawn',
      alphabet: 'd',
      search: 'dagaz',
      image: 'dagaz',
      reversed: false,
      aett: 'tyr'
    },
    {
      rune: '',
      alphabet: '',
      search: 'wyrd',
      image: 'wyrd',
      reversed: false,
      aett: ''
    },
  ];

  chakras: Array<any> = [
    {
      id: 'root'
    },
    {
      id: 'sacral'
    },
    {
      id: 'solar_plexus'
    },
    {
      id: 'heart'
    },
    {
      id: 'throat'
    },
    {
      id: 'third_eye'
    },
    {
      id: 'crown'
    }
  ];

  decks: any = {
    rock: {
      hStart: 240,
      hEnd: 240,
      sStart: 0,
      sEnd: 0,
      lStart: 30,
      lEnd: 40,
      rMin: 30,
      rMax: 75,
      glyph: {
        style: 'emboss',
        styleColor: 47
      }
    },
    roseQuartz: {
      hStart: 330,
      hEnd: 359,
      sStart: 10,
      sEnd: 65,
      lStart: 90,
      lEnd: 98,
      rMin: 15,
      rMax: 35,
      texture: 'stone-white',
      effect: 'overlay',
      glyph: {
        style: 'emboss',
        styleColor: 47,
        styleSaturation: '100%'
      },
      info: {
        element: 'water',
        chakra: [3],
      }
    },
    amethyst: {
      hStart: 276,
      hEnd: 291,
      sStart: 50,
      sEnd: 65,
      lStart: 25,
      lEnd: 45,
      texture: 'stone-black',
      effect: 'soft-light',
      glyph: {
        style: 'glow',
        styleColor: 'gold'
      },
      info: {
        element: 'air',
        chakra: [5, 6],
      }
    },
    lapis: {
      hStart: 230,
      hEnd: 245,
      sStart: 80,
      sEnd: 100,
      lStart: 45,
      lEnd: 60,
      texture: 'speckles',
      effect: 'lighten',
      glyph: {
        style: 'emboss',
        styleColor: 47,
        styleSaturation: '100%'
      },
      info: {
        element: 'air',
        chakra: [4, 5],
      }
    },
    snowflakeObsidian: {
      hStart: 45,
      hEnd: 48,
      sStart: 0,
      sEnd: 2,
      lStart: 0,
      lEnd: 10,
      texture: 'stone-black',
      effect: 'overlay',
      glyph: {
        style: 'emboss',
        styleColor: 47,
        styleSaturation: '100%'
      },
      info: {
        element: 'earth',
        chakra: [0, 5],
      }
    },
    malachite: {
      hStart: 147,
      hEnd: 169,
      sStart: 36,
      sEnd: 67,
      lStart: 23,
      lEnd: 40,
      texture: 'malachite',
      effect: 'overlay',
      glyph: {
        style: 'emboss',
        styleColor: 55,
        styleSaturation: '100%'
      },
      info: {
        element: 'fire',
        chakra: [2, 3],
      }
    },
    opalite: {
      hStart: 160,
      hEnd: 300,
      sStart: 95,
      sEnd: 100,
      lStart: 98,
      lEnd: 100,
      texture: 'holographic',
      effect: 'lighten',
      glyph: {
        style: 'emboss',
        styleColor: 55,
        styleSaturation: '100%'
      },
      info: {
        element: 'air',
        chakra: [5],
      }
    },
    citrine: {
      hStart: 24,
      hEnd: 45,
      sStart: 50,
      sEnd: 75,
      lStart: 25,
      lEnd: 45,
      texture: 'stone-white',
      effect: 'overlay',
      glyph: {
        style: 'glow',
        styleColor: 'orange'
      },
      info: {
        element: 'air',
        chakra: [0, 2],
      }
    },
    jade: {
      hStart: 70,
      hEnd: 95,
      sStart: 40,
      sEnd: 50,
      lStart: 25,
      lEnd: 45,
      texture: 'stone-white',
      effect: 'soft-light',
      glyph: {
        style: 'emboss',
        styleColor: 47,
        styleSaturation: '100%'
      },
      info: {
        element: 'water',
        chakra: [3],
      }
    },
    sunstone: {
      hStart: 10,
      hEnd: 35,
      sStart: 60,
      sEnd: 85,
      lStart: 20,
      lEnd: 40,
      texture: 'stone-black',
      effect: 'soft-light',
      glyph: {
        style: 'emboss',
        styleColor: 47,
        styleSaturation: '100%'
      },
      info: {
        element: 'fire',
        chakra: [2],
      }
    },
    blueLaceAgate: {
      hStart: 215,
      hEnd: 230,
      sStart: 40,
      sEnd: 80,
      lStart: 80,
      lEnd: 95,
      texture: 'agate',
      effect: 'overlay',
      glyph: {
        style: 'emboss',
        styleColor: 47,
        styleSaturation: '100%'
      },
      info: {
        element: 'earth',
        chakra: [4],
      }
    },
    opal: {
      hStart: 170,
      hEnd: 190,
      sStart: 80,
      sEnd: 100,
      lStart: 80,
      lEnd: 100,
      texture: 'labradorite',
      effect: 'hue',
      glyph: {
        style: 'emboss',
        styleColor: 47,
        styleSaturation: '100%'
      },
      info: {
        element: 'earth',
        chakra: [4],
      }
    },
    labradorite: {
      hStart: 0,
      hEnd: 255,
      sStart: 40,
      sEnd: 60,
      lStart: 50,
      lEnd: 100,
      texture: 'jasper',
      effect: 'difference',
      glyph: {
        style: 'emboss',
        styleColor: 47,
        styleSaturation: '100%'
      },
      info: {
        element: 'earth',
        chakra: [4],
      }
    },
    jasper: {
      hStart: 10,
      hEnd: 20,
      sStart: 50,
      sEnd: 50,
      lStart: 30,
      lEnd: 50,
      texture: 'jasper',
      effect: 'hard-light',
      glyph: {
        style: 'emboss',
        styleColor: 47,
        styleSaturation: '100%'
      },
      info: {
        element: 'earth',
        chakra: [4],
      }
    },
    mossAgate: {
      hStart: 120,
      hEnd: 130,
      sStart: 0,
      sEnd: 10,
      lStart: 10,
      lEnd: 35,
      texture: 'moss',
      effect: 'hard-light',
      glyph: {
        style: 'emboss',
        styleColor: 47,
        styleSaturation: '100%'
      },
      info: {
        element: 'earth',
        chakra: [4],
      }
    },
    fluorite: {
      hStart: 10,
      hEnd: 20,
      sStart: 0,
      sEnd: 0,
      lStart: 20,
      lEnd: 25,
      texture: 'flourite',
      effect: 'hard-light',
      glyph: {
        style: 'emboss',
        styleColor: 47,
        styleSaturation: '100%'
      },
      info: {
        element: 'earth',
        chakra: [4],
      }
    },
  }

  getRandom = (min = 0, max = 1) => Math.floor(Math.random() * (max - min + 1) + min);
  minRadius = 33;
  maxRadius = 75;
  randomItem = (array) => array[Math.floor(Math.random() * array.length)];

  getRandomRadius(min?, max?) {
    if (min && max) {
      return this.getRandom(min, max);
    } else {
      return this.getRandom(this.minRadius, this.maxRadius);
    }
  }

  getRuneFromTranslit(letter) {
		let matchingRunic = this.rune.find(f => f.alphabet === letter || f.altAlphabet === letter);
    if (matchingRunic) {
      return matchingRunic.rune;
    } else {
      return '';
    }
  }

  filterCards(field, searchText) {
    return this.rune.filter((card) => {
      return card[field].toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    });
  }

  getCardByDeck(cardId: string, deck: string) {
    return this.rune.filter((card) => {
      return card.search == cardId;
    });
  }

  getCardByIndex(index: number, deckId: string) {
    return this.rune[index];
  }

  getCardIndex(cardId) {
    return this.rune.findIndex(card => {
      return card.image == cardId;
    })
  }

  getRandomInt(excluded, maxIndex) {
    // return Math.floor(Math.random()*(max-min+1)+min);
    var result = 0;
    var min = 0;
    var max = maxIndex - 1;
    var index = Math.floor(Math.random()*(max-min+1)+min);

    while(excluded.indexOf(index) > -1) {
      if (index < max) {
        index++;
      } else {
        index = 0;
      }
    }
    return index;
  }

  getRandomCard(excluded) {
    var index = this.getRandomInt(excluded, 24);
    return this.rune[index];
  }

  getCard(cardId) {
    return this.rune.filter((card) => {
      return card.image == cardId;
    });
  }

  getRunes() {
    return this.rune;
  }

  getCardsByIndex(indexArray) {
    var cards = [];
    for (let i of indexArray) {
      cards.push(this.rune[i])
    }
    return cards;
  }

  getRandomCardInRange(subset: Array<any>, excluded?: Array<any>) {
    if (excluded) {
      subset = subset.filter((n) => { // remove excluded
        return excluded.indexOf(n) < 0;
      });
    };

    var index = subset[Math.floor(Math.random()*subset.length)];
    return this.rune[index];
  }

  getMissingCards(cardIds: Array<string>) {
    var allCards = JSON.parse(JSON.stringify(this.rune.map(c => c.image)));
    let difference = allCards.filter(x => !cardIds.includes(x));
    return difference;
  }

  generateStone(glyphData, stoneData?) {
  	const index = parseInt('1', 10);
    let angle = this.getRandom(-3, 3);
    let textureAngle = (index * 73) % 360;
  	let px = this.getRandom(0, 100);
  	let py = this.getRandom(0, 100);
    let radius = `${this.getRandomRadius()}% ${this.getRandomRadius()}% ${this.getRandomRadius()}% ${this.getRandomRadius()}%`;

    // these are custom if needed;
    let hue = this.getRandom(0, 255);
    let saturation = `${this.getRandom(0, 80)}%`;
    let lightness = `${this.getRandom(30, 80)}%`;

    if (stoneData) {
      hue = this.getRandom(stoneData.hStart, stoneData.hEnd);
      saturation = `${this.getRandom(stoneData.sStart, stoneData.sEnd)}%`;
      lightness = `${this.getRandom(stoneData.lStart, stoneData.lEnd)}%`;
      if (stoneData.rMin && stoneData.rMax) {
        radius = `${this.getRandomRadius(stoneData.rMin, stoneData.rMax)}% ${this.getRandomRadius(stoneData.rMin, stoneData.rMax)}% ${this.getRandomRadius(stoneData.rMin, stoneData.rMax)}% ${this.getRandomRadius(stoneData.rMin, stoneData.rMax)}%`;
      }
    }

  	let stone: any = {};
    stone['styles'] = {};
  	stone.styles['a'] = angle;
  	stone.styles['px'] = px;
  	stone.styles['py'] = py;
  	stone.styles['h'] = hue;
  	stone.styles['r'] = radius;
  	stone.styles['ta'] = textureAngle;
    stone.styles['s'] = saturation;
    stone.styles['l'] = lightness;
    stone.texture = stoneData.texture;
    stone.glyph = stoneData.glyph.style;
    stone.glyphColor = stoneData.glyph.styleColor;
    stone.glyphSaturation = stoneData.glyph.styleSaturation;
    stone.effect = stoneData.effect;
    return stone;

  }
}
