import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LenormandService {
  lastIndex: number = 35;

  lenormand: any = [
    {
      image: "lenormand-01.png",
      number: 1,
      feeling: "Positive",
      card: "Nine of Hearts",
      timing: "Soon",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-rider-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-02.png",
      number: 2,
      feeling: "Positive",
      card: "Six of Diamonds",
      timing: "A Short Time / Window",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-clover-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-03.png",
      number: 3,
      feeling: "Positive",
      card: "Ten of Spades",
      timing: "A Long Time",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-ship-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-04.png",
      number: 4,
      feeling: "Positive",
      card: "King of Hearts",
      timing: "Permanent",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-house-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-05.png",
      number: 5,
      feeling: "Neutral",
      card: "Seven of Hearts",
      timing: "9 - 12 Months",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-tree-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-06.png",
      number: 6,
      feeling: "Negative",
      card: "King of Clubs",
      timing: "Fall",
      url: "https://labyrinthos.co/blogs/lenormand-cards/clouds-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-07.png",
      number: 7,
      feeling: "Negative",
      card: "Queen of Clubs",
      timing: "Planned",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-snake-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-08.png",
      number: 8,
      feeling: "Negative",
      card: "Nine of Diamonds",
      timing: "Forever",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-coffin-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-09.png",
      number: 9,
      feeling: "Positive",
      card: "Queen of Spades",
      timing: "Springtime",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-bouquet-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-10.png",
      number: 10,
      feeling: "Negative",
      card: "Jack of Diamonds",
      timing: "Quickly, Suddenly",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-scythe-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-11.png",
      number: 11,
      feeling: "Negative",
      card: "Jack of Clubs",
      timing: "Short",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-whip-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-12.png",
      number: 12,
      feeling: "Neutral",
      card: "Seven of Diamonds",
      timing: "Temporary",
      url: "https://labyrinthos.co/blogs/lenormand-cards/birds-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-13.png",
      number: 13,
      feeling: "Positive",
      card: "Jack of Spades",
      timing: "Temporarily, Soon",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-child-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-14.png",
      number: 14,
      feeling: "Negative",
      card: "Nine of Clubs",
      timing: "Difficult Timing",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-fox-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-15.png",
      number: 15,
      feeling: "Positive",
      card: "Ten of Clubs",
      timing: "A Long Time",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-bear-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-16.png",
      number: 16,
      feeling: "Positive",
      card: "Six of Hearts",
      timing: "Night, Winter",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-star-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-17.png",
      number: 17,
      feeling: "Neutral",
      card: "Queen of Hearts",
      timing: "Long Lasting",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-stork-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-18.png",
      number: 18,
      feeling: "positive",
      card: "Ten of Hearts",
      timing: "Long Lasting",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-dog-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-19.png",
      number: 19,
      feeling: "Neutral",
      card: "Six of Spades",
      timing: "Delays",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-tower-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-20.png",
      number: 20,
      feeling: "positive",
      card: "Eight of Spades",
      timing: "Many difference timings",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-garden-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-21.png",
      number: 21,
      feeling: "Negative",
      card: "Eight of Clubs",
      timing: "Standstill",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-mountain-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-22.png",
      number: 22,
      feeling: "Neutral",
      card: "Queen of Diamonds",
      timing: "6 - 7 Weeks",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-crossroad-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-23.png",
      number: 23,
      feeling: "Negative",
      card: "Seven of Clubs",
      timing: "Running out of time",
      url: "https://labyrinthos.co/blogs/lenormand-cards/mice-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-24.png",
      number: 24,
      feeling: "Positive",
      card: "Jack of Hearts",
      timing: "Springtime",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-heart-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-25.png",
      number: 25,
      feeling: "Neutral",
      card: "Ace of Clubs",
      timing: "Repetitive",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-ring-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-26.png",
      number: 26,
      feeling: "Neutral",
      card: "10 of Diamonds",
      timing: "Future",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-book-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-27.png",
      number: 27,
      feeling: "Neutral",
      card: "Seven of Spades",
      timing: "Fast",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-letter-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-28.png",
      number: 28,
      feeling: "Neutral",
      card: "Ace of Hearts",
      timing: "Immediately",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-man-animus-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-29.png",
      number: 29,
      feeling: "Neutral",
      card: "Ace of Spades",
      timing: "Immediately",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-anima-woman-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-30.png",
      number: 30,
      feeling: "Positive",
      card: "King of Spades",
      timing: "Winter",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-lily-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-31.png",
      number: 31,
      feeling: "Positive",
      card: "Ace of Diamonds",
      timing: "Day, Sunrise",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-sun-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-32.png",
      number: 32,
      feeling: "Neutral",
      card: "Eight of Hearts",
      timing: "Evening, Sunset",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-moon-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-33.png",
      number: 33,
      feeling: "Positive",
      card: "Eight of Diamonds",
      timing: "Long Lasting",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-key-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-34.png",
      number: 34,
      feeling: "Positive",
      card: "King of Diamonds",
      timing: "Repetitive",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-fish-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-35.png",
      number: 35,
      feeling: "Neutral",
      card: "Nine of Spades",
      timing: "A Long Time",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-anchor-lenormand-card-meaning-and-combinations"
    },
    {
      image: "lenormand-36.png",
      number: 36,
      feeling: "Negative",
      card: "Six of Clubs",
      timing: "2 - 3 weeks",
      url: "https://labyrinthos.co/blogs/lenormand-cards/the-cross-lenormand-card-meaning-and-combinations"
    }
  ];

  constructor(private translate: TranslateService) {}

  getLenormand() {
    return this.lenormand;
  }

  filterCards(searchText) {
    return this.lenormand.filter((card) => {
      let title = this.translate.instant('lenormand.' + card.image + '.title');
      return title.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    });
  }

  getCard(cardId) {
    return this.lenormand.filter((card) => {
      return card.image == cardId;
    });
  }

  getCardByDeck(cardId: string, deckId: string) {
    // identical for now, as lenormand decks don't need extra cards, but reading service uses same function names for each service.
    return this.lenormand.filter((card) => {
      return card.image == cardId;
    });
  }

  getCardByIndex(index) {
    return this.lenormand[index];
  }

  getCardsByIndex(indexArray) {
    var cards = [];
    for (let i of indexArray) {
      cards.push(this.lenormand[i])
    }
    return cards;
  }

  getRandomCardInRange(indexes) {
    var index = indexes[Math.floor(Math.random()*indexes.length)];
    return this.lenormand[index];
  }

  getCardIndex(cardId) {
    return this.lenormand.findIndex(card => {
      return card.image == cardId;
    })
  }

  getRandomInt(excluded, maxIndex) {
    // return Math.floor(Math.random()*(max-min+1)+min);
    var result = 0;
    var min = 0;
    var max = maxIndex - 1;
    var index = Math.floor(Math.random()*(max-min+1)+min);

    while(excluded.indexOf(index) > -1) {
      if (index < max) {
        index++;
      } else {
        index = 0;
      }
    }
    return index;
  }

  getRandomCard(excluded) {
    var index = this.getRandomInt(excluded, 36);
    return this.lenormand[index];
  }

  getMissingCards(cardIds: Array<string>) {
    var allCards = JSON.parse(JSON.stringify(this.lenormand.map(c => c.image)));
    let difference = allCards.filter(x => !cardIds.includes(x));
    return difference;
  }
}
