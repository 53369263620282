import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { TarotService } from './tarot.service';
import { ProductService } from './product.service';
import { TranslateService } from '@ngx-translate/core';
import { Filesystem, Directory } from "@capacitor/filesystem";
import { Capacitor } from "@capacitor/core";
import { Storage } from '@ionic/storage';
import { ToastController, ModalController } from '@ionic/angular';
import { LoadingPage } from '../modals/loading/loading.page';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  constructor(
    private toastController: ToastController,
    private modalController: ModalController,
    private tarotService: TarotService,
    private productService: ProductService,
    private translate: TranslateService,
    private http: HttpClient,
    private storage: Storage,
    private router: Router,
    private sanitizer: DomSanitizer
  ) { }

  apiURL: string = "https://milkmusket.net/linked_content/labyrinthos/downloads/decks/";
  downloadPathWrite: string = "labyrinthos/decks/";
  downloadProgress = new Subject<number>();
  downloadItem = new Subject<string>();
  progress: number;
  item: string;

  // file structures for tarot decks, including images and translations. when downloading from a url, make sure to have a common url structure like "app.labyrinthos.co/downloads/decks/DECKCODE/CODEDCARDNAME"
  tarotDeckStructure: any = {
    cards: [...this.tarotService.getAllCardProperties('image')],
    translation: undefined
  };

  async presentToast(message: string, color: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'top',
      color: color,
      duration: 5000
    });
    await toast.present();

    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }

  async presentLoading(userDeck: string, product: any) {
    console.log('present loading for: ', userDeck);
    const loading = await this.modalController.create({
      component: LoadingPage,
      cssClass: 'loading',
      id: 'loading_' + userDeck,
      componentProps: {
        userDeck: userDeck,
        downloadProgress: this.downloadProgress,
        downloadItem: this.downloadItem,
        product: product
      }
    });
    await loading.present();
  }

  async dismissLoading(userDeck: string) {
    console.log('dismiss loading for: ', userDeck);
    let modal = await this.modalController.dismiss({}, '', 'loading_' + userDeck);
  }

  private convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  })

  getMimetype(name: string) {
    if (name.indexOf('png') >= 0) {
      return 'image/png';
    }
  }

  async fileInfo(path?: string) {
    // return directory if exists, otherwise return false
    var choosePath;
    if (path) {
      choosePath = path;
    } else {
      choosePath = '';
    };

    try {
      return await Filesystem.readdir({
        path: choosePath,
        directory: Directory.Library
      });
    } catch (error) {
      return false;
    }
  }



  async getFiles(deck: string) {
    return await this.storage.get('deck_' + deck).then(data => {
      if (data) {
        return data;
      }
    });
  }

  getFile(deck: string, cardId: string) {
    return this.storage.get('deck_' + deck).then(data => {
      if (data && data[cardId]) {
        return data[cardId];
      }
    });
  }

  convertUrlToName(url: string) {
    var id = url.split("/").pop();
    return this.translate.instant('tarot.GTT.' + id + '.title');
  }

  async quietRelinkiOS(decks: Array<string>) {
    console.log('quietly relinking these decks...', decks);
    Filesystem.requestPermissions();
    for (let deck of decks) {
      var deckURLs = await this.storage.get('deck_' + deck);
      // for each card in deck
      for (let [name, url] of Object.entries(deckURLs)) {
        const readResult = await Filesystem.getUri({
          path: this.downloadPathWrite + deck + "/" + name,
          directory: Directory.Library
        });
        var newURL = Capacitor.convertFileSrc(readResult.uri);
        deckURLs[name] = newURL;
      }
      this.storage.set('deck_' + deck, deckURLs);
    }
  }

  async downloadFiles(deck: string) {
    // android needs to check permissions first.
    Filesystem.requestPermissions();
    var deckCategory = this.productService.getProductType(deck);
    var product = this.productService.getDeck(deckCategory, deck);
    this.presentLoading(deck, product);

    if (this.tarotDeckStructure.cards.indexOf('back') === -1) {
      this.tarotDeckStructure.cards.push('back-01.png');
    };

    var deckURLs = this.tarotDeckStructure.cards.map((id) => {
      return this.apiURL + deck + "/" + id;
    });

    var files = this.tarotDeckStructure.cards.reduce((acc,curr)=> (acc[curr]='',acc),{});

    const requests = [];

    for (let url of deckURLs) {
      requests.push(
        new Promise((resolve, reject) => {
          this.http.get(url, {
            responseType: 'blob',
            reportProgress: true,
            observe: 'events'
          }).subscribe(async event => {
            if (event.type === HttpEventType.DownloadProgress) {
              this.downloadProgress.next(Math.round((100 * event.loaded) / event.total));
              this.downloadItem.next(this.convertUrlToName(url));
            } else if (event.type === HttpEventType.Response) {
              this.downloadProgress.next(0);

              const name = url.substr(url.lastIndexOf('/') + 1);
              const base64 = await this.convertBlobToBase64(event.body) as string;

              // different directories are needed to keep these files hidden from users.
              var hiddenDirectory;
              if (Capacitor.getPlatform() == 'ios') {
                hiddenDirectory = Directory.Library;
              } else if (Capacitor.getPlatform() == 'android') {
                hiddenDirectory = Directory.External;
              } else {
                hiddenDirectory = Directory.Documents;
              };

              const savedFile = await Filesystem.writeFile({
                path: this.downloadPathWrite + deck + "/" + name,
                data: base64,
                directory: hiddenDirectory,
                recursive: true
              });

              const readResult = await Filesystem.getUri({
                path: this.downloadPathWrite + deck + "/" + name,
                directory: hiddenDirectory
              });

              var path;
              if (Capacitor.getPlatform() == 'ios' || Capacitor.getPlatform() == 'android') {
                path = Capacitor.convertFileSrc(readResult.uri);
                // path = this.sanitizer.bypassSecurityTrustResourceUrl(Capacitor.convertFileSrc(readResult.uri));
                // path = readResult.uri;
                // path = base64;
              } else {
                path = base64;
              };

              const mimeType = this.getMimetype(name);

              files[name] = path;
              resolve(files);
            }
          });
        })
      );
    }

    // save file paths to storage
    const result = await Promise.all(requests).then((values) => {
      this.storage.set('deck_' + deck, files);
    });

    // create an object to use for all card components. otherwise we're fetching from storage each time a card image is loaded. It's much better to have the image here, I think.
    this.dismissLoading(deck);
  }
}
