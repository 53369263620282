<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{'purchases.starter_pack.title' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="success" *ngIf="!offerings"></ion-content>
<ion-content color="success" *ngFor="let sku of offerings; index as i">
  <div class="hero">
    <img src="../assets/offerings/starter_pack.png">
  </div>
  <ion-grid class="counter ion-text-center">
    <ion-row>
      <ion-col>
        {{countdown.dd}}<br>
        <span class="capitals">{{'_common.time.dd' | translate}}</span>
      </ion-col>
      <ion-col>
        {{countdown.hh}}<br>
        <span class="capitals">{{'_common.time.hh' | translate}}</span>
      </ion-col>
      <ion-col>
        {{countdown.mm}}<br>
        <span class="capitals">{{'_common.time.mm' | translate}}</span>
      </ion-col>
      <ion-col>
        {{countdown.ss}}<br>
        <span class="capitals">{{'_common.time.ss' | translate}}</span>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div class="content ion-padding ion-margin ion-text-center">
    <h1 class="stamp animated bounceIn delay-3">
      <div>
        <span class="number">47<small>%</small></span><br>
        <span class="off capitals">off</span>
      </div>
    </h1>

    <p class="capitals">{{'_common.deal' | translate}}</p>
    <h1>{{ sku.product.title }}</h1>
    <p>{{'purchases.starter_pack.description' | translate}}</p>
    <ion-button size="small" class="xsmall" fill="outline" (click)="openJournalShop()">
      {{'purchases.starter_pack.faq_journal' | translate}}
    </ion-button>
    <ion-button size="small" class="xsmall" fill="outline" (click)="openCreditsInfo()">
      {{'purchases.starter_pack.faq_credits' | translate}}
    </ion-button>
  </div>
  <ion-button size="large" color="tertiary" expand="block" class="ion-margin" (click)="purchase(sku)">
    {{'_common.purchase' | translate}} - {{ sku.product.price | number:'1.0-2' | currency: sku.product.currencyCode }}
  </ion-button>
</ion-content>
