<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start" *ngIf="spreadType == 'tarot'">
      <ion-button (click)="share(card.url)">
        <ion-icon slot="icon-only" name="share-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center" *ngIf="translateVariable">
      <span class="rune" *ngIf="spreadType == 'rune'">{{ card.image }}: </span>
      {{translateVariable + '.title' | translate}}
      <span *ngIf="reverseCard"> - {{ '_common.reversed' | translate}}</span>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss(card.image)">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar *ngIf="(deckInfo?.reversal || card.reversed) && !spread" mode="md">
    <ion-segment mode="md" value="up" (ionChange)="reverseCard = !reverseCard">
      <ion-segment-button value="up" mode="md" size="small">
        <ion-icon name="arrow-up"></ion-icon>
      </ion-segment-button>
      <ion-segment-button value="rev" mode="md" size="small">
        <ion-icon name="arrow-down"></ion-icon>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="ion-padding ion-margin ion-text-center">
    <div *ngIf="spread">
      <!-- view-reading / custom spreads show different stuff! -->
      <div class="spread ion-padding-bottom" *ngIf="position && (spreadId == 'custom' || standardSpread == false)">
        <p class="capitals">{{ position.name }}</p>
        {{ position.description }}
      </div>
      <!-- get-reading / input-reading / tab-reading shows different stuff! -->
      <div class="spread ion-padding-bottom" *ngIf="position && !position.description && spreadType != 'lenormand' && standardSpread == true">
        <p class="capitals">{{ 'spreads.spreads.' + spreadId + '.cards.' + index + '.name' | translate }}</p>
        {{ 'spreads.spreads.' + spreadId + '.cards.' + index + '.description' | translate }}
      </div>
    </div>

    <ion-grid (swipeleft)="nextCard()" (swiperight)="prevCard()">
      <app-card *ngIf="spreadType != 'rune'" class="card" [userDeck]="userDeck" [cardId]="card.image" [reversed]="reverseCard" (cardClicked)="expandCard(true)"></app-card>
      <div class="rune-wrapper" *ngIf="spreadType == 'rune'">
        <app-rune class="card" [stoneId]="userDeck" [letter]="card.alphabet" [reversed]="reverseCard" [shadow]="'var(--shadow-elevated)'"></app-rune>
      </div>
      <ion-modal [isOpen]="expandedCard" [animated]="false">
        <ng-template>
          <ion-header>
            <ion-toolbar>
              <ion-buttons slot="end">
                <ion-button (click)="expandCard(false)">
                  <ion-icon slot="icon-only" name="close-outline"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-header>
          <ion-content color="light" class="ion-padding ion-text-center ion-padding" (click)="expandCard(false)">
            <app-card class="card expanded" [userDeck]="userDeck" [cardId]="card.image" [reversed]="reverseCard"></app-card>
            <p *ngIf="product && product.publisher">
              {{'products.publisher.' + product.publisher + '.copyright' | translate}}
            </p>
          </ion-content>
        </ng-template>
      </ion-modal>
      <p *ngIf="product && product.publisher && !product.publisher.includes('labyrinthos')">
        {{'products.publisher.' + product.publisher + '.copyright' | translate}}
      </p>
    </ion-grid>

    <div class="keywords ion-margin-top">
      <div class="keywords-upright" *ngIf="!reverseCard">
        <ion-badge color="tertiary" *ngFor="let keyword of (translateVariable + '.keywords') | translate">{{keyword}}</ion-badge>
      </div>
      <div class="keywords-reversed" *ngIf="reverseCard">
        <ion-badge color="tertiary" *ngFor="let keyword of (translateVariable + '.keywords_reversal') | translate">{{keyword}}</ion-badge>
      </div>
    </div>

    <!-- meditations if they exist -->
    <div class="meditation round ion-margin-vertical ion-text-center" *ngIf="translate.instant('meditations.' + card.image + '.0') !== 'meditations.' + card.image + '.0' && translate.instant('meditations.' + card.image) !== ''">
      <div *ngIf="!reverseCard">
        <span class="capitals small">✦ Affirmation ✦</span>
        <h4>{{'meditations.' + card.image + '.0' | translate}}</h4>
      </div>
      <div *ngIf="reverseCard">
        <span class="capitals small">✦ Shift Your Thinking ✦</span>
        <h4>{{'meditations.' + card.image + '.reversed' | translate}}</h4>
      </div>
    </div>

    <div class="description ion-margin-top">
      <ion-text *ngIf="!reverseCard" [innerHTML]="translateVariable + '.description' | translate"></ion-text>
      <ion-text *ngIf="reverseCard" [innerHTML]="translateVariable + '.reversal' | translate"></ion-text>
    </div>
  </div>

  <!-- per card reading notes if they exist -->
  <ion-card class="ion-margin-top userNotes round" *ngIf="spread?.cards[index]?.notes?.length > 0 && permissions?.features?.indexOf('premium') > -1">
    <ion-card-header>
      <ion-avatar class="ion-float-left">
        <img src="../assets/avatars/avatar_0.png">
      </ion-avatar>
      <ion-card-title class="capitals">My Interpretation</ion-card-title>
      <ion-card-subtitle *ngIf="spread?.date"><strong>{{spread.date}}:</strong> {{spread.question}}</ion-card-subtitle>
    </ion-card-header>

    <ion-card-content>
      {{spread.cards[index].notes}}
    </ion-card-content>
  </ion-card>

  <!-- Relationship / Work / Finances -->
  <div *ngIf="spreadType == 'tarot' && card.image != 'major-24.png' && card.image != 'major-25.png' && auth">
    <ion-list-header mode="md" color="light">
      <ion-label>{{'card_modal.subtitle_categories' | translate}}</ion-label>
    </ion-list-header>
    <div class="pagination ion-margin">
      <ion-chip *ngFor="let topic of context; index as i" color="tertiary" [outline]="true" (click)="goToSlide(i)" [ngClass]="{'active': i == activeSlide}">{{'card_modal.categories.' + topic | translate}}</ion-chip>
    </div>
    <swiper #swiperRef (slideChange)="updateCurrentSlide($event)" [config]="config" class="ion-margin-bottom context-meanings">
      <ng-template swiperSlide *ngFor="let topic of context; index as i">
        <ion-card class="ion-margin-top ion-no-margin userNotes round ion-text-left">
          <ion-card-header>
            <strong>{{'card_modal.categories.' + topic | translate}}</strong>
          </ion-card-header>
          <ion-card-content [ngClass]="{'blur': !permissions?.features?.includes('premium')}">
            {{('meditations.' + card.image + '.' + topic + '.') + (reverseCard === true ? 'rev' : 'up') | translate}}
          </ion-card-content>
          <div class="premium-overlay ion-text-center" *ngIf="!permissions?.features?.includes('premium')">
            <p class="capitals">{{'card_modal.premium.subtitle' | translate}}</p>
            <h4>{{'card_modal.premium.title' | translate}}</h4>
            <p>{{'card_modal.premium.message' | translate}}</p>
            <ul class="ion-text-left" [innerHTML]="'card_modal.premium.list' | translate">
            </ul>
            <ion-button (click)="openPremium()">
              {{'purchases.premium.banner.title' | translate}}
            </ion-button>
          </div>
        </ion-card>
      </ng-template>
    </swiper>
  </div>

  <!-- tarot card properties -->
  <ion-grid *ngIf="spreadType == 'tarot'" class="icongrid">
    <ion-list-header mode="md" color="light">
      <ion-label>{{'card_modal.subtitle_properties' | translate}}</ion-label>
    </ion-list-header>
    <ion-row class="ion-text-center">
      <!-- tarot suits -->
      <ion-col size="4" id="suits-info">
        <div>
          <ion-icon class="corner" name="search-outline" color="tertiary"></ion-icon>
          <ion-icon class="info" src="assets/custom-ion-icons/{{card.type}}.svg" color="primary"></ion-icon>
          <ion-label text-wrap>
            <p>{{'_common.correspondence.suit' | translate}}</p>
            <p class="answer">{{'_common.suits.' + card.type | translate}}</p>
          </ion-label>
        </div>
      </ion-col>
      <ion-popover trigger="suits-info" class="ion-padding" side="bottom" alignment="center" size="auto">
        <ng-template>
          <p *ngIf="card.type != 'major'">{{'theory.' + card.type + '.subtitle' | translate}}</p>
          <p *ngIf="card.type == 'major'">{{'_common.suits.major_description' | translate}}</p>
        </ng-template>
      </ion-popover>
      <!-- tarot numerology -->
      <ion-col *ngIf="(card.number || card.number == 0) && card.type != 'major'" size="4" id="num-info">
        <div>
          <ion-icon class="corner" name="search-outline" color="tertiary"></ion-icon>
          <ion-icon src="assets/custom-ion-icons/{{card.number}}.svg" class="info" color="primary"></ion-icon>
          <ion-label text-wrap>
            <p>{{'_common.correspondence.num' | translate}}</p>
            <p class="answer capitalize">{{'_common.numbers.' + card.number | translate}}</p>
          </ion-label>
        </div>
      </ion-col>
      <ion-popover trigger="num-info" class="ion-padding" side="bottom" alignment="center" size="auto">
        <ng-template>
          <p>{{'_common.numbers.description.' + card.number | translate}}</p>
        </ng-template>
      </ion-popover>
      <!-- tarot astrology correspondences -->
      <ion-col *ngIf="card.astro" size="4" id="astro-info">
        <div>
          <ion-icon class="corner" name="search-outline" color="tertiary"></ion-icon>
          <ion-icon *ngFor="let icon of getAstroIcons(card.astro); index as i" src="assets/custom-ion-icons/{{icon}}.svg" color="primary" class="info"></ion-icon>
          <ion-label text-wrap>
            <p>{{'_common.correspondence.astro' | translate}}</p>
            <p class="answer" *ngIf="{ card: card.astro.split('__')[1], plain: card.astro } as astro">
              <span *ngIf="!astro.card">{{ astro.plain }}</span>
              <span *ngIf="astro.card">
                {{ 'astro.' + astro.card + '.title' | translate }}
              </span>
            </p>
          </ion-label>
        </div>
      </ion-col>
      <ion-popover trigger="astro-info" class="ion-padding" side="bottom" alignment="center" size="auto" *ngIf="card.astro && { card: card.astro.split('__')[1], plain: card.astro } as astro">
        <ng-template>
          <p *ngIf="astro.card">{{ 'astro.' + astro.card + '.description' | translate }}</p>
          <p *ngIf="!astro.card">All minor arcana cards correspond to certain parts of the celestial wheel.</p>
        </ng-template>
      </ion-popover>
      <!-- tarot elements -->
      <ion-col *ngIf="card.element" size="4" id="element-info">
        <div>
          <ion-icon class="corner" name="search-outline" color="tertiary"></ion-icon>
          <ion-icon src="assets/custom-ion-icons/{{card.element}}.svg" class="ion-margin-horizontal info" color="primary"></ion-icon>
          <ion-label text-wrap>
            <p>{{'_common.correspondence.element' | translate}}</p>
            <p class="answer">
              {{ '_common.astro.' + card.element + '.title' | translate }}
            </p>
          </ion-label>
        </div>
      </ion-col>
      <ion-popover trigger="element-info" class="ion-padding" side="bottom" alignment="center" size="auto">
        <ng-template>
          <p>{{ '_common.astro.' + card.element + '.description' | translate }}</p>
        </ng-template>
      </ion-popover>
      <!-- tarot yes or no -->
      <ion-col lines="none" size="4">
        <div>
          <ion-icon class="ion-margin-horizontal info" size="large" src="assets/custom-ion-icons/{{card.yn+''}}.svg" color="primary"></ion-icon>
          <ion-label text-wrap>
            <p>{{'_common.correspondence.yn' | translate}}</p>
            <p class="answer">
              <span *ngIf="card.yn === true">{{'_common.correspondence.yes' | translate}}</span>
              <span *ngIf="card.yn === false">{{'_common.correspondence.no' | translate}}</span>
              <span *ngIf="card.yn === undefined">{{'_common.correspondence.maybe' | translate}}</span>
            </p>
          </ion-label>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!-- astrology card properties -->
  <ion-list class="properties" *ngIf="spreadType == 'astro' && cardProperties.length > 0">
    <ion-list-header mode="md" color="light">
      <ion-label>Card Properties</ion-label>
    </ion-list-header>
    <ion-item *ngFor="let property of cardProperties" lines="none">
      <ion-label text-wrap>
        <p class="capitals">{{property.title | translate}}</p>
        <p>{{property.description | translate}}</p>
      </ion-label>
    </ion-item>
  </ion-list>

  <!-- tarot custom notes -->
  <ion-list class="properties" *ngIf="spreadType == 'tarot' && auth && (userData.notes_up || !spread)">
    <ion-list-header mode="md" color="light">
      <ion-label>{{ 'card_modal.notes' | translate}}</ion-label>
      <!-- should only show if not coming from reading, when using openCardSimple -->
      <div class="buttons" *ngIf="!spread">
        <ion-button (click)="editNotes = true" *ngIf="!editNotes" size="small" fill="outline">
          <ion-label>Edit</ion-label>
        </ion-button>
        <ion-button (click)="deleteUserNotes()" *ngIf="userData.notes_up" size="small" fill="outline" color="tertiary">
          <ion-label>Delete</ion-label>
        </ion-button>
      </div>
    </ion-list-header>
    <ion-item lines="none" *ngIf="updatedUserData && userData">
      <ion-label text-wrap *ngIf="!editNotes" class="placeholder">
        <p *ngIf="!userData.notes_up && !updatedUserData.notes_up"><em [innerHTML]="'card_modal.notes_empty' | translate"></em></p>
        <p *ngIf="userData.notes_up || updatedUserData.notes_up">{{updatedUserData.notes_up}}</p>
      </ion-label>
      <ion-textarea placeholder="{{ 'card_modal.notes_placeholder' | translate }}" *ngIf="editNotes" maxlength="360" [(ngModel)]="updatedUserData.notes_up" autoGrow="true"></ion-textarea>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer mode="md" class="animated slideInUp" *ngIf="spreadType == 'tarot' && (userData.notes_up || !spread) && editNotes">
  <ion-toolbar color="secondary">
    <ion-title class="ion-text-center">
      <ion-button (click)="saveUserNotes()" fill="outline" size="small" [disabled]="!updatedUserData.notes_up">
        <ion-label>Save Note</ion-label>
      </ion-button>
      <ion-button (click)="cancelUserNotes()" fill="outline" size="small" color="danger">
        <ion-label>Cancel</ion-label>
      </ion-button>
    </ion-title>
  </ion-toolbar>
</ion-footer>

<ion-footer mode="md" *ngIf="!editNotes">
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-button (click)="prevCard()" shape="round" fill="clear" [disabled]="!prev || spread && !prev.card_id">
        <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center" *ngIf="card.url">
      <ion-button size="small" fill="outline" (click)="openPage(card.url)">
        {{'card_modal.more' | translate}}
      </ion-button>
      <ion-button (click)="presentPopover($event)" shape="round" size="small" fill="clear" *ngIf="language != 'en'">
        <ion-icon slot="icon-only" name="information-circle-outline"></ion-icon>
      </ion-button>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="nextCard()" shape="round" fill="clear" [disabled]="!next || spread && !next.card_id">
        <ion-icon slot="icon-only" name="arrow-forward"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

<app-premium #premium></app-premium>
