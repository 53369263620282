import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { User } from '../../user';
import { ProductService } from '../../services/product.service';
import { ReadingService } from '../../services/reading.service';

@Component({
  selector: 'app-reading-settings',
  templateUrl: './reading-settings.component.html',
  styleUrls: ['./reading-settings.component.scss'],
})
export class ReadingSettingsComponent implements OnInit {
  @Input() user: User;
  @Input() spreadType: string;
  @Input() spreadSettings: any;
  @Input() showAuto: boolean;
  @Output() settingsChanged = new EventEmitter<{ settingsChanged: any }>();
  deck: string;
  searchText: string;
  searchedDecks: Array<any> = [];
  subsetAlertOptions: any = {
    header: 'Filter Cards',
    message: 'You can select more than one category. Unselect everything to use your full deck.',
    cssClass: 'subsetSelect'
  };
  subsetOptions: Array<any> = [
    {
      value: 'major',
      text: '_common.suits.major'
    },
    {
      value: 'wands',
      text: '_common.suits.wands'
    },
    {
      value: 'cups',
      text: '_common.suits.cups'
    },
    {
      value: 'swords',
      text: '_common.suits.swords'
    },
    {
      value: 'pentacles',
      text: '_common.suits.pentacles'
    },
    {
      value: 'court',
      text: '_common.suits.court'
    },
    {
      value: 'numbered',
      text: '_common.suits.numbered'
    }
  ];

  constructor(
    public readingService: ReadingService,
    private productService: ProductService,
  ) { }

  setFilteredItems() {
    if (this.searchText) {
      this.searchedDecks = this.productService.getOwnedDecksByType(this.spreadType).filter((deck) => {
        if (deck.name) {
          return deck.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1;
        }
      });
    } else {
      this.searchedDecks = this.productService.getOwnedDecksByType(this.spreadType);
    }
  }

  clearSubset() {
    this.spreadSettings.subset = [];
  }

  updateSettings() {
    // when settings are updated, send to parent component to do various things
    this.settingsChanged.emit({ settingsChanged: this.spreadSettings });
  }

  ngOnInit() {
    this.searchedDecks = this.productService.getOwnedDecksByType(this.spreadType);
    this.deck = this.user[this.spreadType + 'Deck'];

    // default is digital type
    if (!this.user.readingType) {
      this.user.readingType = 'digital';
    }

    // when on default settings, you should both get and update spread settings from the user object.
    // otherwise, don't touch user object, you'll be passed this data from outside, and you'll edit that data too.
    if (!this.spreadSettings) {
      this.spreadSettings = {
        reversals: this.user.reversals,
        autoSave: this.user.autoSave,
        readingType: this.user.readingType,
        pickCards: this.user.pickCards,
        shuffle: this.user.shuffle,
        subset: this.user.subset,
        deck: this.deck
      };
    }
  }

}
