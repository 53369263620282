<ion-app>
  <ion-split-pane [when]="false" content-id="main">
    <!--  our side menu, on desktop it's on a different side  -->
    <ion-menu content-id="main" [side]="menuPlacement" [ngClass]="{'web': web}">
      <app-menu></app-menu>
    </ion-menu>

    <!-- the main content -->
    <ion-router-outlet main id="main"></ion-router-outlet>
  </ion-split-pane>

  <app-premium #premium></app-premium>
</ion-app>
