export const environment = {
  production: true,
  labyrinthosAPI: 'https://labyrinthos.herokuapp.com/',
  RCApple: 'appl_ODCwjRsbIdSldhbKfzwtnaoPVcZ',
  RCAndroid: 'goog_AoouQLijCvDoHRAiiqdsQGlzLZt',
  RCWeb: 'rcb_GhkgsfhAXAtQziqBKuHytAquzVzv',
  testTransactions: false,
  callCost: 33,
  premiumMinutes: 10, 
  maxFreeLogs: 100,
  maxFreeSpreads: 100,
  maxLogsPerPage: 100,
  firebaseConfig: {
    apiKey: "AIzaSyD5KHOHmnosevehXp3huVDtshHe3nqDynk",
    authDomain: "labyrinthos-app.firebaseapp.com",
    databaseURL: "https://labyrinthos-app.firebaseio.com",
    projectId: "labyrinthos-app",
    storageBucket: "labyrinthos-app.appspot.com",
    messagingSenderId: "1041874819558",
    appId: "1:1041874819558:web:db12ec0ddba2c1ea4f1e27",
    measurementId: "G-8ST09J33MR"
  }
};
