<ion-header>
  <ion-toolbar>
    <ion-title>Call Completed</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card class="round" color="light">
    <ion-item color="light" lines="none">
      <ion-avatar slot="start">
        <img src="../assets/readers/{{reader}}-avatar.png">
      </ion-avatar>
      <ion-label textwrap>
        <h3>Call with {{reader}}</h3>
        <!-- Phase 1 -->
        <p><strong>Minutes Used:</strong> {{usedFreeMinutes?.toFixed(2)}}</p>
        <p><strong>Minutes Remaining this Month:</strong>  {{remainingFreeMinutes?.toFixed(2)}}</p>
      </ion-label>
      <ion-text slot="end">
        <!-- Phase 2 -->
        <!-- {{cost}} <img src="../assets/catssandra/credits.png"> -->
      </ion-text>
    </ion-item>
    <ion-card-content *ngIf="!sentReview">
      <p><strong>How did it go?</strong></p>
      <app-rating (ratingChange)="onRatingChange($event)"></app-rating>
      <ion-textarea [(ngModel)]="review"></ion-textarea>
      <ion-button (click)="submit()">
        Leave Review
      </ion-button>
    </ion-card-content>
  </ion-card>

  <app-transcript [transcript]="transcript" [reader]="reader"></app-transcript>
</ion-content>

<ion-footer class="animated fadeInUp" color="primary">
  <ion-toolbar color="primary" class="ion-text-center">
    <ion-button (click)="addTranscriptToLog()" [disabled]="!transcript">
      Save Transcript and Dismiss
    </ion-button>
  </ion-toolbar>
</ion-footer>
