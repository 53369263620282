import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuController, ModalController, LoadingController } from '@ionic/angular';
import { User } from '../../user';
import { UserService } from '../../services/user.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, RouterModule, Router, NavigationExtras } from '@angular/router';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { NotificationPage } from '../../modals/notification/notification.page';
import { DefaultSettingsPage } from '../../modals/default-settings/default-settings.page';
import { ReadingService } from '../../services/reading.service';
import { RateService } from '../../services/rate.service';
import { ThemeService } from '../../services/theme.service';
import { PurchaseService } from '../../services/purchase.service';
import { ModalService } from '../../services/modal.service';
import { LogService } from '../../services/log.service';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  constructor(
    private modalController: ModalController,
    private menu: MenuController,
    private loadingController: LoadingController,
    private router: Router,
    private socialSharing: SocialSharing,
    private iab: InAppBrowser,
    private userService: UserService,
    public readingService: ReadingService,
    private rateService: RateService,
    public themeService: ThemeService,
    public purchaseService: PurchaseService,
    private modalService: ModalService,
    private logService: LogService,
  ) { }

  userUpdates: Subscription;
  user: any;
  spreadType: string = "tarot";
  id: string = "daily";
  birthcards: string;
  web: boolean = Capacitor.getPlatform() == 'web';
  pages = [
    {
      title: 'settings',
      login: true,
      web: true,
      icon: 'person-circle-outline',
      handler: () => { this.navigate('/account/undefined') }
    },
    {
      title: 'reading_settings',
      login: true,
      web: true,
      icon: 'settings-outline',
      handler: () => { this.readingService.openSettings(this.user, this.spreadType, this.id) }
    },
    {
      title: 'about',
      login: false,
      web: true,
      icon: 'information-circle-outline',
      handler: () => { this.navigate('/about') }
    },
    {
      title: 'help',
      login: false,
      web: true,
      icon: 'help-circle-outline',
      handler: () => { this.openBrowser('https://help.labyrinthos.co/') }
    },
    {
      title: 'vote',
      login: false,
      web: true,
      icon: 'trail-sign-outline',
      handler: () => { this.openBrowser('https://labyrinthos.frill.co/b/kvj5dqme/feature-ideas?topic=topic_ndgpgr5d') }
    },
    {
      title: 'rate',
      login: true,
      web: false,
      icon: 'star-outline',
      handler: () => { this.rateApp() }
    },
    {
      title: 'share',
      login: false,
      web: false,
      icon: 'share-outline',
      handler: () => { this.shareApp() }
    },
    {
      title: 'notifications',
      login: false,
      web: false,
      icon: 'notifications-outline',
      handler: () => { this.openNotifications() }
    },
    {
      title: 'restore',
      login: false,
      web: false,
      icon: 'refresh-outline',
      handler: () => { this.purchaseService.restore() }
    },
    {
      title: 'logout',
      login: false,
      web: true,
      icon: 'log-out-outline',
      handler: () => { this.presentLogoutLoading() }
    }
  ];
  pagesPublic = {
    social: [
      {
        title: 'instagram',
        icon: 'logo-instagram',
        handler: () => { this.openBrowser('https://instagram.com/labyrinthostarot') }
      },
      {
        title: 'tiktok',
        icon: 'logo-tiktok',
        handler: () => { this.openBrowser('https://pinterest.com/gttarot') }
      },
      {
        title: 'pinterest',
        icon: 'logo-pinterest',
        handler: () => { this.openBrowser('https://tiktok.com/@labyrinthostarot') }
      }
    ],
    main: [
      {
        title: 'signup',
        icon: 'person-circle-outline',
        handler: () => { this.goToAuth('signup') }
      },
      {
        title: 'login',
        icon: 'log-in-outline',
        handler: () => { this.goToAuth('login') }
      },
      {
        title: 'shop',
        icon: 'bag-handle-outline',
        handler: () => { this.openBrowser('https://labyrinthos.co/collections/all') }
      },
      {
        title: 'about',
        icon: 'newspaper-outline',
        handler: () => { this.navigate('about') }
      },
      {
        title: 'pricing',
        icon: 'pricetag-outline',
        handler: () => { this.navigate('pricing') }
      }
    ],
    download: [
      {
        title: 'android',
        icon: 'logo-google-playstore',
        handler: () => { this.openBrowser('https://play.google.com/store/apps/details?id=com.labyrinthos.app&hl=en_US') }
      },
      {
        title: 'apple',
        icon: 'logo-apple',
        handler: () => { this.openBrowser('https://itunes.apple.com/us/app/labyrinthos-academy-online-tarot-classes/id1155180220?mt=8') }
      }
    ]
  };

  handle(fxn) {
    fxn();
    this.menu.close();
  }

  openBrowser(url) {
    const browser = this.iab.create(url, '_system');
  }

  async openNotifications() {
    const modal = await this.modalController.create({
      component: NotificationPage,
    });

    return await modal.present();
  }

  async openReadingSettings() {
    const modal = await this.modalController.create({
      component: DefaultSettingsPage
    });

    return await modal.present();
  }

  rateApp() {
    this.rateService.firstAlert();
  }

  shareApp() {
    console.log('calling share function...');
    var options = {
      message: 'Check out the Labyrinthos Tarot App!', // not supported on some apps (Facebook, Instagram)
      subject: 'Check out the Labyrinthos Tarot App!', // fi. for email
      url: 'https://app.labyrinthos.co',
      chooserTitle: 'Share on...' // Android only, you can override the default share sheet title
    };
    this.socialSharing.shareWithOptions(options);
  }

  async presentLogoutLoading() {
    const loading = await this.loadingController.create({
      message: 'Logging out...'
    });
    await loading.present();
    Promise.resolve()
    .then(() => {
      return this.userService.clearData();
    })
    .then(() => {
      this.logService.publishSubscription('logs', []);
    })
    .then(() => {
      this.themeService.setTheme('day');
    })
    .then(() => {
      this.dismissLogoutLoading();
      this.navigate('/auth');
    });
  }

  async dismissLogoutLoading() {
    const loading = await this.loadingController.getTop();
    loading.dismiss();
  }

  getLevelPercent() {
    // get experince and percent - trust only exp
    var nextLevel = this.userService.getNextLevel(this.user.exp);
    var nextLevelExp = this.userService.getExpByLevel(nextLevel);
    var thisLevelExp = this.userService.getExpByLevel(nextLevel - 1);
    var expToNextLevel = nextLevelExp - thisLevelExp;
    var currentExpToNextLevel = this.user.exp - thisLevelExp;
    return currentExpToNextLevel / expToNextLevel;
  }

  clickAvatar() {
    if (this.user.email) {
      this.navigate('/account/birthcards')
    } else {
      this.modalService.openSignup();
    }
    this.menu.close();
  }

  navigate(url) {
    this.router.navigateByUrl(url);
  }

  goToAuth(form: string) {
    var routerURL: string = 'auth';
    let navigationExtras: NavigationExtras = {
      state: {
        form: form
      }
    };
    this.router.navigate([routerURL], navigationExtras);
  }

  ngOnDestroy() {
    this.userUpdates.unsubscribe();
  }

  ngOnInit() {
    this.userUpdates = this.userService.getSubscription('userSubscription').subscribe((data) => {
      this.user = data;
      if (data.user_data?.birthdate) {
        const birthdate = this.userService.parseBirthDate(data.user_data.birthdate);
        this.birthcards = this.userService.getBirthCards(birthdate.month, birthdate.date, birthdate.year);
      };
    });
  }
}
