<ion-header>
  <ion-toolbar>
    <ion-title>Default {{'reading_settings.title' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-item>
    <ion-label text-wrap>
      Choose your default settings for your readings. You can still customize individual readings before you begin them. Changes apply in the next reading.
    </ion-label>
  </ion-item>
  <ion-radio-group value="user.dailyType" allow-empty-selection="false" [(ngModel)]="user.dailyType" *ngIf="spreadType == 'tarot'">
    <ion-item-divider mode="md">
      <ion-icon name="sunny-outline" slot="start"></ion-icon>
      <ion-label class="capitals">
        Tarot {{'reading_settings.daily' | translate}}
        <ion-icon color="primary" name="help-circle-outline" slot="end" id="dailyPop"></ion-icon>
      </ion-label>
      <ion-popover trigger="dailyPop" mode="ios">
        <ng-template>
          <p>For your daily readings screen, do you want single card readings or 3 card readings for your tarot readings?</p>
        </ng-template>
      </ion-popover>
    </ion-item-divider>
    <ion-item>
      <ion-label>{{'reading_settings.daily_3' | translate}}</ion-label>
      <ion-radio slot="end" value="daily" mode="md"></ion-radio>
    </ion-item>
    <ion-item>
      <ion-label>{{'reading_settings.daily_1' | translate}}</ion-label>
      <ion-radio slot="end" value="single" mode="md"></ion-radio>
    </ion-item>
  </ion-radio-group>

  <app-reading-settings [user]="user" [spreadType]="spreadType" (settingsChanged)="onSettingsChanged($event)" [showAuto]="true"></app-reading-settings>
</ion-content>

<ion-footer class="ion-no-border" mode="ios">
  <ion-toolbar color="primary">
    <ion-button (click)="saveSettings()" color="primary" expand="full">
      {{'reading_settings.update' | translate}}
    </ion-button>
  </ion-toolbar>
</ion-footer>
