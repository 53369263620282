import { Injectable } from '@angular/core';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConnectivityService {
  constructor() {
    this.initConnectivityMonitoring();
  }

  public onlineOffline: Observable<boolean>;

  initConnectivityMonitoring() {
    if (!window || !navigator || !('onLine' in navigator)) return;
    this.onlineOffline = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    ).pipe(map(() => navigator.onLine))
  }
}
