import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';
import { SpreadService } from './spread.service';
import { UserService } from './user.service';
import { TarotService } from './tarot.service';
import { ToastController } from '@ionic/angular';
import { ActivatedRoute, RouterModule, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '../services/analytics.service';
import { Spread } from '../spread';
import { User } from '../user';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(
    private storage: Storage,
    private httpClient: HttpClient,
    private spreadService: SpreadService,
    private userService: UserService,
    private tarotService: TarotService,
    private toastController: ToastController,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private analyticsService: AnalyticsService,
  ) {
    this.init();
  }

  maxFreeLogs: number = environment.maxFreeLogs;
  maxFreeSpreads: number = environment.maxFreeSpreads;
  logs = new Subject<any>();
  spreads = new Subject<any>();
  dailyLog = new Subject<any>();
  returnUrl = new Subject<any>();
  url = environment.labyrinthosAPI + 'user/backups';
  httpOptions: any = {
    headers: new HttpHeaders()
  };
  user: User;
  userUpdates: Subscription;

  init() {
    // check & set token
    this.storage.get('token').then(data => {
      this.httpOptions.headers = this.httpOptions['headers'].set('Authorization', 'Token ' + data);
    });

    this.userService.getUser().then(data => {
      this.user = data;
    });

    this.userUpdates = this.userService.getSubscription('userSubscription').subscribe((data) => {
      this.user = data;
    });
  }

  getSubscription(name) {
    return this[name];
  }

  publishSubscription(name, data) {
    this[name].next(data);
  }

  getEarliestNLogs(n: number) {
    // gets ids of last n logs for multi-delete
    return this.storage.get('logs').then(data => {
      if (data) {
        var sorted = this.sortLogsDate(data, 'date');
        var lastN = sorted.slice(Math.max(sorted.length - n, 0));
        return lastN.map((log) => { return log.id });
      }
    })
  }

  setOmnisendTags(logArray: Array<any>) {
    // count total logs - tag omnisend if over certain numbers
    const totalLogPoints = [1, 10, 30, 90, 200];
    const tags = [];

    for (let point of totalLogPoints) {
      if (logArray.length == point) {
        tags.push("logcount: " + point);
      }
    }

    if (tags.length > 0) {
      this.userService.setTag(tags);
    }
  }

  // ------ UTILITY FUNCTIONS ------ //
  convertDate(dateObj) {
    return (dateObj.getMonth() + 1) + '/' + dateObj.getDate() + '/' +  dateObj.getFullYear();
  }

  checkDuplicates(type, readingId) {
    return this.getPastIds(type).then(data => {
      if (data && data.indexOf(readingId) > -1) {
        return true;
      } else {
        return false;
      }
    });
  }

  checkNonDuplicates(arr1: Array<any>, arr2: Array<any>) {
    if (arr1.length > 0 && arr2.length > 0) {
      return arr1.filter(o1 => !arr2.some(o2 => o1.id === o2.id));
    } else {
      if (arr1.length == 0) {
        return arr2;
      } else {
        return arr1;
      }
    }
  }

  sortLogsAlphabetical(logs, p) {
    if (logs && p) {
      return logs.sort(function(a, b){
        if(a[p] < b[p]) { return -1; }
        if(a[p] > b[p]) { return 1; }
        return 0;
      });
    }
  }

  sortLogsDate(logs, p) {
    // this returns latest, p == parameter, like date
    if (logs && p) {
      return logs.sort(function(a, b) {
        a = new Date(a[p]);
        b = new Date(b[p]);
        return a>b ? -1 : a<b ? 1 : 0;
      });
    }
  }

  async getPastIds(type) {
    const ids = [];
    return this.storage.get(type).then(data => {
      if (data) {
        for (let log of data) {
          ids.push(log.id);
        }
      }
      return ids;
    })
  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ':00 ' + ampm;
    return strTime;
  }

  checkFinished(cardArray) {
    let result = true;
    for (let card of cardArray) {
      if (!card.card_id) {
        result = false;
        break;
      }
    }
    return result;
  }

  // ------ DAILY READINGS STORAGE FUNCTIONS ------ //
  async getDaily() {
    return await this.storage.get('daily').then(data => {
      if (data) {
        return data;
      }
    });
  }

  async saveDaily(log: any) {
    var data = await this.storage.get('daily') ?? [];
    // push new log
    data.push(log);
    // remove logs not from today
    data = data.filter(l => l.date == log.date);
    return this.storage.set('daily', data).then(
      data => console.log('Stored daily log to temporary storage', data),
      error => console.error('Error storing item in temporary storage', error)
    );
  }

  // ------ STORAGE FUNCTIONS ------ //
  async getLogs(type: string, page?: number) {
    if (page) {
      var pageSize = environment.maxLogsPerPage;
      var logs = await this.storage.get(type);
      return logs?.slice((page - 1) * pageSize, page * pageSize);
    } else {
      return await this.storage.get(type);
    }
  }

  async totalLogCount(type) {
    var logs = await this.storage.get(type);
    return logs.length;
  }

  async getXLogs(number: number) {
    return this.storage.get('logs').then(data => {
      console.log('getting log data for getXLogs', data);
      if (data) {
        this.sortLogsDate(data, 'date').reverse();
        return data.slice(Math.max(data.length - number, 0)).reverse();
      }
    });
  }

  async getLog(type, id) {
    return await this.storage.get(type).then(data => {
      if (data) {
        return data.filter((log) => {
          return log.id == id;
        })[0];
      }
    });
  }

  calcUpgradeMax(type: 'logs' | 'spreads') {
    var maxFree = 'maxFree' + type.charAt(0).toUpperCase() + type.slice(1);
    var maxFreeNum = this[maxFree];
    var permissions = this.user?.permissions?.journals;

    if (permissions) {
      var upgrades = permissions.map((j) => {
        return parseInt(j.split('_')[1]);
      });
      upgrades.push(maxFreeNum);
      var sum = upgrades.reduce((a, b) => a + b, 0);
      return sum;
    } else {
      return this[maxFree];
    }
  }

  checkAtLogLimit(type: 'logs' | 'spreads') {
    // returns true if past or at limit, returns false if have not reached the log limit yet.
    const max = this.calcUpgradeMax(type);
    // console.log('maximum amount of logs is: ', max);
    return this.storage.get(type).then(data => {
      if (data?.length >= max) {
        return true;
      } else {
        return false;
      }
    });
  }

  async saveLog(type, toAPI, log, skipMax?: boolean, handler?: any) {
    // type can be 'spreads' or 'logs'
    var premium = this.user?.permissions?.features?.indexOf('premium') > -1;
    var correctType = type == 'logs';
    if (skipMax) {
      var maxLogs = false;
    } else {
      var maxLogs = await this.checkAtLogLimit(type);
    }

    // check cases:
    // yes premium, at max > correct
    // no premium, at max
    // yes premium, not max
    // no premium, not max

    if (maxLogs && !premium && correctType) {
      console.log('error, show modal to upgrade...', maxLogs);
      if (handler) {
        handler();
      };
      return false;
    } else {
      console.log('success, saving log to storage...', type, log.id, log.app);
      try {
        let data = await this.storage.get(type);
        if (!data) {
          data = [log];
        } else {
          data.unshift(log);
        };

        if (type == 'logs') {
          this.publishSubscription('logs', data);
          this.setOmnisendTags(data);
        } else if (type == 'spreads') {
          this.publishSubscription('spreads', data);
        };

        await this.storage.set(type, data);

        if (toAPI) {
          await this.saveAPILog(type, log);
        }
      } catch (error) {
        console.error('Error storing item', error);
        this.presentToast(`Error: ${error}`, 'danger');
      }
    }
  }

  editLog(type, id, logData) {
    console.log('editing this log...', logData);
    return this.getLogIndex(type, id).then(index => {
      return this.getLogs(type).then(logs => {
        // console.log(`getting ${type} logs`, logs);
        logs[index] = logData;
        return this.storage.set(type, logs).then(data => {
          console.log(`Updated ${type}`, id, logData.app, logs);

          if (logData.app == 'v2') {
            this.publishSubscription('logs', logs);
          };
          if (logData.app == 'v2-spreads') {
            this.publishSubscription('spreads', logs);
          };

          // if this is a reading, it will have an app parameter.
          // otherwise, appName for editAPILog function should be 'v2-spreads'.
          if (logData.app) {
            return this.editAPILog(logData.app, logData);
          } else {
            return this.editAPILog('v2-spreads', logData)
          };
          // when score changes, we also have to publish it
        });
      });
    });
  }

  async getLogIndex(type, logId) {
    const index = this.storage.get(type).then(data => {
      return data.findIndex(log => {
        return log.id == logId;
      },
      error => console.error('Error storing item', error));
    });
    return index;
  }

  async deleteLog(type, logId) {
    return this.getLogIndex(type, logId).then(index => {
      return this.getLogs(type).then(logs => {
        // need to get app name... use deck property
        // if type is spread, the deck is 'v2-spreads'

        if (logs) {
          var app;
          if (type == 'spreads') {
            app = 'v2-spreads';
          } else {
            app = logs[index].app;
          };

          logs.splice(index, 1);
          return this.storage.set(type, logs).then(data => {
            console.log('Deleted ' + type + ' from storage', logId);
          }).then(() => {
            this.publishSubscription(type, logs);
            this.deleteLogAPI(app, logId);
          });
        }
      });
    });
  }

  async deleteLogs(type: string, logIds: Array<string>) {
    return this.getLogs(type).then((logs) => {
      var keep = logs.filter((log) => {
        return logIds.indexOf(log.id) === -1;
      });
      return this.storage.set(type, keep).then(data => {
        console.log('Deleted ' + type + ' from storage', logIds, data);
        this.publishSubscription(type, keep);
      }).then(() => {
        logIds.forEach(id => {
          return this.deleteLogAPI('v2', id);
        });
      });
    });
  }

  async filterLogs(searchText) {
    return this.storage.get('logs').then(data => {
      if (!searchText) {
        return data;
      } else {
        return data.filter((log) => {
          if (log.question) {
            // some corrupted logs don't have questions...
            if (log.notes) {
              return log.question.toLowerCase().indexOf(searchText.toLowerCase()) > -1 || log.notes.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
            } else {
              return log.question.toLowerCase().indexOf(searchText.toLowerCase()) > -1
            }
          }
        });
      }
    });
  }

  async filterLogsByDate(startDate: Date, endDate: Date, logs: Array<any>) {
    var resultData = logs.filter((log) => {
      return new Date(log.date).getTime() >= startDate.getTime() &&
             new Date(log.date).getTime() <= endDate.getTime();
    });
    return resultData;
  }

  async filterLogsByX(key:string, value:any, logs?:any, negative?:boolean) {
    // if logs parameter exists, filter through those logs. Otherwise filter through all logs.
    if (!logs) {
      var logs = await this.getLogs('logs');
    };
    return logs.filter((log) => {
      if (log[key]) {
        if (!negative) {
          return log[key].includes(value);
        } else {
          return !log[key].includes(value);
        }
      }
    });
  }

  async filterLogsMulti(key:string, values:Array<string>, logs?:any, negative?:boolean) {
    if (!logs) {
      var logs = await this.getLogs('logs');
    };
    return logs.filter((log) => {
      if (log[key]) {
        if (!negative) {
          return values.includes(log[key]);
        } else {
          return !values.includes(log[key]);
        }
      }
    });
  }

  async filterLogsByCards(values:Array<string>, logs:Array<any>) {
    return logs.filter((log) => {
      if (Array.isArray(log.cards)) {
        var cards = log.cards.map((card) => {
          return card.card_id;
        });
      }

      if (cards) {
        var filtered = cards.filter(id => values.includes(id));
        return filtered.length > 0;
      };
    });
  }

  async getStarredLogs() {
    return this.storage.get('logs').then(data => {
      if (data) {
        var starred = data.filter((log) => {
          return log.starred;
        });
        return starred;
      }
    });
  }

  // ------ API FUNCTIONS ------ //
  editAPILog(appName, log) {
    console.log('editing log in DB for ' + log.id);
    const params = new HttpParams().set('app', appName);
    this.httpOptions['params'] = params;

    const newLog = this.httpClient.put(
      this.url + '/by-id',
      {
        app: appName,
        foreign_id: log.id,
        log_data: JSON.stringify(log)
      },
      this.httpOptions
    );

    return newLog.subscribe(
      data => {
        console.log('data edited in api!');
        return true;
      },
      error => {
        console.log('data failed edit in api!', error);
        return false;
      }
    );
  }

  saveAPILog(type, log) {
    let storageType;
    if (type == 'logs') { storageType = 'v2' }
    else if (type == 'spreads') { storageType = 'v2-spreads' }
    else if (type == 'userData') { storageType = 'userData' }
    else { storageType = null }

    // need to set this everytime Tina!!!
    const params = new HttpParams().set('app', storageType);
    this.httpOptions['params'] = params;
    console.log(`Saving ${storageType} log in DB for: `, log.id);

    const newLog = this.httpClient.post(
      this.url,
      {
        app: storageType,
        foreign_id: log.id,
        log_time: log.date + ', ' + this.formatAMPM(new Date()),
        log_data: JSON.stringify(log)
      },
      this.httpOptions
    );

    return newLog.subscribe (
      data => {
        console.log('data saved to api!', data);
      },
      error => {
        console.log('data failed to save to api!', error);
      }
    );
  }

  // fix for Tina's 7 year bug...
  cleanLogs(app: string, data: any, commonId: string) {
    var errorData = data
      .filter(score => score.foreign_id != commonId)
      .map((s, i) => {
        return {
          id: s.foreign_id,
          i: i
        }
      });
    for (let log of errorData) {
      data.splice(log.i, 1);
      this.deleteLogAPI(app, log.id);
    }
    return data;
  }

  async getAPILogs(app) {
    console.log('pulling logs for ' + app);
    // only way to make sure the data is pulled is by getting the token again...
    return this.storage.get('token').then((token) => {
      this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Token ' + token);
      const params = new HttpParams().set('app', app);
      this.httpOptions['params'] = params;

      const logs = this.httpClient.get(
        this.url,
        this.httpOptions
      );

      return logs.subscribe(
        data => {
          // append to localstorage logs
          if (app == 'golden-thread') {
            return this.parseGTTLogs(data);
          }
          if (app == 'luminous-spirit') {
            return this.parseLSTLogs(data);
          }
          if (app == 'seventh-sphere') {
            return this.parseSSTLogs(data);
          }
          if (app === 'v2') {
            return this.parseV2LogsV2('logs', data);
          }
          if (app === 'v2-spreads') {
            return this.parseV2LogsV2('spreads', data);
          }
          if (app == 'userData') {
            return this.parseV2Logs('userData', data);
          }
          if (app === 'score') {
            // we are moving this to user_data -- download and convert, and delete rest of logs.
            if (data && data[0]) {
              var cleanedData = this.cleanLogs('score', data, 'score');
              var latest = cleanedData.pop();
              var logData = JSON.parse(latest.log_data);
              delete logData.app;
              delete logData.date;
              delete logData.id;
              this.userService.setUserData('score', logData, true);
              this.deleteLogAPI('score', 'score');
              this.storage.remove('score');
            }
          }
        },
        error => {
          console.log(error);
        }
      )
    });
  }

  isLog(x: any) {
    // do needed checks, returning true or false
    return (
      typeof x?.id === 'string' &&
      typeof x?.date === 'string' &&
      typeof x?.image === 'string' &&
      typeof x?.deck === 'string' &&
      Array.isArray(x?.cards)
    )
  }

  async parseExportedLogs(type, data, n?: number) {
    console.log('parsing logs for uploaded logs', data);
    var invalid: Array<any> = [];
    var duplicate: Array<any> = [];
    var count: number = 0;
    var premium = this.user?.permissions?.features?.indexOf('premium') > -1;
    var maxLogs = await this.checkAtLogLimit('logs');

    for (let log of data) {
      // check if premium user and log limit has not been reached.
      if ((premium || !maxLogs) && count < n) {
        var valid = this.isLog(log);
        if (valid) {
          await this.checkDuplicates(type, log.id).then(response => {
            const dupe = response;
            if (!response) {
              console.log('not a duplicate already in storage', log);
              count++;
              this.saveLog(type, true, log).then(data => {return data});
            } else {
              duplicate.push(log.id);
            }
          });
        } else {
          invalid.push(log.id);
        }
      } else {
        break;
      }
    }
    if (invalid.length > 0) {
      this.presentToast(this.translate.instant('log_limits.toasts.import_success', {
        count: count,
        total: data.length,
        invalid: invalid.length,
        duplicate: duplicate.length
      }), 'success');
    }
  }

  async parseV2Logs(type: string, data: any, replace?: boolean) {
    console.log('parsing logs for', type, data);
    const cleanData = [];

    for (let log of data) {
      const logData = JSON.parse(log.log_data);
      cleanData.push(logData);

      if (replace) {
        // if it's a replace function, replace all local logs with external logs.
        await this.storage.set(type, data);
        this.publishSubscription(type, data);
      } else {
        await this.checkDuplicates(type, logData.id).then(response => {
          const dupe = response;
          if (!response) {
            // if it does not already exist in storage save it.
            // console.log('not a duplicate already in storage', logData);
            this.saveLog(type, false, logData, true).then(data => {return data});
          } else {
            // if exists in storage, replace it with what's online
            // need to update editLog function to also only allow editing local
          }
        });
      }
    }
  }

  uniqBy(arr, key) {
    return Object.values([...arr].reverse().reduce((m, i) => {m[key.split('.').reduce((a, p) => a?.[p], i)] = i; return m;}, {}))
  }

  async parseV2LogsV2(type, data) {
    console.log('parsing logs for', type);
    const cleanData = [];

    for (let log of data) {
      // console.log(`parsing ${type} ${log.log_time}`)
      const logData = JSON.parse(log.log_data);
      // journal entries remap types...
      if (type == 'logs') {
        if (logData.type != 'astro' && logData.type != 'lenormand' && logData.type != 'rune') {
          logData.type = 'tarot';
        }
      };
      cleanData.push(logData);
    }

    // these are the only kind that have different ids. We need to move score data to user_data object instead.
    if (type == 'logs' || type == 'spreads') {
      // make sure local and remote data is the same.
      var local = await this.storage.get(type) ?? [];
      var remote = cleanData;
      var difference1 = this.checkNonDuplicates(local, remote);
      var difference2 = this.checkNonDuplicates(remote, local);
      var difference: Array<any> = this.uniqBy(difference1.concat(difference2), 'id');

      console.log('duplicates found:', difference);

      for (let log of difference) {
        var stored = await this.checkDuplicates(type, log.id);
        if (stored) {
          this.saveAPILog(type, log);
        } else {
          this.saveLog(type, false, log, true);
        }
      }
    } else {
      // replace with remote data
      await this.storage.set(type, data);
    }
  }

  async parseGTTLogs(data) {
    console.log('parsing logs for gtt', data);
    const cleanData = [];
    for (let log of data) {
      const logData = JSON.parse(log.log_data);
      console.log('gtt log', logData);
      // if it is NOT a daily spread - match it up with the spread here.
      if (logData.title) {
        // take only relevant information
        const spread = this.spreadService.getSpreadByTitle(logData.title);
        const thisSpread = JSON.parse(JSON.stringify(spread));
        thisSpread.question = logData.question;
        thisSpread.date = log.log_time.split(",")[0];
        thisSpread.id = log.foreign_id;
        thisSpread.deck = 'GTT';
        thisSpread.app = 'golden-thread';
        // take only card id and reversal info of each card
        for (let card of logData.cards) {
          const thisPosition = this.spreadService.getPositionInSpread(thisSpread.cards, card.name);
          thisPosition.card_id = card.card_id;
          thisPosition.reversed = card.reversed;
        }
        console.log(thisSpread);
        // now with this updated log object... we push it into the existing logs, and tag it!
        cleanData.push(thisSpread);
        console.log('clean data from gtt', cleanData);

        // check if duplicates
        await this.checkDuplicates('logs', thisSpread.id).then(response => {
          console.log(thisSpread);
          const dupe = response;
          if (!response) {
            this.saveLog('logs', false, thisSpread).then(data => {return data});
          }
        });
      }
    }
    console.log('clean data from gtt', cleanData);
    return cleanData;
  }

  async parseLSTLogs(data) {
    console.log('parsing logs for lst');
    const cleanData = [];
    for (let log of data) {
      const logData = JSON.parse(log.log_data);
      const startDate = logData.startDate;
      // iterate over all phases
      for (let phase of logData.phases) {
        if (phase.cards) {
          // get the matching spread from spread service
          const spread = this.spreadService.getSpreadByTitle(phase.phase);
          const thisSpread = JSON.parse(JSON.stringify(spread));

          // and take the relevant info
          thisSpread.date = startDate;
          thisSpread.id = log.foreign_id;
          thisSpread.question = "How will the " + phase.phase + " affect my " + logData.intention + " intention?";
          thisSpread.deck = 'LST';
          thisSpread.app = 'luminous-spirit';
          // take only the card id and reversal info of each card
          for (let i in phase.cards) {
            const thisPosition = thisSpread.cards[i];
            // console.log(phase.cards[i]);
            // console.log(thisSpread.cards[i]);
            thisPosition.card_id = phase.cards[i].card_id;
            thisPosition.reversed = phase.cards[i].reversed;
          }
          // console.log(thisSpread);
          // now with this updated log object... we push it into the existing logs, and tag it!
          cleanData.push(thisSpread);

          // check if duplicates
          await this.checkDuplicates('logs', thisSpread.id).then(data => {
            const dupe = data;
            if (!dupe) {
              this.saveLog('logs', false, thisSpread).then(data => {return data});
            }
          });
        }
      }
    }
    return cleanData;
  }

  async parseSSTLogs(data) {
    console.log('parsing logs for sst');
    const cleanData = [];
    for (let log of data) {
      var logData = JSON.parse(log.log_data);
      var spread;

      // get the matching spread from spread service
      if (logData.name == '3x3 Reading') {
        // name doesn't match - can't go back since some folks may have already saved this
        spread = this.spreadService.getSpreadByTitle('9 Card Reading');
      } else {
        spread = this.spreadService.getSpreadByTitle(logData.name);
      }

      const thisSpread = JSON.parse(JSON.stringify(spread));

      // and take the relevant info if they found a spread match.
      if (thisSpread) {
        thisSpread.date = logData.date;
        thisSpread.id = log.foreign_id;
        thisSpread.app = 'seventh-sphere';
        thisSpread.notes = logData.interpretation;

        // if question exists use it, otherwise use the name
        if (logData.question) {
          thisSpread.question = logData.question;
        } else {
          thisSpread.question = logData.name;
        }

        // check if lenormand or tarot type spread
        if (thisSpread.type == 'lenormand') {
          thisSpread.deck = 'SSL';
        } else {
          thisSpread.deck = 'SST';
        }

        for (let i in logData.cards) {
          const thisPosition = thisSpread.cards[i];
          thisPosition.card_id = logData.cards[i].card_id;
          thisPosition.reversed = logData.cards[i].reversed;
        }
        // now with this updated log object... we push it into the existing logs, and tag it!
        cleanData.push(thisSpread);

        // check if duplicates
        await this.checkDuplicates('logs', thisSpread.id).then(data => {
          const dupe = data;
          if (!dupe) {
            this.saveLog('logs', false, thisSpread).then(data => {return data});
          }
        });
      }
    }
    return cleanData;
  }

  async deleteLogAPI(appName, logId) {
    console.log('deleting log ' + logId);
    const params = new HttpParams().set('app', appName).set('id', logId);
    this.httpOptions['params'] = params;

    const logDelete = this.httpClient.delete(
      this.url + '/by-id',
      this.httpOptions
    )

    return logDelete.subscribe (
      data => {
        console.log('delete from db', appName, data);
      },
      error => {
        console.log('delete from db', error);
      }
    )
  }

  async presentToast(message: string, color: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'top',
      duration: 5000,
      color: color,
      buttons: [
        {
          text: 'OK'
        }
      ]
    });
    toast.present();
  }

  // part of auto-save feature for catssandra readings.
  saveReading(spread) {
    // create new const to avoid affecting the data in spread service...
    const savedSpread = JSON.parse(JSON.stringify(spread));
    // save deck used to log
    savedSpread.id = spread.id + '_' + uuidv4();
    savedSpread.deck = spread.deck;
    savedSpread.app = 'v2';
    savedSpread.date = this.convertDate(new Date());

    // if report exits, also add it to the reading...
    if (spread.report?.summary) {
      savedSpread.report = spread.report;
    };

    // returns false if cannot save, 4th parameter of saveLog function overrides blocking and lets you save anyway.
    return this.saveLog('logs', true, savedSpread, false).then((data) => {
      if (data === false) {
        // autosave failed, show upgrade modal
        this.presentToast(this.translate.instant('log_limits.toasts.auto_save_fail'), 'danger');
        return false;
      } else {
        // autosave success message
        this.presentToast(this.translate.instant('log_limits.toasts.auto_save_success'), 'success');
        return savedSpread;
      }
    });
  }

  // general auto-save functionality to test if reading exists or not before saving. if exists -> edit, if new -> save
  // not available on free-form readings
  checkAutoSave(spread, user) {
    var finished = this.checkFinished(spread.cards);
    var userSetting = user.autoSave;
    var userExists = user.email;

    if (finished && userSetting && userExists) {
      // if it's a new reading create a new journal entry.
      if (spread.id.indexOf("_") == -1) {
        return true;
      // but if it already exists, save the log.
      // remember: custom entries though have other things to check.
      } else {
        if (spread.id.indexOf("custom") == -1) {
          this.editLog('logs', spread.id, spread);
          return false;
        } else {
          return true;
        }
      }
    }
    return false;
  }

  // return logs as textfile for export
  parseLogs(logs: Array<any>) {
    var plaintext = logs.map((log) => {
      var spreadName = `Spread: ${log.title}`;
      var question = `Question: ${log.question}`;
      var notes = `My Notes:\n${log.notes || "No Notes Taken"}`;
      var date = log.date;
      var type = log.type;

      if (type == 'astro' || type == 'lenormand' || type =='rune') {
        var translateVariable = `${type}.`
      } else {
        if (log.deck) {
          var translateVariable = this.tarotService.getDeckTranslateVariable(log.deck) + '.';
        }
      };

      if (log.cards && Array.isArray(log.cards)) {
        var cards = log.cards.map((card) => {
          if (translateVariable != undefined) {
            if (card.reversed) {
              var title: string = this.translate.instant(translateVariable + card.card_id + '.title') + " " + this.translate.instant('_common.reversed');
              var keywords: string = this.translate.instant(translateVariable + card.card_id + '.keywords_reversal');
              var description: string = this.translate.instant(translateVariable + card.card_id + '.reversal').replaceAll('<br>', ' ');
            } else {
              var title: string = this.translate.instant(translateVariable + card.card_id + '.title');
              var keywords: string = this.translate.instant(translateVariable + card.card_id + '.keywords');
              var description: string = this.translate.instant(translateVariable + card.card_id + '.description').replaceAll('<br>', ' ');
            }
          }
          return `${title}: ${keywords}\n${description}\n\n`
        }).join('');
      } else {
        var cards;
      }

      var reports = '';
      if (log.report) {
        if (!log.allReports) {
          log.allReports = [];
        };
        log.allReports.push(log.report);
        delete log.report;
      };

      if (log.allReports?.length > 0) {
        reports = log.allReports.map((report) => {
          var name: string;
          if (report.role && report?.role[0]) {
            name = report.role[0].toUpperCase() + report.role.slice(1);
          } else {
            name = 'Catssandra';
          }
          var summary: string = report.summary;
          var cards = report.cards.map((card) => {
            return card;
          }).join('\n\n');
          return `${name}: ${summary}\n\n${cards}`;
        }).join('');
      };

      return `${date}\n${spreadName}\n${question}\n\n${cards}\n${notes}\n\n${reports}`
    });

    return plaintext.join('\n\n--------------\n\n');
  }
}
