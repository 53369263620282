import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-deck',
  templateUrl: './deck.component.html',
  styleUrls: ['./deck.component.scss'],
})

export class DeckComponent implements OnInit {
  @Input() view: Array<string> = ['box-flat', 'cards'];
  @Input() product: any;
  @Input() height: string = '320px';
  viewData: any = {
    skew: '-7.5deg',
    topBox: '50%',
    topCards: '-25%'
  };
  scatterData: Array<any> = [
    {
      id: 'a',
      position: [20, 40],
      rotate: 2
    },
    {
      id: 'f',
      position: [10, 30],
      rotate: 45
    },
    {
      id: 'g',
      position: [15, 110],
      rotate: 50
    },
    {
      id: 'u',
      position: [50, 50],
      rotate: 30
    },
    {
      id: 'i',
      position: [-10, 30],
      rotate: 25
    }
  ]

  constructor() { }

  ngOnInit() {
  }

}
