import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AstroService {
  lastIndex: number = 43;

  ranges: any = [
    {
      id: 'zodiac',
      range: [0,1,2,3,4,5,6,7,8,9,10,11]
    },
    {
      id: 'planet',
      range: [12,13,14,15,16,17,18,19,20,21]
    },
    {
      id: 'house',
      range: [22,23,24,25,26,27,28,29,30,31,32,33]
    },
    {
      id: 'moon',
      range: [34,35,36,37,38,39,40,41,42,43]
    }
  ];

  astro: any = [
    {
      type: "zodiac",
      image: "astrology-oracle_zodiac-aries.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/the-zodiac-sign-aries-symbol-personality-strengths-weaknesses',
      search: "fire cardinal aries zodiac"
    },
    {
      type: "zodiac",
      image: "astrology-oracle_zodiac-taurus.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/the-zodiac-sign-taurus-symbol-personality-strengths-weaknesses',
      search: "earth fixed taurus zodiac"
    },
    {
      type: "zodiac",
      image: "astrology-oracle_zodiac-gemini.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/the-zodiac-sign-gemini-symbol-personality-strengths-weaknesses',
      search: "air mutable gemini zodiac"
    },
    {
      type: "zodiac",
      image: "astrology-oracle_zodiac-cancer.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/the-zodiac-sign-cancer-symbol-personality-strengths-weaknesses',
      search: "water cardinal cancer zodiac"
    },
    {
      type: "zodiac",
      image: "astrology-oracle_zodiac-leo.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/the-zodiac-sign-leo-symbol-personality-strengths-weaknesses',
      search: "fire fixed leo zodiac"
    },
    {
      type: "zodiac",
      image: "astrology-oracle_zodiac-virgo.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/the-zodiac-sign-virgo-symbol-personality-strengths-weaknesses',
      search: "earth mutable virgo zodiac"
    },
    {
      type: "zodiac",
      image: "astrology-oracle_zodiac-libra.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/the-zodiac-sign-libra-symbol-personality-strengths-weaknesses',
      search: "air cardinal libra zodiac"
    },
    {
      type: "zodiac",
      image: "astrology-oracle_zodiac-scorpio.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/the-zodiac-sign-scorpio-symbol-personality-strengths-weaknesses',
      search: "water fixed scorpio zodiac"
    },
    {
      type: "zodiac",
      image: "astrology-oracle_zodiac-sagittarius.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/the-zodiac-sign-sagittarius-symbol-personality-strengths-weaknesses',
      search: "fire mutable sagittarius zodiac"
    },
    {
      type: "zodiac",
      image: "astrology-oracle_zodiac-capricorn.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/the-zodiac-sign-capricorn-symbol-personality-strengths-weaknesses',
      search: "earth cardinal capricorn zodiac"
    },
    {
      type: "zodiac",
      image: "astrology-oracle_zodiac-aquarius.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/the-zodiac-sign-aquarius-symbol-personality-strengths-weaknesses',
      search: "air fixed aquarius zodiac"
    },
    {
      type: "zodiac",
      image: "astrology-oracle_zodiac-pisces.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/the-zodiac-sign-pisces-symbol-personality-strengths-weaknesses',
      search: "water mutable pisces zodiac"
    },
    {
      type: "planet",
      image: "astrology-oracle_planet-sun.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/sun-astrology-symbol-characteristics-planet-energy',
      search: "planet sun sol"
    },
    {
      type: "planet",
      image: "astrology-oracle_planet-moon.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/moon-astrology-symbol-characteristics-planet-energy',
      search: "planet moon luna"
    },
    {
      type: "planet",
      image: "astrology-oracle_planet-mercury.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/mercury-astrology-symbol-characteristics-planet-energy',
      search: "planet mercury"
    },
    {
      type: "planet",
      image: "astrology-oracle_planet-venus.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/venus-astrology-symbol-characteristics-planet-energy',
      search: "planet venus"
    },
    {
      type: "planet",
      image: "astrology-oracle_planet-mars.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/mars-astrology-symbol-characteristics-planet-energy',
      search: "planet mars"
    },
    {
      type: "planet",
      image: "astrology-oracle_planet-jupiter.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/jupiter-astrology-symbol-characteristics-planet-energy',
      search: "planet jupiter"
    },
    {
      type: "planet",
      image: "astrology-oracle_planet-saturn.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/saturn-astrology-symbol-characteristics-planet-energy',
      search: "planet saturn"
    },
    {
      type: "planet",
      image: "astrology-oracle_planet-uranus.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/uranus-astrology-symbol-characteristics-planet-energy',
      search: "planet uranus"
    },
    {
      type: "planet",
      image: "astrology-oracle_planet-neptune.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/neptune-astrology-symbol-characteristics-planet-energy',
      search: "planet neptune"
    },
    {
      type: "planet",
      image: "astrology-oracle_planet-pluto.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/pluto-astrology-symbol-characteristics-planet-energy',
      search: "planet pluto"
    },
    {
      type: "house",
      image: "astrology-oracle_house-01.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/1st-first-house-of-self-astrology',
      search: "house of self identity ego persona 1 1st"
    },
    {
      type: "house",
      image: "astrology-oracle_house-02.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/2nd-second-house-of-value-astrology',
      search: "house of possessions value 2 2nd"
    },
    {
      type: "house",
      image: "astrology-oracle_house-03.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/3rd-third-house-of-communication-astrology',
      search: "house of communication intellect 3 3rd"
    },
    {
      type: "house",
      image: "astrology-oracle_house-04.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/4th-fourth-house-of-home-astrology',
      search: "house of family origins home 4 4th"
    },
    {
      type: "house",
      image: "astrology-oracle_house-05.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/5th-fifth-house-of-pleasure-astrology',
      search: "house of pleasure fun creativity play 5 5th"
    },
    {
      type: "house",
      image: "astrology-oracle_house-06.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/6th-sixth-house-of-health-astrology',
      search: "house of health service work 6 6th"
    },
    {
      type: "house",
      image: "astrology-oracle_house-07.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/7th-seventh-house-of-relationships-astrology',
      search: "house of partners relationships marriage 7 7th"
    },
    {
      type: "house",
      image: "astrology-oracle_house-08.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/8th-eighth-house-of-sex-and-death-astrology',
      search: "house of transformation death sex 8 8th"
    },
    {
      type: "house",
      image: "astrology-oracle_house-09.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/9th-ninth-house-of-spirituality-astrology',
      search: "house of journeys travel philosophy religion 9 9th"
    },
    {
      type: "house",
      image: "astrology-oracle_house-10.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/10th-tenth-house-of-ambition-astrology',
      search: "house of career ambition achievement 10 10th"
    },
    {
      type: "house",
      image: "astrology-oracle_house-11.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/11th-eleventh-house-of-friendship-astrology',
      search: "house of friends social society 11 11th"
    },
    {
      type: "house",
      image: "astrology-oracle_house-12.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/12th-twelfth-house-of-secrets-astrology',
      search: "house of secrets hidden mystery 12 12th"
    },
    {
      type: "moon",
      image: "astrology-oracle_moon-new.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/',
      search: "new moon phase"
    },
    {
      type: "moon",
      image: "astrology-oracle_moon-wax-cres.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/',
      search: "waxing crescent moon phase"
    },
    {
      type: "moon",
      image: "astrology-oracle_moon-first-quarter.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/',
      search: "first quarter moon phase"
    },
    {
      type: "moon",
      image: "astrology-oracle_moon-wax-gibb.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/',
      search: "waxing gibbous moon phase"
    },
    {
      type: "moon",
      image: "astrology-oracle_moon-full.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/',
      search: "full moon phase"
    },
    {
      type: "moon",
      image: "astrology-oracle_moon-wane-gibb.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/',
      search: "waning gibbous moon phase"
    },
    {
      type: "moon",
      image: "astrology-oracle_moon-last-quarter.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/',
      search: "last quarter moon phase"
    },
    {
      type: "moon",
      image: "astrology-oracle_moon-wane-cres.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/',
      search: "waning crescent moon phase"
    },
    {
      type: "moon",
      image: "astrology-oracle_moon-lunar-eclipse.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/',
      search: "lunar eclipse moon phase"
    },
    {
      type: "moon",
      image: "astrology-oracle_moon-solar-eclipse.png",
      url: 'https://labyrinthos.co/blogs/astrology-horoscope-zodiac-signs/',
      search: "solar eclipse moon phase"
    }
  ];

  filterCards(field, searchText) {
    return this.astro.filter((card) => {
      return card[field].toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    });
  }

  getCard(cardId) {
    return this.astro.filter((card) => {
      return card.image == cardId;
    });
  }

  getCardByDeck(cardId: string, deckId: string) {
    // identical for now, as lenormand decks don't need extra cards, but reading service uses same function names for each service.
    return this.astro.filter((card) => {
      return card.image == cardId;
    });
  }

  getCardByIndex(index) {
    return this.astro[index];
  }

  getCardsByIndex(indexArray) {
    var cards = [];
    for (let i of indexArray) {
      cards.push(this.astro[i])
    }
    return cards;
  }

  getSubsetNamebyIndex(index: number) {
    // for astrology section, we also need to know the section (zodiac, house, planet, moon) as index
    if (index == 0) {var range = ['house']}
    else if (index == 1) {var range = ['zodiac']}
    else if (index == 2) {var range = ['planet']}
    else {var range = ['moon']}

    return range;
  }

  getCardArraysforSubset(subset: Array<string>) {
    var subsetIndexes = this.ranges.map((type) => {
      if (subset.indexOf(type.id) > -1) {
        return type.range;
      }
    });

    subsetIndexes = subsetIndexes.filter(Boolean); // remove undefined or empty items
    subsetIndexes = Array.prototype.concat(...subsetIndexes); // combine arrays
    subsetIndexes = Array.from(new Set(subsetIndexes)); // remove duplicates

    return subsetIndexes;
  }

  getRandomCardInRange(subset: Array<string>, excluded) {
    if (excluded) {
      subset = subset.filter((n) => { // remove excluded
        return excluded.indexOf(n) < 0;
      });
    };

    var index = subset[Math.floor(Math.random()*subset.length)];
    return this.astro[index];
  }

  getCardIndex(cardId) {
    return this.astro.findIndex(card => {
      return card.image == cardId;
    })
  }

  getRandomInt(excluded, maxIndex) {
    // return Math.floor(Math.random()*(max-min+1)+min);
    var result = 0;
    var min = 0;
    var max = maxIndex - 1;
    var index = Math.floor(Math.random()*(max-min+1)+min);

    while(excluded.indexOf(index) > -1) {
      if (index < max) {
        index++;
      } else {
        index = 0;
      }
    }
    return index;
  }

  getRandomCard(excluded, positionIndex) {
    var index = this.getRandomInt(excluded, 43);
    return this.astro[index];
  }

  getAstro() {
    return this.astro;
  }
}
