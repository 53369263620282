<ion-content class="ion-padding ion-text-center" *ngIf="product">
  <h2>{{progress}}%</h2>
  <div class="loading-animation">
    <img src="../assets/loading.gif">
  </div>
  <ion-progress-bar [value]="progress" color="tertiary"></ion-progress-bar>
  <p class="small"><small>Downloading {{item}}</small></p>
  <p>
    Downloading <strong>{{product.name}}</strong> for offline use. Do not close app.<br><br>
    <small>Uninstalling and reinstalling the app, and some new version updates require re-downloading files.</small>
  </p>
</ion-content>
